/*! Flickity v2.0.8
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: #fff;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: #fff; }

.flickity-prev-next-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #09f; }

.flickity-prev-next-button:active {
  opacity: .6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  opacity: .3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/*	Fonts	*/
/*	Colors	*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

body, html {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: bold; }

/*	Misc.	*/
html {
  left: 0;
  top: 0;
  width: 100%; }

body {
  text-align: center;
  position: relative;
  overflow-x: hidden !important; }
  body:before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    position: fixed;
    z-index: -1;
    visibility: hidden;
    transition: all ease-in-out 0.3s; }
  body.scroll-hidden {
    overflow: hidden; }
    body.scroll-hidden:before {
      opacity: 1;
      visibility: visible;
      z-index: 999; }
  body.popup-active:before {
    z-index: 1000; }

main {
  transition: all ease-in-out 0.2s;
  position: relative;
  display: block; }
  main#main {
    top: 112px; }

* {
  box-sizing: border-box;
  color: #7A7B78;
  max-width: 100%;
  user-select: none; }

.site-container {
  max-width: 1280px;
  margin: 0 auto; }

.print-container {
  display: none; }

.container {
  margin: 0 auto;
  width: 92.5%;
  max-width: 73.75rem; }
  .container.container-large {
    width: 95%; }
  .container.container-small {
    width: 80%; }
  .container.container-desktop-small {
    max-width: 61.25rem; }

.flickity-viewport {
  cursor: default !important; }

@media only screen and (min-width: 980px) {
  .container-desktop {
    max-width: 67.5rem;
    margin: 0 auto;
    width: 95%; } }

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
  font-family: "proxima-soft"; }

img {
  width: 100%;
  height: auto;
  user-select: none; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  cursor: default !important; }

h1 {
  font-size: 1.75em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.375em; }

h4 {
  font-size: 1.125em; }

h5 {
  font-size: 1em; }

h6 {
  font-size: 1em; }

p,
li {
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400; }

p a {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline; }

a {
  display: inline-block;
  transition: color ease-in-out 0.2s;
  user-select: none; }

span,
i,
b,
strong {
  font-weight: inherit;
  color: inherit;
  font-family: inherit; }

b,
strong {
  font-weight: 700; }

section.general-section {
  padding: 2.8125rem 0; }

section.bg-blue-gradient {
  padding: 1.5625rem 0 2.1875rem; }

.bg-blue-gradient {
  background-image: linear-gradient(to bottom, #2da0f1 0%, #289bee 44%, #178be4 68%, #0076d8 100%); }

.local-hash {
  padding-top: 9.375rem;
  margin-top: -9.375rem;
  display: block; }

.el-desktop {
  display: none; }

@media only screen and (min-width: 980px) {
  .el-desktop {
    display: block; }
  .el-mobile {
    display: none; } }

/*	Misc Font Styles	*/
.bold {
  font-weight: 700; }

.orange {
  color: #E25F2C; }

.underline {
  text-decoration: underline; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.bold.underline:hover {
  opacity: 0.8;
  cursor: pointer; }

.blue {
  color: #2C68A5; }

.white {
  color: white; }

.border-bottom {
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  padding: 0 5% 1rem; }
  @media only screen and (max-width: 680px) {
    .border-bottom {
      font-size: 1.1em; } }

.side-borders {
  display: flex;
  align-items: center; }
  .side-borders:before, .side-borders:after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #2C68A5; }
  .side-borders:before {
    margin-right: 0.625rem; }
  .side-borders:after {
    margin-left: 0.625rem; }

.social-full {
  width: 100%;
  flex: 0 0 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 3.4375rem;
  border-radius: 50px;
  margin-bottom: 0.625rem; }
  .social-full span {
    border-radius: 50%;
    width: 3.3125rem;
    height: 3.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    position: absolute;
    left: 1px;
    top: 1px; }
    .social-full span svg {
      width: 30%; }
  .social-full.facebook {
    background-color: #425893; }
    .social-full.facebook span svg * {
      fill: #425893; }
  .social-full.google {
    background-color: #cd5642; }
    .social-full.google span svg * {
      fill: #cd5642; }

/*	Flex Cols	*/
.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-3 {
  flex: 3; }

.flex-4 {
  flex: 4; }

/*	Misc.	*/
button {
  background: transparent;
  box-shadow: none;
  border: none;
  outline: none; }

.section-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.section-parallax {
  background-size: 125%; }

button.button {
  font-size: 1em;
  font-weight: 800;
  font-family: inherit; }

.button {
  border: 2px solid transparent;
  padding: 0.75rem 2.5rem 0.875rem;
  border-radius: 100px;
  font-size: 1em;
  font-weight: 800;
  letter-spacing: 0.0625rem;
  background: transparent;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  min-height: 2.8125rem;
  user-select: none;
  transition: all ease-in-out 0.2s !important;
  text-align: center;
  position: relative;
  cursor: pointer; }
  .button.print svg {
    max-height: 1.5625rem;
    width: auto;
    margin: -0.3125rem 0.9375rem -0.3125rem -0.3125rem; }
    .button.print svg * {
      stroke-width: 3px; }
  .button.button-center {
    justify-content: flex-start;
    text-align: left; }
  .button.button-full {
    width: 100%; }
  .button.button-white {
    color: white;
    border-color: white; }
    .button.button-white.active {
      background-color: white;
      color: #E25F2C; }
  .button.button-orange {
    color: #E25F2C;
    border-color: #E25F2C; }
  .button.button-orange-solid {
    color: white;
    background-image: linear-gradient(to right, #f5993c 0%, #f76b1c 51%, #f5993c 100%);
    border: none;
    background-size: 150% auto; }
  .button.button-blue-solid {
    color: white;
    background-image: linear-gradient(to right, #2da0f1 0%, #0076d8 51%, #2da0f1 100%);
    border: none;
    background-size: 150% auto; }
  .button.button-green-solid {
    background-image: linear-gradient(to right, #90bc40 0%, #429321 80%, #90bc40 100%);
    background-size: 200% auto;
    color: white;
    border: transparent; }
  .button.button-blue {
    border-color: #2C68A5;
    color: #2C68A5; }
  .button.button-icon-inline {
    position: relative;
    overflow: visible; }
    .button.button-icon-inline .button-icon {
      border: 2px solid #2C68A5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.3125rem;
      position: absolute;
      left: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease-in-out 0.2s;
      overflow: visible; }
      .button.button-icon-inline .button-icon svg {
        width: 0.78125rem;
        height: auto;
        max-height: 0.78125rem; }
  .button.button-icon {
    display: flex;
    font-size: 0.8125em;
    font-weight: 600;
    font-family: "open-sans";
    letter-spacing: 0;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0.625rem;
    border: none; }
    .button.button-icon svg {
      display: block;
      width: 1.4375rem;
      flex: 0 0 1.4375rem;
      margin-right: 0.625rem; }
  .button.button-square {
    border-radius: 1.25rem !important; }
  .button.add-quickplay {
    font-size: 0.625em;
    font-weight: 700;
    padding: 0.46875rem 1.25rem;
    line-height: 1.3; }
  .button.results-expand:after {
    display: none; }
  .button.button-follow {
    text-align: center;
    width: 100%;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #E25F2C;
    font-weight: 600;
    font-size: 1.375rem;
    display: flex; }
    .button.button-follow svg {
      margin-left: 1.25rem;
      height: 2rem;
      width: auto; }
      .button.button-follow svg * {
        fill: #2C68A5;
        transition: all ease-in-out 0.15s; }

.tm {
  font-size: 50%;
  vertical-align: top;
  display: inline !important; }

.remove:before {
  content: ""; }

.button.loading,
button.loading {
  position: relative;
  overflow: hidden;
  background: none; }
  .button.loading:before,
  button.loading:before {
    content: "";
    background: white;
    background-image: url(images/spinner.gif);
    background-size: 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }

.default-list {
  padding: 0;
  margin: 1.25rem 0 1.25rem 0.625rem;
  list-style: none;
  text-align: left; }
  .default-list li {
    color: #2C68A5;
    font-size: 1.125em;
    font-weight: 600;
    margin-bottom: 0.46875rem; }
    .default-list li a {
      color: #2C68A5;
      font-weight: 600;
      font-size: inherit;
      text-decoration: underline;
      display: inline-block; }

.icon-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400 !important;
  font-size: 1.375em; }
  .icon-title .icon {
    width: 2rem;
    height: 2rem;
    flex: 0 0 2rem !important;
    border-radius: 50%;
    border: 2px solid white;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 0.9375rem;
    flex: 0 0 auto; }
    .icon-title .icon svg {
      max-width: 0.875rem; }
  .icon-title.blue {
    color: #2C68A5; }
    .icon-title.blue .icon {
      border-color: #2C68A5; }
      .icon-title.blue .icon svg * {
        fill: #2C68A5; }

.icon-text {
  display: flex;
  align-items: center;
  justify-content: center; }
  .icon-text svg {
    border: 2px solid #2C68A5;
    border-radius: 50%;
    padding: 0.375rem;
    width: 2rem;
    height: 2rem;
    overflow: visible;
    margin-left: 0.625rem; }
  .icon-text.icon-blue {
    border-color: #2C68A5; }
    .icon-text.icon-blue svg * {
      fill: #2C68A5; }

.breadcrumbs {
  color: white;
  flex: 0 0 100% !important;
  width: 100%;
  text-align: left;
  margin-bottom: 0.9375rem !important;
  margin-top: 0 !important; }
  .breadcrumbs a {
    margin: 0 0.3125rem;
    text-decoration: none; }

@media only screen and (max-width: 680px) {
  .breadcrumbs {
    max-width: 22.5rem;
    margin: 0 auto; } }

/*futureball	*/
@media only screen and (max-width: 700px) {
  .section.thick.aboutbetting-section {
    padding-top: 1.5rem; } }

.about-betting .banner {
  position: relative; }
  @media only screen and (min-width: 980px) {
    .about-betting .banner {
      max-height: auto;
      min-height: 38rem; } }
  @media only screen and (max-width: 1100px) {
    .about-betting .banner {
      min-height: 20rem; } }
  @media only screen and (max-width: 980px) {
    .about-betting .banner {
      min-height: 18rem; } }
  .about-betting .banner .banner-image {
    top: inherit;
    bottom: 0; }
  .about-betting .banner .banner-overlay {
    width: 35rem; }

.banner-content--container {
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem; }
  .banner-content--container .banner-list {
    max-width: 75rem; }
  @media only screen and (max-width: 1100px) {
    .banner-content--container.desktop {
      display: none; } }
  .banner-content--container.mobile {
    display: none; }
    @media only screen and (max-width: 1100px) {
      .banner-content--container.mobile {
        display: block;
        position: relative;
        padding: 0;
        padding-bottom: 2rem; } }

.banner-list {
  margin: auto;
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: flex-end;
  padding-top: 2.5rem; }
  .banner-list .list-items-options {
    max-width: 31rem;
    width: 100%; }
  .banner-list .list-items-options .row {
    display: flex; }
    .banner-list .list-items-options .row img {
      max-width: 1.6rem; }
    .banner-list .list-items-options .row .col {
      padding: 1rem 1rem;
      box-sizing: border-box; }
      .banner-list .list-items-options .row .col.orange {
        background-color: #f66c1c;
        color: white;
        text-align: center; }
        .banner-list .list-items-options .row .col.orange p {
          color: white;
          text-transform: uppercase;
          margin: 0; }
          @media only screen and (max-width: 500px) {
            .banner-list .list-items-options .row .col.orange p {
              font-size: 9px; } }
      .banner-list .list-items-options .row .col.dark {
        background-color: #2f2b29;
        color: white;
        text-align: center; }
        .banner-list .list-items-options .row .col.dark p {
          color: white;
          text-transform: uppercase;
          margin: 0; }
          @media only screen and (max-width: 500px) {
            .banner-list .list-items-options .row .col.dark p {
              font-size: 9px; } }
      .banner-list .list-items-options .row .col:nth-child(1) {
        width: 66.6666%; }
        @media only screen and (max-width: 500px) {
          .banner-list .list-items-options .row .col:nth-child(1) {
            width: 50%; } }
        @media only screen and (max-width: 400px) {
          .banner-list .list-items-options .row .col:nth-child(1) {
            width: 47%; } }
      .banner-list .list-items-options .row .col:nth-child(2), .banner-list .list-items-options .row .col:nth-child(3) {
        width: 33.3333%; }
    .banner-list .list-items-options .row .border {
      box-sizing: border-box;
      border-left: solid 1px #cfcfcf;
      border-right: solid 1px #cfcfcf; }
    .banner-list .list-items-options .row .col p {
      font-size: 12px;
      line-height: 1.4;
      margin: 0; }
      .banner-list .list-items-options .row .col p strong {
        font-weight: 600;
        margin-bottom: 0.3rem;
        display: block; }
    .banner-list .list-items-options .row.two {
      background-color: white; }
    .banner-list .list-items-options .row.three {
      background-color: #ececec; }
    .banner-list .list-items-options .row.four {
      background-color: #f2f2f2; }
    .banner-list .list-items-options .row .icon-container {
      text-align: center;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center; }

@media only screen and (max-width: 1100px) {
  position: relative;
  width: 100%;
  display: block;
  padding-right: 0;
  margin-bottom: 5rem;
  .banner-list {
    max-width: 75rem;
    margin: auto;
    width: 100%;
    padding-top: 1rem; }
    .banner-list .list-items-options {
      max-width: 100%;
      width: 100%; } }

.aboutbetting-section {
  padding: 6rem 0;
  text-align: left; }
  @media only screen and (max-width: 700px) {
    .aboutbetting-section {
      padding: 3rem 0; } }
  .aboutbetting-section h3 {
    font-size: 26px;
    color: #f66c1c; }
    @media only screen and (max-width: 700px) {
      .aboutbetting-section h3 {
        font-size: 23px; } }
  .aboutbetting-section p {
    font-size: 18px;
    color: #000000;
    letter-spacing: 1px; }
    @media only screen and (max-width: 700px) {
      .aboutbetting-section p {
        font-size: 16px; } }
  .aboutbetting-section .top-content {
    padding-left: 5rem; }
    .aboutbetting-section .top-content .icon-content {
      top: 0; }
    @media only screen and (max-width: 900px) {
      .aboutbetting-section .top-content {
        padding-left: 3.2rem; }
        .aboutbetting-section .top-content .icon-content {
          top: 0.1rem;
          max-width: 1.8rem;
          left: 0.2rem; } }
  .aboutbetting-section .icon-content {
    position: absolute;
    left: 0.8rem;
    top: 1rem;
    max-width: 3rem;
    width: 100%;
    height: auto; }
    @media only screen and (max-width: 900px) {
      .aboutbetting-section .icon-content {
        left: 0;
        top: 1.7rem;
        max-width: 2rem; } }
    @media only screen and (max-width: 700px) {
      .aboutbetting-section .icon-content {
        left: 0;
        top: 1.3rem;
        max-width: 2rem; } }
  .aboutbetting-section .middle-content {
    padding: 3rem 0; }
    @media only screen and (max-width: 700px) {
      .aboutbetting-section .middle-content {
        padding: 1rem 0; } }
    .aboutbetting-section .middle-content .two-col .col:first-child {
      padding-left: 5rem;
      position: relative; }
      @media only screen and (max-width: 900px) {
        .aboutbetting-section .middle-content .two-col .col:first-child {
          padding-left: 3rem; } }
    .aboutbetting-section .middle-content .two-col .col:last-child {
      padding-left: 5rem;
      position: relative; }
      @media only screen and (max-width: 900px) {
        .aboutbetting-section .middle-content .two-col .col:last-child {
          padding-left: 3rem; } }
      .aboutbetting-section .middle-content .two-col .col:last-child .badge-wrap {
        position: relative;
        z-index: 10; }
      .aboutbetting-section .middle-content .two-col .col:last-child p {
        padding-right: 7.5rem; }
        @media only screen and (max-width: 700px) {
          .aboutbetting-section .middle-content .two-col .col:last-child p {
            padding-right: 0; } }
      .aboutbetting-section .middle-content .two-col .col:last-child .content-badge {
        position: absolute;
        z-index: 1;
        max-width: 9rem;
        right: -10px;
        top: 50%;
        transform: translateY(-24%); }
        @media only screen and (max-width: 700px) {
          .aboutbetting-section .middle-content .two-col .col:last-child .content-badge {
            max-width: 6rem;
            right: -10px;
            top: 90%;
            transform: translateY(-24%); } }
  .aboutbetting-section .bottom-content .two-col .col:first-child {
    padding-left: 5rem;
    position: relative; }
    @media only screen and (max-width: 900px) {
      .aboutbetting-section .bottom-content .two-col .col:first-child {
        padding-left: 3rem; } }
  .aboutbetting-section .bottom-content .two-col .col:last-child {
    padding-left: 5rem;
    position: relative; }
    @media only screen and (max-width: 900px) {
      .aboutbetting-section .bottom-content .two-col .col:last-child {
        padding-left: 3rem; } }
  .aboutbetting-section .two-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .aboutbetting-section .two-col .col {
      width: 48%;
      padding-left: 5rem; }
      @media only screen and (max-width: 900px) {
        .aboutbetting-section .two-col .col {
          width: 100%;
          padding-left: 3rem; }
          .aboutbetting-section .two-col .col:first-child {
            margin-bottom: 4rem; } }
      @media only screen and (max-width: 700px) {
        .aboutbetting-section .two-col .col {
          margin-bottom: 1rem; }
          .aboutbetting-section .two-col .col:first-child {
            margin-bottom: 1rem; } }
      .aboutbetting-section .two-col .col ul {
        padding-left: 1.5rem; }
        .aboutbetting-section .two-col .col ul li {
          margin: 0;
          padding: 0;
          list-style: none;
          margin-bottom: 1.5rem;
          position: relative; }
          .aboutbetting-section .two-col .col ul li a {
            font-size: 18px;
            color: #000000; }
            .aboutbetting-section .two-col .col ul li a:hover {
              opacity: 1;
              text-decoration: underline; }
            .aboutbetting-section .two-col .col ul li a img {
              width: 0.7rem;
              position: absolute;
              left: -1.5rem;
              top: 0.35rem; }

.about-betting .blue-aboutbetting {
  padding: 6rem 0;
  background-color: #037bdb; }
  @media only screen and (max-width: 1280px) {
    .about-betting .blue-aboutbetting {
      padding: 3rem 0;
      padding-top: 4rem; } }
  .about-betting .blue-aboutbetting .container {
    max-width: 78.75rem; }
  .about-betting .blue-aboutbetting .gradient_content {
    background: -webkit-linear-gradient(#f6711f, #ffa954);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 64px;
    margin: 0; }
    @media only screen and (max-width: 1080px) {
      .about-betting .blue-aboutbetting .gradient_content {
        font-size: 45px; } }
  .about-betting .blue-aboutbetting .two-col.wide-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col {
      width: 25%;
      position: relative;
      padding-top: 3.5rem; }
      @media only screen and (max-width: 1280px) {
        .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col {
          width: 100%;
          position: relative;
          padding-top: 0rem;
          padding-bottom: 1rem; } }
      .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col .blue-icon {
        max-width: 3.3rem;
        position: absolute;
        left: 0;
        top: 2rem; }
        @media only screen and (max-width: 1280px) {
          .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col .blue-icon {
            max-width: 3.3rem;
            position: absolute;
            left: 0;
            top: -1rem; } }
      .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col .left-content {
        padding-left: 5rem;
        color: white;
        text-align: left; }
      .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col h4 {
        color: white;
        margin: 0;
        font-size: 26px; }
      .about-betting .blue-aboutbetting .two-col.wide-left .col.sml-col p {
        color: white;
        line-height: 1.5;
        font-size: 16px; }
    .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child {
      width: 70%; }
      @media only screen and (max-width: 1280px) {
        .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child {
          width: 100%; } }
      .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col .col {
          width: 33.33333%;
          padding: 0 0.4rem; }
          @media only screen and (max-width: 700px) {
            .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col .col {
              width: 100%;
              padding: 1rem 0rem; } }
          .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col .col .white-box {
            padding: 2.5rem 1.5rem;
            border-radius: 2rem;
            background-color: white;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); }
            @media only screen and (max-width: 1280px) {
              .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col .col .white-box {
                padding: 2.5rem 3rem; } }
            @media only screen and (max-width: 1080px) {
              .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col .col .white-box {
                padding: 2.5rem 1.5rem; } }
            .about-betting .blue-aboutbetting .two-col.wide-left .col:last-child .three-col .col .white-box p {
              margin-bottom: 0; }

.about-betting .lottery-benefits {
  background: linear-gradient(183deg, #f6711f 6%, #ffa954); }

.top-content {
  position: relative; }
  @media only screen and (max-width: 700px) {
    .top-content {
      padding-left: 3.1rem; } }
  .top-content img {
    top: -0.8rem;
    width: 2.5rem;
    left: 1.2rem; }
    @media only screen and (max-width: 700px) {
      .top-content img {
        top: -0.4rem;
        width: 1.8rem;
        left: 0.4rem; } }

.comparison-list {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0; }
  .comparison-list .top-content {
    text-align: left;
    padding-top: 2.5rem;
    flex: 1;
    padding-left: 0; }
    .comparison-list .top-content h3 {
      color: #E25F2C; }
  .comparison-list .banner-list {
    flex: 0 0 31rem;
    margin-left: 5rem;
    width: 31rem; }
  @media only screen and (max-width: 860px) {
    .comparison-list {
      display: block;
      padding: 1rem 0; }
      .comparison-list .top-content,
      .comparison-list .banner-list {
        width: 100%;
        margin: 0; }
      .comparison-list .top-content {
        text-align: center; } }

/*futureball	*/
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.banner-has-draws {
  background: linear-gradient(to right, #f66c1c 0%, #fea500 70%, #fb8e0c 70%, #fb8e0c 100%); }
  @media screen and (max-width: 1080px) {
    .banner-has-draws {
      background: linear-gradient(to right, #f66c1c 0%, #fea500 100%); } }
  .banner-has-draws .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url(images/lottery-winner.png);
    background-repeat: no-repeat;
    background-position: 52.5% bottom;
    background-size: auto 95%; }
  .banner-has-draws .banner {
    padding: 0;
    min-height: 400px !important;
    max-height: none !important; }
    .banner-has-draws .banner.section-image .banner-image {
      left: 20%; }
  .banner-has-draws .banner-logo--container {
    padding-bottom: 2rem; }
    .banner-has-draws .banner-logo--container.image-side {
      display: flex;
      align-items: flex-start; }
      .banner-has-draws .banner-logo--container.image-side > img {
        position: static;
        width: 8rem;
        margin-right: 2rem; }
        @media screen and (max-width: 560px) {
          .banner-has-draws .banner-logo--container.image-side > img {
            max-width: 65%; } }
      .banner-has-draws .banner-logo--container.image-side .future-logo h1 {
        font-size: 24px; }
      .banner-has-draws .banner-logo--container.image-side .money-banner {
        margin-top: 0;
        display: block; }
        .banner-has-draws .banner-logo--container.image-side .money-banner .button-container {
          width: 100%;
          max-width: 280px;
          margin: 0 auto; }
        .banner-has-draws .banner-logo--container.image-side .money-banner .button-container .button {
          margin-top: 1.5rem;
          width: 100%; }
        .banner-has-draws .banner-logo--container.image-side .money-banner .main-money-container {
          margin-right: 0; }
          .banner-has-draws .banner-logo--container.image-side .money-banner .main-money-container h3 {
            font-size: 46px;
            justify-content: center;
            align-items: flex-end;
            display: block;
            text-align: center;
            line-height: 1.5;
            position: relative;
            margin: 0 auto; }
            .banner-has-draws .banner-logo--container.image-side .money-banner .main-money-container h3 span {
              font-size: 120px;
              margin-right: 0.5rem;
              display: block; }
          .banner-has-draws .banner-logo--container.image-side .money-banner .main-money-container .currency {
            font-size: 40px !important;
            align-self: flex-start;
            transform: translateY(-15px);
            position: absolute;
            left: -10px; }
    .banner-has-draws .banner-logo--container .future-logo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 3rem;
      transform: translateY(0); }
      @media only screen and (min-width: 980px) {
        .banner-has-draws .banner-logo--container .future-logo {
          transform: translateY(-2rem);
          margin: 0; } }
      .banner-has-draws .banner-logo--container .future-logo img {
        max-width: 3rem;
        margin-right: 1rem; }
        @media only screen and (min-width: 980px) {
          .banner-has-draws .banner-logo--container .future-logo img {
            max-width: 5rem; } }
      .banner-has-draws .banner-logo--container .future-logo h1 {
        font-size: 48px;
        letter-spacing: 2px;
        font-weight: 300;
        margin: 0;
        margin-top: 32px;
        text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2); }
    .banner-has-draws .banner-logo--container .money-banner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      align-content: center;
      padding-left: 0;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1rem; }
      .banner-has-draws .banner-logo--container .money-banner .main-money-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: center;
        margin-right: 6rem; }
        .banner-has-draws .banner-logo--container .money-banner .main-money-container p {
          font-size: 76px;
          line-height: 1;
          margin: -15px 0 0;
          opacity: 0.6;
          font-weight: 700;
          transform: translateX(15px); }
        .banner-has-draws .banner-logo--container .money-banner .main-money-container h3 {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          flex-wrap: wrap;
          line-height: 0.8;
          font-size: 128px;
          letter-spacing: 6px;
          max-width: 45rem;
          font-weight: 700;
          margin: 0;
          text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2); }
          .banner-has-draws .banner-logo--container .money-banner .main-money-container h3 span {
            font-size: 162px;
            letter-spacing: 1px;
            line-height: 0.73; }
      .banner-has-draws .banner-logo--container .money-banner .cub-title-money p {
        padding-left: 4.5rem;
        padding-top: 1rem;
        font-size: 20px;
        line-height: 1;
        margin: -14px 0 0;
        opacity: 1;
        font-weight: 400; }
      .banner-has-draws .banner-logo--container .money-banner .button-container .button {
        margin: 0;
        margin-top: 3.5rem;
        font-size: 18px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); }
        .banner-has-draws .banner-logo--container .money-banner .button-container .button:hover {
          background-color: #349a00; }
      .banner-has-draws .banner-logo--container .money-banner .button-container p {
        padding-top: 2rem;
        font-size: 14px;
        line-height: 1;
        margin: -14px 0 0;
        opacity: 1;
        font-weight: 700;
        text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2); }
    .banner-has-draws .banner-logo--container .banner-content {
      display: flex;
      justify-content: space-between; }
      .banner-has-draws .banner-logo--container .banner-content .button-container {
        margin: 0;
        font-size: 18px; }

.banner-draws {
  background: #fb8e0c;
  text-align: left;
  z-index: 9;
  padding-left: 40px; }
  @media screen and (max-width: 1080px) {
    .banner-draws {
      background: transparent; } }
  .banner-draws > h2 {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    max-width: 360px; }
  .banner-draws .banner-draw {
    width: 100%;
    cursor: pointer;
    padding: 15px 30px;
    margin-bottom: 20px;
    max-width: 360px;
    display: block;
    margin-left: 0;
    transition: all ease-in-out 0.2s; }
    .banner-draws .banner-draw:hover {
      transform: scale(1.01); }
    .banner-draws .banner-draw .banner-draw--cart {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #2C68A5; }
      .banner-draws .banner-draw .banner-draw--cart svg {
        margin-left: 0.5rem;
        width: 20px; }
    .banner-draws .banner-draw > div {
      display: flex;
      align-items: flex-start; }
      .banner-draws .banner-draw > div > * {
        flex: 0 0 51%;
        font-size: 16px !important;
        margin: 2.5px 0; }
        .banner-draws .banner-draw > div > *:first-child {
          text-align: left !important; }
        .banner-draws .banner-draw > div > *:last-child {
          text-align: right; }
      .banner-draws .banner-draw > div svg {
        height: 15px;
        width: auto;
        flex: 0 0 auto; }
    .banner-draws .banner-draw h3 {
      color: #2C68A5; }
    .banner-draws .banner-draw:hover {
      opacity: 1; }
  @media only screen and (max-width: 1080px) {
    .banner-draws {
      position: static;
      width: 100%;
      height: auto;
      padding: 20px;
      text-align: center; }
      .banner-draws h2 {
        margin-top: 0;
        padding-top: 1rem;
        margin: 0 auto 2rem; }
      .banner-draws .banner-draw {
        max-width: 320px;
        display: block;
        margin: 0 auto 1rem; } }

@media only screen and (max-width: 1080px) {
  .banner-has-draws {
    background: linear-gradient(to right, #f66c1c 0%, #fea500 100%); }
    .banner-has-draws .container {
      display: block;
      background-image: none !important;
      width: 92.5% !important;
      max-width: 100% !important; }
    .banner-has-draws .banner {
      padding-bottom: 0 !important; }
      .banner-has-draws .banner.section-image .banner-image {
        left: 0; }
      .banner-has-draws .banner .banner-logo--container.image-side {
        display: block;
        text-align: center; }
        .banner-has-draws .banner .banner-logo--container.image-side img {
          margin-right: 0;
          width: auto; }
        .banner-has-draws .banner .banner-logo--container.image-side .future-logo {
          margin-top: 1rem; }
        .banner-has-draws .banner .banner-logo--container.image-side .future-logo h1 {
          margin-left: 0;
          margin-top: 1rem; } }

.futureball {
  position: relative;
  /* Defaullts */
  /* Banner */
  /* Page Layout */
  /* Resonsive */ }
  .futureball .banner {
    max-height: 23rem;
    min-height: 23rem; }
    .futureball .banner .banner-image {
      left: 50%; }
  .futureball .section.thick {
    padding: 5rem 0; }
  @media only screen and (min-width: 980px) {
    .futureball .banner {
      max-height: 23rem;
      min-height: 23rem; } }
  @media only screen and (min-width: 1080px) {
    .futureball .banner .container {
      max-width: 73.75rem;
      width: 92.5%; } }
  .futureball p {
    font-size: 18px;
    line-height: 1.5; }
  .futureball .blue-bg {
    background-color: #037bdb; }
  .futureball .two-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
    @media screen and (max-width: 1050px) {
      .futureball .two-col {
        width: 100%;
        max-width: 80rem; } }
    .futureball .two-col .col {
      width: 45%;
      text-align: left; }
      @media screen and (max-width: 1050px) {
        .futureball .two-col .col {
          width: 100%;
          margin: 1rem 0; } }
    .futureball .two-col .col {
      width: 45%;
      text-align: left; }
      @media screen and (max-width: 1050px) {
        .futureball .two-col .col {
          width: 100%;
          margin: 1rem 0; } }
  .futureball .orange-gradient {
    background: linear-gradient(183deg, #f6711f 6%, #ffa954 100%); }
    .futureball .orange-gradient .white-boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .futureball .orange-gradient .white-boxes .col {
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        padding: 20px; }
        @media screen and (max-width: 980px) {
          .futureball .orange-gradient .white-boxes .col {
            width: 100%;
            max-width: 80rem;
            margin: 1rem 0; } }
  .futureball .orange-text {
    color: #f66c1c; }
  .futureball .blue-text {
    color: #2096eb; }
  .futureball .button-container {
    text-align: center;
    margin-top: 60px; }
  .futureball .green-full {
    color: white;
    background-color: #4bc90b;
    font-size: 18px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); }
    .futureball .green-full:hover {
      background-color: #349a00; }
  .futureball .banner-logo--container {
    padding-bottom: 2rem; }
    .futureball .banner-logo--container .future-logo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 3rem;
      transform: translateY(0); }
      @media only screen and (min-width: 980px) {
        .futureball .banner-logo--container .future-logo {
          transform: translateY(-2rem);
          margin: 0; } }
      .futureball .banner-logo--container .future-logo img {
        max-width: 3rem;
        margin-right: 1rem; }
        @media only screen and (min-width: 980px) {
          .futureball .banner-logo--container .future-logo img {
            max-width: 5rem; } }
      .futureball .banner-logo--container .future-logo h3 {
        font-size: 48px;
        letter-spacing: 2px;
        font-weight: 300;
        margin: 0;
        margin-top: 32px; }
    .futureball .banner-logo--container .money-banner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      align-content: center;
      padding-left: 0;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1rem; }
      .futureball .banner-logo--container .money-banner .main-money-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: center;
        margin-right: 6rem; }
        .futureball .banner-logo--container .money-banner .main-money-container .currency,
        .futureball .banner-logo--container .money-banner .main-money-container p {
          font-size: 63px !important;
          line-height: 1;
          opacity: 0.6;
          font-weight: 700;
          margin: 0 0 auto; }
        .futureball .banner-logo--container .money-banner .main-money-container h1 {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          flex-wrap: wrap;
          line-height: 0.8;
          font-size: 119px;
          letter-spacing: 0px;
          max-width: 45rem;
          margin: 0;
          font-weight: 600; }
          .futureball .banner-logo--container .money-banner .main-money-container h1 span {
            font-size: 162px;
            letter-spacing: 1px;
            line-height: 0.73;
            font-weight: 600; }
      .futureball .banner-logo--container .money-banner .cub-title-money p {
        padding-left: 4.5rem;
        padding-top: 1.8rem;
        font-size: 18px;
        line-height: 1;
        margin: -14px 0 0;
        opacity: 1;
        font-weight: 400; }
      .futureball .banner-logo--container .money-banner .button-container .button {
        margin: 0;
        margin-top: 3.5rem;
        font-size: 18px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); }
        .futureball .banner-logo--container .money-banner .button-container .button:hover {
          background-color: #349a00; }
      .futureball .banner-logo--container .money-banner .button-container p {
        padding-top: 2rem;
        font-size: 14px;
        line-height: 1;
        margin: -14px 0 0;
        opacity: 1;
        font-weight: 700;
        text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2); }
    .futureball .banner-logo--container .banner-content {
      display: flex;
      justify-content: space-between; }
      .futureball .banner-logo--container .banner-content .button-container {
        margin: 0;
        font-size: 18px; }
  .futureball .popular-tab {
    display: inline-block;
    padding: 7px 27px;
    padding-bottom: 9px;
    color: white;
    letter-spacing: 0.5px;
    background-color: #f9993b;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); }
  .futureball .white-border--container {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid white;
    text-align: center;
    color: white; }
    .futureball .white-border--container h3 {
      margin: 0;
      color: white;
      font-size: 26px; }
  .futureball .plays-container.three-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 4rem; }
    @media screen and (max-width: 900px) {
      .futureball .plays-container.three-col {
        padding: 1rem 0rem; } }
    .futureball .plays-container.three-col .col {
      width: 31%;
      margin: 20px 0;
      text-align: left;
      position: relative;
      padding: 2rem 3rem; }
      @media screen and (max-width: 900px) {
        .futureball .plays-container.three-col .col {
          width: 47%; } }
      @media screen and (max-width: 600px) {
        .futureball .plays-container.three-col .col {
          width: 100%; } }
      .futureball .plays-container.three-col .col:nth-child(2) .popular-tab {
        position: absolute;
        top: -15px;
        right: 15px; }
      .futureball .plays-container.three-col .col h4 {
        font-size: 26px;
        margin: 0 0 0.4rem 0; }
      .futureball .plays-container.three-col .col p {
        font-size: 20px;
        margin: 0; }
  .futureball .white-box--shadow {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
    border-radius: 10px; }
    .futureball .white-box--shadow .cart-icon {
      max-width: 2.2rem;
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem; }
  .futureball .lottery-signup .container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .futureball .lottery-signup .container .signup-item {
      margin-right: 20px; }
      .futureball .lottery-signup .container .signup-item.s-button {
        margin: 0;
        padding-left: 60px; }
  .futureball .app-content--section .two-col.wide-left {
    align-items: inherit; }
  .futureball .why-video .two-col .col .orange-text {
    font-size: 26px;
    color: #f66c1c;
    margin-top: 0; }
  .futureball .why-video .two-col .col:first-child {
    width: 40%; }
    @media screen and (max-width: 1050px) {
      .futureball .why-video .two-col .col:first-child {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center; } }
    .futureball .why-video .two-col .col:first-child ul {
      margin: 0;
      padding: 0; }
      @media screen and (max-width: 1050px) {
        .futureball .why-video .two-col .col:first-child ul {
          text-align: left;
          max-width: 23rem;
          margin: auto; } }
      .futureball .why-video .two-col .col:first-child ul li {
        margin: 0;
        padding: 0.6rem 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
        .futureball .why-video .two-col .col:first-child ul li img {
          max-width: 2.2rem;
          height: auto;
          margin-right: 1rem; }
        .futureball .why-video .two-col .col:first-child ul li span {
          font-size: 20px;
          color: #1b90e8;
          display: block; }
  .futureball .why-video .two-col .col:last-child {
    width: 56%; }
    @media screen and (max-width: 1050px) {
      .futureball .why-video .two-col .col:last-child {
        width: 100%;
        margin-top: 1rem; } }
  .futureball .orange-gradient .white-boxes {
    max-width: 64rem; }
    .futureball .orange-gradient .white-boxes .two-col .col {
      padding-bottom: 4rem; }
      @media screen and (max-width: 1050px) {
        .futureball .orange-gradient .white-boxes .two-col .col {
          width: 100%;
          max-width: 80rem;
          margin: 1rem 0; } }
      .futureball .orange-gradient .white-boxes .two-col .col h3 {
        text-align: center;
        font-size: 26px;
        color: #f66c1c; }
      .futureball .orange-gradient .white-boxes .two-col .col .list-col {
        padding: 0 4rem; }
        @media screen and (max-width: 700px) {
          .futureball .orange-gradient .white-boxes .two-col .col .list-col {
            padding: 0; } }
        .futureball .orange-gradient .white-boxes .two-col .col .list-col ul {
          margin: 0;
          padding: 0; }
          .futureball .orange-gradient .white-boxes .two-col .col .list-col ul li {
            font-size: 16px;
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: 2;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between; }
            .futureball .orange-gradient .white-boxes .two-col .col .list-col ul li span {
              width: 46%;
              text-align: left; }
            .futureball .orange-gradient .white-boxes .two-col .col .list-col ul li .light-blue {
              color: #1b90e8; }
            .futureball .orange-gradient .white-boxes .two-col .col .list-col ul li .dark-blue {
              color: #045b9c; }
  .futureball .content-block h3 {
    font-size: 28px; }
  .futureball .content-block p {
    font-size: 18px; }
  .futureball .content-block .two-col {
    width: 100%; }
  .futureball .slide-container .slide-content {
    text-align: center;
    font-size: 16px;
    color: #045b9c;
    max-width: 80%;
    margin: auto;
    padding-bottom: 1.5rem; }
  .futureball .range-slide--container {
    background: none !important; }
    .futureball .range-slide--container input[type="range"] {
      -webkit-appearance: none;
      margin: 18px 0 30px;
      width: 100%; }
    .futureball .range-slide--container input[type="range"]:focus {
      outline: none; }
    .futureball .range-slide--container input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      cursor: pointer;
      animate: 0.2s;
      background: whitesmoke;
      border-radius: 2rem; }
    .futureball .range-slide--container input[type="range"]::-webkit-slider-thumb {
      height: 1.3rem;
      width: 1.3rem;
      border-radius: 100%;
      background: #464d4f;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -0.4rem; }
    .futureball .range-slide--container input[type="range"]:focus::-webkit-slider-runnable-track {
      background: whitesmoke; }
  .futureball .range-slider {
    display: block !important; }
    .futureball .range-slider .divisions-container {
      display: flex;
      align-items: center; }
      .futureball .range-slider .divisions-container h4,
      .futureball .range-slider .divisions-container .lottery-numbers {
        flex: 0 0 45%;
        margin-top: 0 !important; }
      .futureball .range-slider .divisions-container .lottery-numbers {
        justify-content: flex-start; }
      .futureball .range-slider .divisions-container h4 {
        order: -1;
        margin-bottom: 0;
        text-align: right; }
      .futureball .range-slider .divisions-container p {
        order: 3;
        margin-top: 1rem; }
    .futureball .range-slider .lottery-number {
      width: 0.6rem !important;
      height: 0.6rem !important;
      flex: 0 0 0.6rem !important;
      min-width: 0.6rem !important;
      display: block;
      border-radius: 100%; }
  .futureball .division-text {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem; }
    .futureball .division-text p {
      font-size: 16px;
      font-weight: 700;
      color: #045b9c;
      margin: 0;
      margin-right: 1rem; }
  .futureball .division-bottom {
    text-align: center;
    font-size: 14px;
    color: #7f7f7f; }
  .futureball .white-box--shadow.numbers {
    padding: 4rem; }
    @media screen and (max-width: 900px) {
      .futureball .white-box--shadow.numbers {
        padding: 2rem 0; } }
  .futureball .number-title {
    margin: 0 0 3rem;
    color: #1b90e8;
    font-size: 26px;
    letter-spacing: 1px; }
    @media screen and (max-width: 600px) {
      .futureball .number-title {
        margin-bottom: 1.5rem;
        font-size: 22px; } }
  .futureball .number-container {
    margin: 0;
    margin-bottom: 3rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (max-width: 900px) {
      .futureball .number-container {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 600px) {
      .futureball .number-container {
        margin-bottom: 1rem; } }
    .futureball .number-container li {
      list-style: none;
      margin: 0 0.5rem;
      padding: 0; }
      @media screen and (max-width: 900px) {
        .futureball .number-container li {
          list-style: none;
          margin: 0 0.5rem;
          padding: 5px;
          box-sizing: border-box; } }
      @media screen and (max-width: 860px) {
        .futureball .number-container li {
          padding: 0;
          text-align: center;
          margin: 2% 1%; } }
      @media screen and (max-width: 500px) {
        .futureball .number-container li {
          margin: 2% 0.5%; } }
      .futureball .number-container li .circle-number {
        display: block;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700; }
        @media screen and (max-width: 860px) {
          .futureball .number-container li .circle-number {
            margin: auto;
            width: 3rem;
            height: 3rem; } }
        @media screen and (max-width: 600px) {
          .futureball .number-container li .circle-number {
            width: 2.5rem;
            height: 2.5rem; } }
        @media screen and (max-width: 500px) {
          .futureball .number-container li .circle-number {
            width: 2.25rem;
            height: 2.25rem; } }
        .futureball .number-container li .circle-number.orange {
          background-color: #f66c1c; }
        .futureball .number-container li .circle-number.blue {
          background-color: #1b90e8; }
  .futureball .view-all {
    font-size: 20px; }
  .futureball .small-top--content {
    max-width: 45rem;
    margin: auto;
    margin-bottom: 4rem;
    text-align: left; }
    .futureball .small-top--content .orange-text {
      color: #f66c1c;
      font-weight: normal;
      margin-bottom: 2rem;
      font-size: 26px; }
  @media screen and (max-width: 1080px) {
    .futureball .banner-logo--container {
      padding-top: 2rem; }
    .futureball .banner {
      padding: 2rem 0;
      max-height: none; }
    .futureball .banner .banner-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 170% !important;
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      max-width: none;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(0%); }
    .futureball .banner .container {
      width: 100%;
      max-width: 90%;
      z-index: 2;
      position: relative; }
    .futureball .banner-logo--container .future-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem 0 2rem 0px; } }
  @media only screen and (max-width: 1080px) and (max-width: 450px) {
    .futureball .banner-logo--container .future-logo {
      margin-bottom: 10px; } }
  @media screen and (max-width: 1080px) {
    .futureball .banner-logo--container .future-logo img {
      max-width: 5rem;
      margin-right: 1rem; } }
  @media only screen and (max-width: 1080px) and (max-width: 450px) {
    .futureball .banner-logo--container .future-logo img {
      max-width: 3.5rem; } }
  @media screen and (max-width: 1080px) {
    .futureball .banner-logo--container .future-logo h3 {
      font-size: 30px; } }
  @media only screen and (max-width: 1080px) and (max-width: 450px) {
    .futureball .banner-logo--container .future-logo h3 {
      margin-top: 0;
      font-size: 23px;
      letter-spacing: 1px; } }
  @media screen and (max-width: 1080px) {
    .futureball .banner-logo--container .money-banner,
    .futureball .banner-logo--container .money-banner .main-money-container {
      display: flex;
      align-content: flex-start;
      justify-content: flex-start; }
    .futureball .banner-logo--container .money-banner {
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;
      align-items: center;
      margin-top: 0; }
    .futureball .flex-money--wrap {
      width: 100%;
      text-align: center; }
    .futureball .banner-logo--container .money-banner .main-money-container {
      align-items: flex-start;
      justify-content: center;
      margin-right: 0;
      width: 100%; }
    .futureball .banner-logo--container .money-banner .main-money-container .currency {
      font-size: 76px !important;
      line-height: 1;
      opacity: 0.6; }
    .futureball .banner-logo--container .money-banner .main-money-container h1 {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-wrap: wrap;
      line-height: 0.8;
      font-size: 107px;
      letter-spacing: 6px;
      max-width: 45rem;
      margin: 0;
      margin-right: 2rem; } }
  @media only screen and (max-width: 1080px) and (max-width: 450px) {
    .futureball .banner-logo--container .money-banner .main-money-container h1 {
      font-size: 65px; } }
  @media screen and (max-width: 1080px) {
    .futureball .futureball
.banner-logo--container
.money-banner
.main-money-container
h1
span {
      font-size: 141px;
      letter-spacing: 1px;
      line-height: 0.73;
      margin-left: 1rem; }
    .futureball .banner-logo--container .money-banner .cub-title-money p {
      padding-left: 0;
      padding-top: 1rem;
      font-size: 20px;
      line-height: 1;
      margin: 0;
      opacity: 1;
      font-weight: 400; }
    .futureball .banner-logo--container .banner-content {
      display: flex;
      justify-content: space-between; }
    .futureball .banner-logo--container .banner-content .button-container {
      margin: 0;
      font-size: 18px; }
    .futureball .futureball
.banner-logo--container
.money-banner
.button-container
.button {
      margin: 2rem 0 0;
      font-size: 18px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); }
    .futureball .banner-logo--container .money-banner .button-container p {
      padding-top: 2rem;
      font-size: 14px;
      line-height: 1;
      margin: -14px 0 0;
      opacity: 1;
      font-weight: 700;
      text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2); } }
  @media screen and (max-width: 730px) {
    .futureball .banner-logo--container .future-logo img {
      max-width: 4rem; }
    .futureball .banner-logo--container .future-logo {
      margin-top: 0; }
    .futureball .banner-logo--container {
      padding-top: 0; }
    .futureball .banner-logo--container .money-banner .main-money-container h1 {
      font-size: 60px;
      margin: 0;
      letter-spacing: -1px;
      justify-content: center; }
    .futureball .banner-logo--container .money-banner .main-money-container h1 span {
      font-size: 90px;
      letter-spacing: -1px;
      line-height: 0.73;
      margin-left: 1rem;
      font-weight: 600; }
    .futureball .banner-logo--container .money-banner .button-container .button {
      margin-top: 2rem; }
    .futureball .banner-logo--container .money-banner .main-money-container p {
      font-size: 50px !important; }
    .futureball .banner-logo--container .money-banner .cub-title-money p {
      font-size: 15px; } }

@media only screen and (min-width: 1560px) {
  .banner-has-draws .banner.section-image .banner-image {
    left: 20%; } }

@media only screen and (max-width: 480px) {
  .banner-has-draws
.banner-logo--container.image-side
.money-banner
.main-money-container
h3 {
    line-height: 1.5;
    margin: 2rem 0 0; }
    .banner-has-draws
.banner-logo--container.image-side
.money-banner
.main-money-container
h3 span + span {
      display: block; } }

.banner-has-draws .futureball-banner {
  max-height: none;
  padding: 30px 0 0 !important;
  background: linear-gradient(to right, #f66c1c 0%, #fea500 100%); }
  .banner-has-draws .futureball-banner .container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-image: url(images/futureball-banner.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 78%;
    padding-bottom: 45px;
    max-width: 66rem; }
    @media only screen and (max-width: 980px) {
      .banner-has-draws .futureball-banner .container {
        display: block;
        background-image: none;
        text-align: center;
        width: 92.5% !important; }
        .banner-has-draws .futureball-banner .container h1 {
          font-size: 56px !important;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 10px; }
          .banner-has-draws .futureball-banner .container h1 .currency {
            position: static !important;
            transform: none !important;
            font-size: 34px !important;
            margin-right: 10px;
            width: auto; }
          .banner-has-draws .futureball-banner .container h1 span {
            font-size: 26px !important;
            width: 100%; }
        .banner-has-draws .futureball-banner .container .button-container {
          text-align: center !important; }
        .banner-has-draws .futureball-banner .container .image-side {
          margin: 0 auto; }
        .banner-has-draws .futureball-banner .container ul {
          display: inline-flex;
          flex-direction: column; }
          .banner-has-draws .futureball-banner .container ul li {
            flex: 0 0 auto;
            display: inline-block; } }
  .banner-has-draws .futureball-banner .banner-logo--container {
    width: 150px;
    margin-right: 75px; }
  .banner-has-draws .futureball-banner .banner-content h1 {
    font-size: 140px;
    max-width: none;
    line-height: 1; }
    .banner-has-draws .futureball-banner .banner-content h1 span {
      font-size: 34px;
      font-weight: 400;
      margin-bottom: 26px; }
    .banner-has-draws .futureball-banner .banner-content h1 .currency {
      position: absolute;
      top: 0;
      left: -10px;
      transform: translate(-100%, 25%);
      font-size: 56px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5); }
  .banner-has-draws .futureball-banner .banner-content ul {
    padding: 0;
    margin: 0; }
    .banner-has-draws .futureball-banner .banner-content ul li {
      font-size: 18px;
      list-style: none;
      position: relative;
      padding-left: 1rem; }
      .banner-has-draws .futureball-banner .banner-content ul li:before {
        content: url(images/checkmark.svg);
        margin-right: 10px;
        margin-left: -10px; }
  .banner-has-draws .futureball-banner .banner-content .button-container {
    text-align: left;
    margin-top: 38px; }
    .banner-has-draws .futureball-banner .banner-content .button-container .button {
      margin-top: 0;
      padding: 15px 33px;
      font-size: 18px;
      font-weight: 600; }
    .banner-has-draws .futureball-banner .banner-content .button-container p {
      font-size: 14px;
      margin: 10px 15px 0;
      font-weight: 600; }

.columns {
  display: flex;
  justify-content: space-between;
  padding: 60px 40px;
  margin-top: 40px;
  border-top: 1px solid white; }
  .columns .col {
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    flex: 1;
    margin-right: 20px; }
    .columns .col h2 {
      text-align: center;
      font-size: 20px;
      color: #E25F2C;
      margin: 0 0 18px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .columns .col h2 img {
        margin-left: 10px;
        height: 45px;
        width: auto;
        margin-left: 15px; }
    .columns .col > div {
      display: flex;
      margin-bottom: 10px; }
    .columns .col p {
      flex: 1;
      text-align: left;
      font-size: 14px;
      color: #2C68A5;
      margin: 0; }
      .columns .col p:last-of-type {
        font-weight: 600; }
    .columns .col .futureballs {
      flex: 1;
      display: flex;
      justify-content: flex-start; }
      .columns .col .futureballs span {
        height: 25px;
        width: 25px;
        border-radius: 100%;
        background-color: #fb8e0c;
        margin-right: 5px; }
        .columns .col .futureballs span.blue {
          background-color: #2C68A5; }
        .columns .col .futureballs span:last-of-type {
          margin: 0; }
    .columns .col:last-of-type {
      margin-right: 0; }
      .columns .col:last-of-type h2 {
        margin-bottom: 35px; }
      .columns .col:last-of-type > div {
        margin-bottom: 22px; }
      .columns .col:last-of-type p:first-of-type {
        flex: 0.5; }

.general-content--columns {
  text-align: left;
  padding: 68px 0 45px;
  margin-top: 0; }
  .general-content--columns .container {
    display: flex;
    flex-wrap: wrap; }
  .general-content--columns .col {
    padding: 0;
    margin-right: 80px; }
    .general-content--columns .col h2 {
      margin-bottom: 21px; }
      .general-content--columns .col h2 img {
        width: 33px;
        height: 33px; }
    .general-content--columns .col p {
      margin-bottom: 10px; }
    .general-content--columns .col:last-of-type {
      margin-right: 0; }
      .general-content--columns .col:last-of-type h2 {
        margin-bottom: 25px; }
  .general-content--columns ul {
    padding: 0;
    margin: 0; }
    .general-content--columns ul li {
      font-size: 14px;
      color: #2C68A5;
      margin: 0 0 10px;
      list-style: none;
      position: relative;
      padding-left: 1rem; }
      .general-content--columns ul li:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 9px;
        height: 7px;
        background-image: url(images/orange-checkmark.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; }

@media screen and (max-width: 1350px) {
  .columns .col {
    padding: 30px 25px; }
    .columns .col > div {
      margin: 0 0 15px; }
    .columns .col .futureballs {
      min-width: 172px; }
      .columns .col .futureballs span {
        width: 20px;
        height: 20px; }
    .columns .col:last-of-type p:first-of-type {
      flex: 0 0 auto;
      min-width: 60px; } }

@media screen and (max-width: 1020px) {
  .columns .col > div {
    flex-wrap: wrap;
    margin-bottom: 15px; }
    .columns .col > div .futureballs {
      flex: 0 0 100%;
      margin: 5px 0 0; } }

@media screen and (max-width: 768px) {
  .columns {
    flex-wrap: wrap;
    padding: 40px 0 0; }
    .columns .col {
      flex: 0 0 92.5%;
      margin: 0 auto 20px !important; }
      .columns .col h2 {
        text-align: center;
        justify-content: center !important;
        flex-direction: column-reverse !important;
        display: flex !important; }
        .columns .col h2 img {
          position: static;
          margin: 0 auto 15px;
          width: 60px;
          height: auto; }
      .columns .col.info-col {
        padding: 30px 15px; }
        .columns .col.info-col > div {
          display: flex; }
          .columns .col.info-col > div p {
            flex: 0 0 60%;
            text-align: left; }
            .columns .col.info-col > div p + p {
              flex: 0 0 40%; }
      .columns .col > div {
        justify-content: center;
        display: block;
        text-align: center; }
        .columns .col > div p {
          text-align: center;
          display: block; }
        .columns .col > div > * {
          flex: 0 0 auto; }
        .columns .col > div .futureballs {
          flex: 2;
          justify-content: center; }
      .columns .col:last-of-type {
        margin: 0; } }

@media screen and (max-width: 765px) {
  .general-content--columns {
    padding: 40px 0 0; }
    .general-content--columns .container {
      flex-wrap: wrap; }
    .general-content--columns .col {
      flex: 0 0 100%;
      margin: 0 0 40px;
      text-align: center; }
      .general-content--columns .col p {
        text-align: left; }
      .general-content--columns .col ul li {
        padding: 0;
        text-align: left; }
        .general-content--columns .col ul li:before {
          position: relative;
          top: 0;
          transform: translateY(0);
          display: inline-block;
          margin-right: 5px; } }

@media screen and (max-width: 550px) {
  .columns .col {
    padding: 30px 10px; }
    .columns .col > div .futureballs {
      flex: 0 0 100%; }
    .columns .col .container-small {
      width: 92.5%; }
    .columns .col:last-of-type h2 {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .columns .col:last-of-type h2 img {
        position: relative;
        top: 0;
        transform: translateY(0);
        left: 0; } }

@media screen and (max-width: 425px) {
  .columns .col:last-of-type h2 {
    font-size: 18px;
    margin-bottom: 20px; }
    .columns .col:last-of-type h2 img {
      height: 35px;
      width: 35px; } }

@media screen and (max-width: 320px) {
  .columns .col > div p {
    flex: 0 0 100%;
    text-align: center; } }

/*app	*/
.header-notice {
  background: #f6711f;
  background: linear-gradient(183deg, #f6711f 6%, #ffa954 100%); }
  .header-notice p {
    margin: 0;
    padding: 0.6rem;
    padding-bottom: 0.7rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px; }

.app .banner.banner-image {
  position: absolute;
  left: 50%;
  top: 0;
  max-width: 108%;
  height: auto;
  transform: translateX(-50%); }
  .app .banner.banner-image .button-container--double {
    display: flex; }
    .app .banner.banner-image .button-container--double img {
      max-width: 100px; }

.app .download-button--container {
  padding-top: 30px; }
  .app .download-button--container img {
    max-width: 170px;
    margin-right: 20px; }

.app .main-app-section {
  padding: 9rem 0 5rem; }
  @media screen and (max-width: 900px) {
    .app .main-app-section {
      padding: 4rem 0 0; } }

@media screen and (max-width: 900px) {
  .app .appinfo-container {
    max-width: 800px; } }

.app .appinfo-container .three-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .app .appinfo-container .three-col .col {
    width: 30%; }
    @media screen and (max-width: 900px) {
      .app .appinfo-container .three-col .col {
        width: 100%; } }
    @media screen and (max-width: 900px) {
      .app .appinfo-container .three-col .col:nth-child(2) img {
        padding: 2rem 0;
        max-width: 300px;
        width: 100%;
        margin: auto; } }
    .app .appinfo-container .three-col .col ul.app-iconlist-left {
      text-align: right;
      padding: 3rem 5rem 0 0; }
      .app .appinfo-container .three-col .col ul.app-iconlist-left .icon-list-item {
        text-align: right;
        position: absolute;
        right: -3.5rem;
        top: 0rem;
        max-width: 2.5rem;
        width: 100%; }
      @media screen and (max-width: 900px) {
        .app .appinfo-container .three-col .col ul.app-iconlist-left {
          text-align: left;
          padding: 3rem 0 0 5rem;
          padding-top: 0; }
          .app .appinfo-container .three-col .col ul.app-iconlist-left .icon-list-item {
            text-align: left;
            position: absolute;
            left: -3.5rem;
            top: 4px;
            max-width: 2.5rem;
            width: 100%; } }
    .app .appinfo-container .three-col .col ul.app-iconlist-right {
      text-align: left;
      padding: 3rem 0 0 5rem; }
      @media screen and (max-width: 900px) {
        .app .appinfo-container .three-col .col ul.app-iconlist-right {
          padding-top: 0; } }
      .app .appinfo-container .three-col .col ul.app-iconlist-right .icon-list-item {
        text-align: left;
        position: absolute;
        left: -3.5rem;
        top: 0rem;
        max-width: 2.5rem;
        width: 100%; }
        @media screen and (max-width: 900px) {
          .app .appinfo-container .three-col .col ul.app-iconlist-right .icon-list-item {
            top: 4px; } }
    .app .appinfo-container .three-col .col ul li {
      margin: 0;
      padding: 0;
      padding-bottom: 3rem;
      list-style: none;
      position: relative; }
      @media screen and (max-width: 900px) {
        .app .appinfo-container .three-col .col ul li {
          padding-bottom: 10px; } }
      .app .appinfo-container .three-col .col ul li:last-child {
        padding-bottom: 0; }
      .app .appinfo-container .three-col .col ul li .icon-list-item img {
        max-width: 3.9rem;
        width: 100%; }

.app .download-button--container.app-bottom {
  transform: translateY(7rem);
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 900px) {
    .app .download-button--container.app-bottom {
      transform: translateY(2rem); } }
  .app .download-button--container.app-bottom a.app-button {
    margin: 0 1rem;
    display: inline-block; }
    @media screen and (max-width: 900px) {
      .app .download-button--container.app-bottom a.app-button {
        margin: 0; } }
    .app .download-button--container.app-bottom a.app-button img {
      max-width: 180px; }

.app-grey--section {
  padding: 6rem;
  background-color: #f6f6f6; }
  @media screen and (max-width: 900px) {
    .app-grey--section {
      padding: 4rem 2rem; } }

.app-grey--section .orange-title {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
  text-align: left; }
  .app-grey--section .orange-title h3 {
    color: #f66c1c;
    margin: 0;
    font-size: 26px;
    text-align: left; }
    @media screen and (max-width: 900px) {
      .app-grey--section .orange-title h3 {
        text-align: center; } }
  @media screen and (max-width: 900px) {
    .app-grey--section .orange-title {
      width: 100%;
      text-align: center; } }

.app-grey--section .two-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1; }
  .app-grey--section .two-col .col:first-child {
    width: 65%;
    text-align: left; }
    @media screen and (max-width: 900px) {
      .app-grey--section .two-col .col:first-child {
        width: 100%;
        text-align: center; } }
    .app-grey--section .two-col .col:first-child ul {
      padding: 0;
      margin: 0; }
      .app-grey--section .two-col .col:first-child ul li {
        margin: 0.8rem 0;
        list-style: none;
        font-size: 18px;
        color: #000000; }
  .app-grey--section .two-col .col:last-child {
    width: 30%;
    text-align: right;
    align-self: stretch; }
    @media screen and (max-width: 900px) {
      .app-grey--section .two-col .col:last-child {
        width: 100%;
        text-align: center;
        margin-top: 20px; } }
    .app-grey--section .two-col .col:last-child a {
      margin: 8px 0;
      border: none;
      backface-visibility: hidden;
      padding: 10px 40px;
      font-weight: 300;
      box-sizing: border-box; }
      .app-grey--section .two-col .col:last-child a.orange-gradient {
        color: white;
        background: linear-gradient(183deg, #f6711f 6%, #ffa954 100%); }
        .app-grey--section .two-col .col:last-child a.orange-gradient:hover {
          background: linear-gradient(183deg, #f6711f 6%, #f6711f 100%); }
      .app-grey--section .two-col .col:last-child a.orange-border {
        border: solid 2px #f77522;
        color: #f77522; }
        .app-grey--section .two-col .col:last-child a.orange-border:hover {
          background-color: #f77522;
          color: white; }

.app-content--section {
  padding: 6rem 0; }
  .app-content--section .two-col.wide-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .app-content--section .two-col.wide-left .col:first-child {
      width: 65%;
      position: relative;
      min-height: 400px;
      border-radius: 20px;
      display: flex;
      align-content: flex-end;
      justify-content: center;
      position: relative;
      overflow: hidden; }
      @media screen and (max-width: 900px) {
        .app-content--section .two-col.wide-left .col:first-child {
          width: 100%;
          margin-bottom: 20px; } }
      .app-content--section .two-col.wide-left .col:first-child img {
        position: absolute;
        left: 50%;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        max-width: none;
        transform: translateX(-50%);
        z-index: 1; }
      .app-content--section .two-col.wide-left .col:first-child .dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: linear-gradient(183deg, transparent 20%, black 97%); }
      .app-content--section .two-col.wide-left .col:first-child .content-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 40px 70px;
        z-index: 20;
        text-align: left; }
        .app-content--section .two-col.wide-left .col:first-child .content-container * {
          color: white; }
        .app-content--section .two-col.wide-left .col:first-child .content-container h3 {
          font-size: 30px; }
        .app-content--section .two-col.wide-left .col:first-child .content-container p {
          font-size: 12px; }
    .app-content--section .two-col.wide-left .col:last-child {
      width: 33%;
      background: linear-gradient(183deg, #f6711f 6%, #ffa954 100%);
      border-radius: 20px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 900px) {
        .app-content--section .two-col.wide-left .col:last-child {
          width: 100%; } }
      .app-content--section .two-col.wide-left .col:last-child .inner {
        padding: 15px 30px;
        padding-top: 90px; }
        @media screen and (max-width: 900px) {
          .app-content--section .two-col.wide-left .col:last-child .inner {
            padding: 20px 30px; } }
        .app-content--section .two-col.wide-left .col:last-child .inner h4 {
          font-size: 26px;
          margin: 0;
          margin-bottom: 30px;
          color: white; }
        .app-content--section .two-col.wide-left .col:last-child .inner p {
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 30px;
          color: white; }
      .app-content--section .two-col.wide-left .col:last-child .button-wrap {
        display: block;
        margin-top: 0; }

/* Forms */
.input-other {
  align-items: center; }
  .input-other.active {
    display: flex !important; }
  .input-other span {
    color: #2C68A5;
    margin-right: 0.3125rem; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input, select, textarea {
  user-select: text !important; }
  input:disabled, select:disabled, textarea:disabled {
    border-color: rgba(0, 0, 0, 0.3) !important;
    color: rgba(0, 0, 0, 0.3) !important; }
    input:disabled::placeholder, select:disabled::placeholder, textarea:disabled::placeholder {
      color: rgba(0, 0, 0, 0.3) !important; }

.password-strength .password-strength--meter {
  height: 0.9375rem;
  width: 95%;
  border-radius: 1.5625rem;
  margin: 0 2.5% 0.9375rem; }

.password-strength .password-strength--validation p {
  text-align: left;
  margin: 0 0 0.3125rem !important;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center; }
  .password-strength .password-strength--validation p svg {
    height: 1.09375rem;
    margin-right: 0.46875rem;
    max-width: 1.5625rem; }

.password-strength .password-strength--validation .password-strength--title {
  color: #E25F2C;
  font-weight: 700;
  margin: 0 0 0.625rem !important; }

.toggle-password {
  position: absolute;
  right: 0.625rem;
  top: 0.9375rem;
  width: 1.25rem;
  height: 1.25rem;
  transition: all ease-in-out 0.19s; }
  .toggle-password * {
    fill: #2C68A5;
    transition: all ease-in-out 0.1s; }
  .toggle-password.active * {
    fill: #E25F2C; }

.has-placeholder {
  position: relative;
  display: flex;
  flex-direction: column; }
  .has-placeholder .placeholder {
    text-align: left;
    position: relative;
    top: 0.9375rem;
    max-height: 0;
    margin: 0;
    order: -1;
    transition: all ease-in-out 0.2s;
    padding-left: 1.40625rem;
    font-size: 12px !important;
    font-weight: 600; }
  .has-placeholder input:focus + .placeholder, .has-placeholder input:valid + .placeholder {
    max-height: 500px;
    top: 0;
    margin-bottom: 0.625rem;
    color: #E25F2C; }
  .has-placeholder input:focus ~ .toggle-password, .has-placeholder input:valid ~ .toggle-password {
    top: 2.5rem; }

form h3 {
  color: #E25F2C;
  font-weight: 600;
  font-size: 1em;
  margin: 0 0 0.9375rem; }

form p {
  color: #2C68A5;
  font-family: "open-sans";
  font-weight: 400;
  font-size: 0.875em; }

form .form-section {
  margin-top: 2.1875rem; }
  form .form-section input[type="submit"] {
    margin-top: 0.9375rem; }

form .input-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 0.75rem;
  flex-wrap: wrap; }
  form .input-row .input-error {
    margin-top: 0.46875rem; }
  form .input-row * {
    margin-bottom: 0 !important; }
  form .input-row > * {
    margin-right: 0.3125rem;
    flex: 1 1 auto !important;
    width: 0; }
    form .input-row > *:last-child {
      margin-right: 0; }
  form .input-row label input, form .input-row label button {
    width: 100%; }
  form .input-row button {
    flex: 0 0 auto;
    width: auto;
    align-self: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.875em;
    letter-spacing: 0;
    display: inline-block; }
    form .input-row button svg {
      width: 1.09375rem;
      height: 1.09375rem; }
  form .input-row input[type="submit"] {
    margin-top: 0 !important;
    width: 100%;
    -webkit-appearance: none; }
    form .input-row input[type="submit"]:active {
      background-color: inherit;
      box-shadow: none; }

form .input-error {
  text-align: left;
  display: block;
  color: #D0021B;
  font-size: 14px;
  font-weight: 600;
  padding: 0 1.40625rem;
  margin-bottom: 0.625rem;
  flex: 0 0 100% !important; }

form input, form textarea, form select, form .select-dropdown {
  margin-bottom: 0.78125rem; }

form label {
  cursor: pointer;
  position: relative; }
  form label.input-underline input, form label.input-underline textarea {
    border: none;
    border-bottom: 2px solid #2C68A5;
    border-radius: 0;
    width: 75%;
    font-size: 1em; }
    form label.input-underline input::placeholder, form label.input-underline textarea::placeholder {
      font-weight: 700; }
  form label.input-white input, form label.input-white textarea {
    border-color: white;
    background-color: transparent;
    color: white; }
  form label.input-blue input, form label.input-blue textarea {
    border-color: #2C68A5;
    background-color: transparent;
    color: #2C68A5; }
    form label.input-blue input::placeholder, form label.input-blue textarea::placeholder {
      color: #2C68A5; }
  form label.input-square input, form label.input-square textarea {
    border-radius: 1.25rem !important; }
  form label.input-orange-solid input, form label.input-orange-solid textarea, form label.input-orange-solid button {
    color: white;
    background-image: linear-gradient(to right, #F5993C 0%, #F76B1C 51%, #F5993C 100%) !important;
    background-size: 150% auto !important;
    border: none; }
  form label.input-green-solid input, form label.input-green-solid button {
    border: none;
    background-image: linear-gradient(to right, #90bc40 0%, #429321 51%, #90bc40 100%);
    background-size: 150% auto;
    color: white; }
  form label.input-full {
    width: 100%; }
    form label.input-full input, form label.input-full textarea, form label.input-full button {
      width: 100%; }
  form label.input-wide input, form label.input-wide button {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem; }
  form label.has-error {
    color: #D0021B; }
    form label.has-error input, form label.has-error textarea {
      border-color: #D0021B;
      margin-bottom: 0.46875rem; }
      form label.has-error input::placeholder, form label.has-error textarea::placeholder {
        color: #D0021B; }
      form label.has-error input:before, form label.has-error textarea:before {
        border-color: #D0021B; }

form textarea {
  background-color: transparent;
  border: 2px solid white;
  font-size: 0.875em;
  font-family: "open-sans";
  color: white;
  border-radius: 1.25rem;
  padding: 0.625rem 1.25rem 0.75rem;
  outline: none;
  height: 7.8125rem;
  resize: none; }
  form textarea::placeholder {
    color: white;
    font-size: inherit; }

form button {
  display: inline-block !important;
  text-align: center; }

form input, form button {
  outline: none;
  line-height: 1; }
  form input[type="text"], form input[type="number"], form input[type="password"], form input[type="email"], form button[type="text"], form button[type="number"], form button[type="password"], form button[type="email"] {
    background-color: transparent;
    border: 2px solid white;
    font-size: 12px !important;
    color: white;
    border-radius: 3.125rem;
    padding: 0.625rem 1.25rem 0.75rem;
    -webkit-appearance: none;
    position: relative;
    overflow: visible;
    font-weight: 600;
    height: 45px;
    line-height: 1;
    font-family: "open-sans";
    -moz-appearance: textfield;
    appearance: textfield; }
    form input[type="text"]::placeholder, form input[type="number"]::placeholder, form input[type="password"]::placeholder, form input[type="email"]::placeholder, form button[type="text"]::placeholder, form button[type="number"]::placeholder, form button[type="password"]::placeholder, form button[type="email"]::placeholder {
      color: inherit;
      line-height: 20px;
      font-size: inherit !important; }
    form input[type="text"]:-ms-input-placeholder, form input[type="number"]:-ms-input-placeholder, form input[type="password"]:-ms-input-placeholder, form input[type="email"]:-ms-input-placeholder, form button[type="text"]:-ms-input-placeholder, form button[type="number"]:-ms-input-placeholder, form button[type="password"]:-ms-input-placeholder, form button[type="email"]:-ms-input-placeholder {
      font-weight: 600 !important;
      font-size: 12px !important; }
  form input[type="submit"], form button[type="submit"] {
    border: 2px solid #E25F2C;
    background: none;
    box-shadow: none;
    border-radius: 3.125rem;
    color: #E25F2C;
    padding: 0.84375rem 1.875rem;
    font-size: 0.875em;
    font-weight: 600;
    font-family: "proxima-soft";
    align-self: flex-start;
    min-height: 2.8125rem;
    cursor: pointer;
    transition: all ease-in-out 0.2s; }
    form input[type="submit"]:hover, form button[type="submit"]:hover {
      background-color: #E25F2C;
      color: white; }
  form input[type="image"], form button[type="image"] {
    margin-left: 1.25rem; }
  form input[type="radio"], form input[type="checkbox"], form button[type="radio"], form button[type="checkbox"] {
    width: 0;
    position: relative;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden; }
    form input[type="radio"]:checked + .checkbox:before, form input[type="checkbox"]:checked + .checkbox:before, form button[type="radio"]:checked + .checkbox:before, form button[type="checkbox"]:checked + .checkbox:before {
      background-color: #2C68A5; }
    form input[type="radio"]:checked + .checkbox:after, form input[type="checkbox"]:checked + .checkbox:after, form button[type="radio"]:checked + .checkbox:after, form button[type="checkbox"]:checked + .checkbox:after {
      content: '';
      position: absolute;
      background: white;
      width: 0.3125rem;
      height: 0.3125rem;
      border-radius: 50%;
      left: 0.53125rem;
      top: 50%;
      transform: translateY(-50%); }
    form input[type="radio"] + .checkbox, form input[type="checkbox"] + .checkbox, form button[type="radio"] + .checkbox, form button[type="checkbox"] + .checkbox {
      position: absolute;
      width: auto;
      top: 50%;
      transform: translateY(-50%);
      left: 0; }
      form input[type="radio"] + .checkbox:before, form input[type="checkbox"] + .checkbox:before, form button[type="radio"] + .checkbox:before, form button[type="checkbox"] + .checkbox:before {
        content: '';
        width: 1.375rem;
        height: 1.375rem;
        border-radius: 50%;
        background-color: white;
        border: 2px solid #2C68A5;
        box-sizing: border-box;
        display: block; }

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.lottery-date-search p {
  color: white;
  margin-bottom: 0.9375rem;
  font-weight: 700; }

.lottery-date-search input, .lottery-date-search select-dropdown {
  margin: 0 !important;
  font-family: "proxima-soft" !important;
  font-weight: 500 !important; }

.radio-container {
  padding: 0;
  margin: 0.625rem auto 0.9375rem !important;
  text-align: left;
  width: 90%; }
  .radio-container textarea {
    width: 100%;
    margin-top: 0.9375rem; }
  .radio-container.has-error label, .radio-container.has-error .label-for {
    color: #D0021B; }
    .radio-container.has-error label input:before, .radio-container.has-error .label-for input:before {
      border-color: #D0021B; }
  .radio-container .input-error {
    margin-top: 0.625rem; }
  .radio-container label, .radio-container .label-for {
    text-align: left;
    display: flex !important;
    margin-bottom: 0.9375rem;
    color: #2C68A5;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative; }
    .radio-container label:last-of-type, .radio-container .label-for:last-of-type {
      margin-bottom: 0;
      margin-right: 0; }
    .radio-container label .terms-conditions-check, .radio-container .label-for .terms-conditions-check {
      position: relative; }
      .radio-container label .terms-conditions-check input[type="checkbox"], .radio-container .label-for .terms-conditions-check input[type="checkbox"] {
        pointer-events: none; }
        .radio-container label .terms-conditions-check input[type="checkbox"]:before, .radio-container .label-for .terms-conditions-check input[type="checkbox"]:before {
          top: -5px; }
        .radio-container label .terms-conditions-check input[type="checkbox"]:after, .radio-container .label-for .terms-conditions-check input[type="checkbox"]:after {
          top: 5px !important; }
    .radio-container label input, .radio-container .label-for input {
      margin-right: 1.875rem;
      width: 0 !important; }
      .radio-container label input:checked ~ .label-content .radio-container, .radio-container .label-for input:checked ~ .label-content .radio-container {
        display: inline-flex !important; }
    .radio-container label .label-content, .radio-container .label-for .label-content {
      font-size: 0.875em;
      margin-top: -0.21875rem; }
      .radio-container label .label-content span, .radio-container .label-for .label-content span {
        color: #2C68A5;
        font-weight: 400;
        font-size: inherit;
        font-family: "open-sans";
        margin-bottom: 0.625rem;
        line-height: 1.4;
        display: block; }
      .radio-container label .label-content .label-for, .radio-container .label-for .label-content .label-for {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem; }
        .radio-container label .label-content .label-for > span, .radio-container .label-for .label-content .label-for > span {
          margin-bottom: 0;
          margin-top: -0.21875rem; }
  .radio-container .radio-container {
    display: none !important;
    flex-direction: column; }

.input-range {
  position: relative;
  display: inline-block;
  width: 100%; }
  .input-range .input-range-container {
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 1;
    height: 0.75rem;
    width: 100%; }
  .input-range .output-range-container {
    position: absolute;
    left: 0;
    bottom: calc(100% + 0.9375rem);
    width: 2.0625rem;
    height: 1.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E25F2C;
    transform: translateX(-50%);
    color: white;
    border-radius: 2.5px; }
    .input-range .output-range-container output {
      color: white;
      font-size: 0.75em;
      font-weight: 600; }
    .input-range .output-range-container:before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.125rem;
      border-color: #E25F2C transparent transparent transparent; }
  .input-range input[type=range] {
    width: 100%;
    -webkit-appearance: none;
    border-radius: 50px;
    height: 0.5rem;
    overflow: visible;
    margin: 0;
    background-color: #EFEFEF;
    width: 100%;
    line-height: 1;
    outline: none; }
    .input-range input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: white;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%; }
    .input-range input[type=range]::-moz-range-progress {
      background-color: #E25F2C; }
    .input-range input[type=range]::-moz-range-track {
      background-color: #E6E6E6; }
    .input-range input[type=range]::-ms-fill-lower {
      background-color: #E25F2C; }
    .input-range input[type=range]::-ms-fill-upper {
      background-color: #E6E6E6; }
    .input-range input[type=range]::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      border-radius: 50px; }

@supports (-ms-ime-align: auto) {
  .toggle-password {
    display: none !important; }
  input::placeholder, textarea::placeholder, select::placeholder {
    line-height: 1 !important; }
  .input-range .input-range-container {
    overflow: hidden; }
  .input-range input[type=range] {
    height: auto;
    width: 100%; }
    .input-range input[type=range]::-ms-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: white;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1); }
    .input-range input[type=range]::-ms-track {
      background: transparent;
      border-color: transparent;
      border-width: 10px 0;
      color: transparent; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .toggle-password {
    display: none !important; }
  input::placeholder, textarea::placeholder, select::placeholder {
    line-height: 1 !important; }
  .input-range .input-range-container {
    overflow: hidden; }
  .input-range input[type=range] {
    height: auto;
    width: 100%;
    margin-top: 12.5px; }
    .input-range input[type=range]::-ms-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: white;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1); }
    .input-range input[type=range]::-ms-track {
      background: transparent;
      border-color: transparent;
      border-width: 10px 0;
      color: transparent; }
    .input-range input[type=range]::-ms-tooltip {
      display: none; } }

.terms-conditions-check {
  text-align: left;
  color: #2C68A5;
  display: flex;
  justify-content: space-between;
  margin: 0.625rem 0 1.5625rem;
  position: relative; }
  .terms-conditions-check .input-error {
    color: #D0021B;
    padding: 0;
    margin-top: 0.9375rem; }
  .terms-conditions-check input[type="checkbox"] {
    margin-right: 2.1875rem !important; }
    .terms-conditions-check input[type="checkbox"]:checked + .checkbox:before {
      background-position: center;
      background-color: transparent;
      background-image: url(images/icon-check.svg);
      background-size: 50%;
      background-repeat: no-repeat;
      transition: none; }
    .terms-conditions-check input[type="checkbox"]:checked + .checkbox:after {
      display: none; }
    .terms-conditions-check input[type="checkbox"] + .checkbox:before {
      border-radius: 0;
      top: 0;
      transform: none;
      width: 1.5rem;
      height: 1.5rem; }
  .terms-conditions-check > span {
    line-height: 1.3;
    width: 100%;
    display: block;
    text-align: left; }
  .terms-conditions-check a {
    color: inherit;
    text-decoration: underline;
    display: inline;
    font-weight: 700; }

.checkbox-offset {
  padding: 0 0.3125rem 0 0.625rem;
  margin-bottom: 0.625rem; }

.input-tooltip {
  position: relative;
  overflow: visible; }
  .input-tooltip input {
    margin-bottom: 0; }
  .input-tooltip .tooltip-action {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    position: absolute;
    right: 0.9375rem;
    top: 0.78125rem;
    border-radius: 50%;
    border: 2px solid #2C68A5;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible; }
    .input-tooltip .tooltip-action:before {
      content: 'i';
      font-weight: 700;
      color: #2C68A5;
      font-size: 0.75em; }
    .input-tooltip .tooltip-action.active + .tooltip-content {
      display: block; }

.tooltip-content {
  display: none;
  position: absolute;
  bottom: calc(100% + 0.625rem);
  left: 0; }
  .tooltip-content.tooltip-content-ccv {
    width: 100% !important;
    flex: auto !important;
    align-items: center;
    text-align: left;
    border: 1px solid #2C68A5;
    background: white;
    border-radius: 0.625rem;
    overflow: hidden;
    padding: 0.625rem;
    justify-content: space-between;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2); }
    .tooltip-content.tooltip-content-ccv:before {
      font-size: 0.8125em;
      content: 'CCV is an anti-fraud secuirty feature. The 3 CCV numbers are located in the back of your card.';
      flex: 0 0 55%;
      margin-right: 5%;
      line-height: 1.2;
      font-weight: 700; }
    .tooltip-content.tooltip-content-ccv:after {
      content: '';
      flex: 1;
      text-align: center;
      line-height: 1.5;
      background-image: url(images/ICON-CCV.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      flex: 0 0 40%;
      align-self: stretch;
      min-height: 4.6875rem; }
  .tooltip-content.active {
    display: flex; }

@media only screen and (min-width: 980px) {
  input[type="submit"].button-orange:hover {
    background: #E25F2C;
    color: white; }
  label.input-blue input[type="submit"]:hover, label.input-blue button:hover {
    background: #2C68A5; }
  label.input-white input[type="submit"]:hover, label.input-white button:hover {
    background: white;
    color: #E25F2C; }
  label.input-orange-solid input:hover, label.input-orange-solid button:hover, label.input-green-solid input:hover, label.input-green-solid button:hover {
    background-position: right center; } }

.select-dropdown {
  position: relative;
  transition: border-radius ease-in-out 0s 0.2s;
  border: 2px solid #2C68A5;
  border-radius: 1.5625rem;
  overflow: hidden;
  cursor: pointer;
  z-index: 99; }
  .select-dropdown.has-error {
    border-color: #D0021B; }
    .select-dropdown.has-error .select-rendered {
      color: #D0021B; }
      .select-dropdown.has-error .select-rendered:after {
        background-color: #D0021B; }
  .select-dropdown + .input-error {
    /*margin-top: rem(10);*/ }
  .select-dropdown input[type="text"] {
    color: #7A7B78;
    margin: 0 !important;
    width: 100%; }
  .select-dropdown input[type="text"]::placeholder {
    color: #7A7B78; }
  .select-dropdown .select-rendered {
    border-bottom: none;
    font-size: 12px !important;
    color: #2C68A5;
    letter-spacing: 0.03125rem;
    padding: 0.8125rem 2.1875rem 0.875rem 1.25rem;
    position: relative;
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 600;
    overflow: hidden;
    justify-content: center;
    height: 41px;
    line-height: 1;
    font-family: "open-sans"; }
    .select-dropdown .select-rendered:after {
      content: '';
      background-color: #2C68A5;
      background-image: url(images/icon-angle-down.svg);
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      width: 0.9375rem;
      height: 0.9375rem;
      position: absolute;
      right: 0.625rem !important;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease-in-out 0.2s;
      font-weight: 800;
      overflow: visible; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .select-dropdown .select-rendered:after {
        background-size: 10px; } }
  .select-dropdown .select-option, .select-dropdown .select-rendered {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center; }
    .select-dropdown .select-option.has-image, .select-dropdown .select-rendered.has-image {
      padding-left: 4rem !important; }
    .select-dropdown .select-option:focus, .select-dropdown .select-rendered:focus {
      background: rgba(0, 114, 0, 0.2) !important;
      outline: none; }
    .select-dropdown .select-option img, .select-dropdown .select-rendered img {
      max-width: 35px !important;
      max-height: 25px;
      height: auto !important;
      width: 100%;
      margin-right: 1.875rem;
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0 !important;
      border: none !important; }
  .select-dropdown .select-inner {
    transition: all ease-in-out 0.2s;
    max-height: 0;
    overflow: hidden; }
  .select-dropdown .select-options {
    width: 100%;
    background: white;
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: 400;
    position: relative;
    z-index: 0;
    max-height: 9rem;
    overflow-y: auto; }
    .select-dropdown .select-options .select-option {
      text-align: center;
      padding: 0.625rem 1.25rem;
      position: relative;
      font-size: 0.75em;
      cursor: pointer;
      color: #2C68A5;
      background-color: #E9EFF5;
      display: flex;
      align-items: center;
      transition: all ease-in-out 0.15s;
      text-decoration: none !important; }
      .select-dropdown .select-options .select-option.selected {
        color: #E25F2C; }
      .select-dropdown .select-options .select-option.hidden {
        display: none; }
      .select-dropdown .select-options .select-option:nth-of-type(even) {
        background-color: white; }
  .select-dropdown.dropdown-active {
    transition: all ease-in-out 0s;
    border-radius: 1.40625rem; }
    .select-dropdown.dropdown-active .select-rendered {
      transition: all ease-in-out 0s; }
      .select-dropdown.dropdown-active .select-rendered:after {
        transform: translateY(-50%) rotate(180deg); }
    .select-dropdown.dropdown-active .select-inner {
      max-height: 12.5rem; }
  .select-dropdown.select-left .select-rendered {
    text-align: left; }
  .select-dropdown.select-white {
    border-color: white; }
    .select-dropdown.select-white * {
      background: transparent !important;
      color: white !important; }
    .select-dropdown.select-white .select-rendered {
      border-color: white; }
      .select-dropdown.select-white .select-rendered:after {
        background-color: white;
        background-image: url(images/icon-angle-down-blue.svg); }
    .select-dropdown.select-white .select-options .select-option {
      border-top: 1px solid white; }
    .select-dropdown.select-white.dropdown-active .select-options {
      border-color: white; }
  .select-dropdown.select-left .select-option, .select-dropdown.select-left .select-rendered {
    justify-content: flex-start; }

@media only screen and (min-width: 980px) {
  .select-dropdown .select-options .select-option:hover {
    background: rgba(0, 114, 0, 0.2);
    /*color: white;*/ }
    .select-dropdown .select-options .select-option:hover img {
      /*background: white;*/ } }

/*	Misc. Parts	*/
.lottery-numbers {
  overflow: visible; }

.lottery-numbers-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .lottery-numbers-container.numbers-container-column .lottery-numbers {
    flex: 0 0 95%;
    margin-bottom: 0.3125rem; }
    .lottery-numbers-container.numbers-container-column .lottery-numbers .lottery-number {
      width: calc((100% - (6 * 0.1875rem)) / 7);
      max-width: 100%; }
    .lottery-numbers-container.numbers-container-column .lottery-numbers:last-of-type {
      margin-bottom: 0; }
  .lottery-numbers-container > p {
    flex: 100%;
    font-size: 1em !important;
    font-weight: 700;
    margin: 0 0 0.625rem;
    line-height: 1; }
  .lottery-numbers-container .lottery-numbers {
    display: flex;
    align-items: center;
    flex: 0 0 100%; }
    .lottery-numbers-container .lottery-numbers.center {
      justify-content: center; }
    .lottery-numbers-container .lottery-numbers .lottery-number {
      border-radius: 50%;
      color: white;
      font-weight: 700;
      font-size: 1.0625em;
      position: relative;
      margin: 0.3125rem 0.1875rem 0 0;
      background: #E25F2C;
      /*width: calc((100% - (6 * 0.1875rem)) / 7);*/
      width: 2.03125rem;
      min-width: 1.875rem;
      max-width: 8.5vw;
      text-align: center;
      user-select: none; }
      .lottery-numbers-container .lottery-numbers .lottery-number > span {
        height: 0;
        display: block;
        padding-top: 100%;
        position: relative; }
        .lottery-numbers-container .lottery-numbers .lottery-number > span > span {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin: 0 auto;
          width: 100%; }
      .lottery-numbers-container .lottery-numbers .lottery-number.number {
        background-color: #E25F2C; }
      .lottery-numbers-container .lottery-numbers .lottery-number.lucky {
        background-color: #38A4D0; }
    .lottery-numbers-container .lottery-numbers.lottery-numbers-row {
      margin-bottom: 0;
      flex: 0 0 100%;
      justify-content: center; }
      .lottery-numbers-container .lottery-numbers.lottery-numbers-row .lottery-number {
        width: calc((100% - (8 * 0.1875rem)) / 9);
        font-size: initial; }
        .lottery-numbers-container .lottery-numbers.lottery-numbers-row .lottery-number > span {
          font-size: initial; }
          .lottery-numbers-container .lottery-numbers.lottery-numbers-row .lottery-number > span > span {
            font-size: 0.875em; }
    .lottery-numbers-container .lottery-numbers.lottery-numbers-results .lottery-number.number {
      border: 2px solid #E25F2C;
      color: #E25F2C;
      background: transparent; }
      .lottery-numbers-container .lottery-numbers.lottery-numbers-results .lottery-number.number.selected {
        background-color: #E25F2C;
        color: white; }
    .lottery-numbers-container .lottery-numbers.lottery-numbers-results .lottery-number.lucky {
      background-color: transparent;
      border: 2px solid #38A4D0;
      color: #38A4D0; }
      .lottery-numbers-container .lottery-numbers.lottery-numbers-results .lottery-number.lucky.selected {
        background-color: #38A4D0;
        color: white;
        border: none; }

@media only screen and (min-width: 768px) {
  .lottery-numbers-container .lottery-numbers {
    flex: 0 1 auto; } }

.video-container {
  width: 100%;
  position: relative;
  background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%); }
  .video-container h1 {
    color: white;
    font-weight: 600;
    margin: 0 0 2.1875rem;
    padding: 1rem 3.75%;
    font-size: 1em;
    position: relative;
    width: 100%; }
    .video-container h1:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: white;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0; }
  .video-container video {
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 73.75rem;
    position: relative;
    object-fit: cover; }
  .video-container .video-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s; }
    .video-container .video-controls span {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
      transition: all ease-in-out 0.3s;
      cursor: pointer;
      margin-right: 0.625rem; }
      .video-container .video-controls span svg * {
        stroke: white;
        fill: white; }
      .video-container .video-controls span:last-child {
        margin-right: 0; }
    .video-container .video-controls.hidden {
      opacity: 0;
      visibility: hidden; }

@media only screen and (min-width: 48.75rem) {
  .video-container h1 {
    text-align: left;
    font-size: 1.875em;
    padding-bottom: 1.75rem; } }

@media only screen and (min-width: 61.25rem) {
  .video-container .video-controls span:hover {
    opacity: 0.8; } }

@media only screen and (min-width: 73.75rem) {
  .video-container {
    padding: 1.25rem 0 0.9375rem; }
    .video-container h1 {
      padding-top: 0;
      margin-top: 0; } }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  user-select: none; }
  header.noborder {
    border-bottom: none; }
  header #header-strip {
    text-align: center;
    background-image: linear-gradient(to top, #F5993C 0%, #F76B1C 100%);
    padding: 0.3125rem 0; }
    header #header-strip p {
      color: white;
      font-size: 1.0625em;
      margin: 0;
      letter-spacing: 0.5px; }
  header .nav-cart-container {
    position: relative;
    margin-left: 0.3125rem; }
    header .nav-cart-container .nav-cart-qty {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #E25F2C;
      color: white !important;
      border-radius: 50%;
      font-size: 8px;
      width: 0.9375rem;
      height: 0.9375rem;
      text-decoration: none !important;
      transform: translate(40%, -40%);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center; }
    header .nav-cart-container svg {
      margin-left: 0 !important; }
  header #header-top {
    background-color: #302C29; }
    header #header-top .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3125rem 0; }
      header #header-top .container #header-logo {
        width: 4.375rem;
        display: flex;
        align-items: center;
        margin: 0.3125rem 0;
        transition: all ease-in-out 0.2s;
        opacity: 1; }
      header #header-top .container #header-top-nav {
        display: flex;
        margin-left: 1.5625rem;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        list-style: none;
        transition: all ease-in-out 0.2s; }
        header #header-top .container #header-top-nav > * {
          color: #E3E3E3;
          display: flex;
          align-items: center;
          margin-right: 0.46875rem;
          font-size: 0.875em;
          line-height: 1;
          cursor: pointer;
          position: relative; }
          header #header-top .container #header-top-nav > * svg {
            width: 0.75rem;
            height: 0.75rem;
            flex: 0 0 0.75rem;
            border: 2px solid white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2.5px;
            box-sizing: content-box;
            margin-left: 0.3125rem;
            transition: all ease-in-out 0.2s; }
          header #header-top .container #header-top-nav > * img {
            width: 1.625rem;
            height: 1.625rem;
            border-radius: 50%;
            margin-right: 0.625rem;
            border: 1px solid white; }
          header #header-top .container #header-top-nav > *:last-child {
            margin-right: 0; }
          header #header-top .container #header-top-nav > *.nav-active {
            color: #E25F2C;
            text-decoration: underline; }
            header #header-top .container #header-top-nav > *.nav-active .nav-cart-container > svg {
              border-color: #E25F2C; }
              header #header-top .container #header-top-nav > *.nav-active .nav-cart-container > svg * {
                stroke: #E25F2C !important; }
              header #header-top .container #header-top-nav > *.nav-active .nav-cart-container > svg path {
                fill: #E25F2C; }
          header #header-top .container #header-top-nav > * .nav-title {
            color: inherit;
            margin: 0; }
        header #header-top .container #header-top-nav .top-nav--desktop-item {
          position: absolute;
          top: 100%;
          right: -0.625rem;
          max-width: none;
          z-index: -1;
          visibility: hidden;
          opacity: 0;
          padding-top: 0.9375rem;
          transition: opacity ease-in-out 0.2s;
          overflow: hidden;
          height: 0;
          max-width: 97.5vw; }
          header #header-top .container #header-top-nav .top-nav--desktop-item .tab-content.active {
            overflow: hidden; }
          header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card {
            box-shadow: none;
            border: 1px solid #2C68A5;
            width: 25rem;
            padding: 1.25rem 0 !important;
            max-height: calc(100vh - 100px);
            overflow: auto; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .lottery-card-account--header {
              padding-bottom: 0.9375rem; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .button {
              font-size: 0.8125em; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card input[type="submit"] {
              padding: 0.625rem 1.875rem; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-funds {
              padding: 0 1.25rem 0.9375rem; }
              header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-funds .choose-plays {
                padding-top: 0.9375rem; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer {
              padding: 0.625rem 1.25rem 0;
              margin-top: 0.625rem;
              display: flex;
              flex-direction: column; }
              header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer h3 span {
                border: none;
                margin: 0 0 0 1.5625rem;
                font-size: 1em;
                color: #E25F2C; }
              header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer h3:hover {
                color: #4C4D4A !important; }
              header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer .button-orange-solid, header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer .button-blue-solid {
                align-self: center;
                padding: 0.625rem;
                min-width: 10.9375rem; }
                header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer .button-orange-solid:hover, header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .account-nav-funds--footer .button-blue-solid:hover {
                  background-position: right center; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .logout {
              margin: 0.625rem 0 0;
              font-size: 0.875em; }
              header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .logout a {
                color: #E25F2C;
                font-weight: 600;
                margin-left: 0.3125rem; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .lottery-card .orange {
              font-size: 1.25em;
              font-weight: 600;
              margin: 1.25rem auto 0.625rem;
              display: inline-block; }
          header #header-top .container #header-top-nav .top-nav--desktop-item:before, header #header-top .container #header-top-nav .top-nav--desktop-item:after {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            bottom: calc(100% - 1px - 0.9375rem);
            right: 1.5625rem;
            transform: translateX(50%);
            border-width: 10px; }
          header #header-top .container #header-top-nav .top-nav--desktop-item:before {
            border-width: 11px;
            border-color: transparent transparent #2C68A5 transparent; }
          header #header-top .container #header-top-nav .top-nav--desktop-item:after {
            border-color: transparent transparent white transparent; }
          header #header-top .container #header-top-nav .top-nav--desktop-item .cart-empty svg {
            width: 4.6875rem;
            height: auto;
            flex: 0 0 4.6875rem;
            margin: 0 auto;
            max-height: 4.6875rem; }
          header #header-top .container #header-top-nav .top-nav--desktop-item .nav-cart {
            padding: 0 !important;
            font-size: 18px; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .nav-cart .cart-footer p {
              color: #4C4D4A;
              font-size: 0.9375em;
              margin: 0; }
            header #header-top .container #header-top-nav .top-nav--desktop-item .nav-cart .cart-footer input[type="submit"] {
              font-size: 1em; }
  header #user-edit {
    background-color: #302C29; }
    header #user-edit .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1.25rem 0 0.625rem; }
      header #user-edit .container a {
        flex: 0 0 49%;
        font-size: 0.875em;
        margin-bottom: 0.625rem;
        padding: 0.625rem;
        font-weight: 700; }
  header #header-bottom {
    padding: 0.9375rem 0;
    transition: all ease-in-out 0.2s 0.1s; }
    header #header-bottom .container {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      header #header-bottom .container > * {
        display: flex;
        align-items: center;
        font-size: 1em;
        color: #2C68A5;
        text-decoration: underline;
        margin-right: 0.9375rem; }
        header #header-bottom .container > *:last-child {
          margin-right: 0;
          margin-left: auto; }
        header #header-bottom .container > * svg {
          height: 1.5625rem;
          max-width: 1.5625rem;
          margin-right: 0.3125rem; }
        header #header-bottom .container > *.nav-grey {
          color: #4C4D4A;
          cursor: pointer;
          user-select: none; }
          header #header-bottom .container > *.nav-grey:after {
            content: '';
            transform: rotate(90deg);
            margin-left: 0.3125rem;
            margin-right: 0;
            transition: all ease-in-out 0.2s;
            position: relative;
            top: 2px;
            width: 0.8125rem;
            height: 0.8125rem;
            background-color: #7A7B78;
            background-image: url(images/icon-angle-right.svg);
            background-size: 45%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 100%; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            header #header-bottom .container > *.nav-grey:after {
              top: 1px;
              background-size: 10px;
              width: 0.9375rem;
              height: 0.9375rem; } }
        header #header-bottom .container > *.active {
          color: #E25F2C; }
          header #header-bottom .container > *.active:after {
            transform: rotate(-90deg); }
            header #header-bottom .container > *.active:after * {
              fill: #E25F2C; }
    header #header-bottom .nav-desktop {
      display: none; }
  header #header-nav {
    max-width: 31.25rem;
    right: -105%;
    transition: right ease-in-out 0.3s !important;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    display: block !important; }
    header #header-nav.active {
      right: 0; }
    header #header-nav .header-cta {
      text-align: left;
      padding: 1.5625rem 5% 1.875rem;
      background-image: linear-gradient(to top left, #0A7FDD 0%, #35A7F5 100%); }
      header #header-nav .header-cta a {
        text-decoration: underline;
        color: white;
        font-size: 1.125em;
        font-weight: 700;
        letter-spacing: 0.5px; }
      header #header-nav .header-cta p {
        color: white;
        font-weight: 400;
        font-size: 0.875em;
        font-family: "open-sans";
        max-width: 80%;
        line-height: 1.5; }
        header #header-nav .header-cta p:last-of-type {
          margin-bottom: 0; }
    header #header-nav ul {
      padding: 0;
      margin: 0;
      text-align: left;
      padding-bottom: 6.25rem; }
      header #header-nav ul li {
        list-style: none;
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #2C68A5;
        line-height: 1; }
        header #header-nav ul li a {
          display: inline-block;
          font-size: 1.125em;
          color: #2C68A5;
          font-weight: 600;
          padding: 0.9375rem 1.875rem 0.9375rem 5%;
          display: flex;
          justify-content: space-between; }
          header #header-nav ul li a:after {
            content: '';
            background-image: url(images/icon-angle-right.svg);
            background-size: 45%;
            background-position: center;
            background-repeat: no-repeat;
            width: 0.9375rem;
            height: 0.9375rem;
            background-color: #2C68A5;
            border-radius: 50%; }
          header #header-nav ul li a span {
            text-decoration: underline; }
  header #absolute-header {
    position: fixed;
    width: 100%;
    height: auto;
    left: 0;
    height: 0;
    overflow-y: scroll;
    background: white; }
    header #absolute-header .absolute-header-container {
      position: relative; }
  header .nav-tab.active {
    height: 100vh; }
  header #header-top-nav > li, header .lottery-card-account--header > li {
    transition: all ease-in-out 0.2s !important; }
    header #header-top-nav > li > svg path, header #header-top-nav > li > svg polygon, header #header-top-nav > li .nav-cart-container > svg path, header #header-top-nav > li .nav-cart-container > svg polygon, header .lottery-card-account--header > li > svg path, header .lottery-card-account--header > li > svg polygon, header .lottery-card-account--header > li .nav-cart-container > svg path, header .lottery-card-account--header > li .nav-cart-container > svg polygon {
      transition: all ease-in-out 0.2s; }

.sign-in {
  padding-bottom: 2.1875rem; }
  .sign-in .sign-in--header {
    background: linear-gradient(to top left, #0B80DE 0%, #44B5FD 100%);
    padding: 3.125rem 0 1.875rem; }
    .sign-in .sign-in--header * {
      color: white; }
    .sign-in .sign-in--header h2 {
      margin: 0 auto;
      font-size: 1.375em;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
      text-align: center;
      font-weight: 700 !important; }
      .sign-in .sign-in--header h2 .signup-icon {
        margin-right: 0.9375rem;
        border: 2px solid white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6.5px; }
        .sign-in .sign-in--header h2 .signup-icon svg {
          width: 0.9375rem;
          height: 0.9375rem;
          box-sizing: content-box; }
      .sign-in .sign-in--header h2 i {
        font-size: 2.125em;
        margin-right: 0.9375rem; }
    .sign-in .sign-in--header p {
      margin-top: 0;
      font-size: 0.875em;
      font-weight: 400;
      font-family: "open-sans"; }
      .sign-in .sign-in--header p:first-of-type {
        margin-top: 1.25rem; }
      .sign-in .sign-in--header p:last-child {
        margin-bottom: 0; }
  .sign-in .button-icon-inline {
    margin-bottom: 1.25rem;
    position: relative;
    font-weight: 700;
    padding-left: 0.625rem;
    padding-right: 0; }
    .sign-in .button-icon-inline.button-icon-inline {
      width: 12.5rem; }
    .sign-in .button-icon-inline svg * {
      stroke: #E25F2C;
      fill: #E25F2C; }
    .sign-in .button-icon-inline svg polygon {
      fill: transparent !important; }
    .sign-in .button-icon-inline.button-orange-solid svg, .sign-in .button-icon-inline.button-blue-solid svg {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); }
    .sign-in .button-icon-inline:last-child {
      margin-bottom: 0; }
  .sign-in .radio-container p {
    padding: 0;
    margin: 0 0 1.25rem; }
  .sign-in p {
    color: #2C68A5;
    margin: 0 0 0.9375rem;
    font-size: 0.875em;
    font-family: "open-sans";
    padding: 0 0.625rem; }
  .sign-in .button-orange-solid, .sign-in .button-blue-solid {
    margin-bottom: 0.625rem;
    font-size: 0.9375em;
    max-width: 20.3125rem; }
    .sign-in .button-orange-solid svg, .sign-in .button-blue-solid svg {
      position: absolute;
      left: 1.25rem;
      overflow: visible; }
    .sign-in .button-orange-solid.has-border svg, .sign-in .button-blue-solid.has-border svg {
      border: 2px solid white;
      border-radius: 50%;
      width: 1.75rem;
      height: 1.75rem;
      padding: 0.3rem; }
  .sign-in .sign-in--main {
    padding: 2.1875rem 0.625rem 0; }
    .sign-in .sign-in--main .radio-container label input {
      margin-right: 1.875rem; }
  .sign-in .sign-in--return {
    text-align: left;
    margin: 0; }
    .sign-in .sign-in--return > * {
      display: flex;
      align-items: center;
      color: #2C68A5;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600; }
      .sign-in .sign-in--return > * svg {
        margin-right: 0.625rem; }
      .sign-in .sign-in--return > *:hover {
        opacity: 0.8; }
  .sign-in .sign-in-action {
    margin-top: 0.625rem;
    margin-bottom: 2.1875rem; }
    .sign-in .sign-in-action p {
      color: #2C68A5;
      display: flex;
      justify-content: center;
      margin: 0;
      line-height: 1;
      font-size: 0.875em;
      font-family: "open-sans";
      padding: 0 !important;
      margin-bottom: 0.625rem; }
      .sign-in .sign-in-action p span {
        color: #E25F2C;
        margin-left: 0.625rem;
        font-weight: 700;
        text-decoration: underline; }
      .sign-in .sign-in-action p a {
        margin-left: 0.625rem; }
  .sign-in form h3 {
    color: #E25F2C;
    font-weight: 600;
    font-size: 1em;
    margin: 0.9375rem 0 1.25rem;
    display: inline-block; }
  .sign-in#sign-up input[type="submit"] {
    margin-bottom: 1.5625rem; }
    .sign-in#sign-up input[type="submit"] + .sign-in-action {
      margin-bottom: 0; }

#forgot-password p {
  color: #7A7B78;
  font-size: 0.875em;
  text-align: left; }
  #forgot-password p span, #forgot-password p a {
    font-weight: 700; }

#forgot-password .sign-in-action p {
  text-align: center;
  justify-content: center;
  margin-bottom: 0.625rem; }
  #forgot-password .sign-in-action p span, #forgot-password .sign-in-action p a {
    margin-left: 0.3125rem;
    color: inherit; }

.nav-tab {
  display: none;
  background: white;
  position: fixed;
  overflow: auto !important;
  width: 100%;
  margin-top: 2px;
  transition: none !important;
  -webkit-overflow-scrolling: touch; }
  .nav-tab .container {
    max-width: 22.5rem; }
  .nav-tab.active {
    display: block;
    z-index: 999;
    background: white;
    padding: 1.5625rem 0 6.25rem !important; }
  .nav-tab#header-nav {
    padding: 0 !important; }

body.nav-small header #header-top .container #header-logo {
  width: 3.75rem;
  margin: 0; }

body.nav-small header #header-top .container #header-top-nav {
  margin: 0.625rem 0; }

body.nav-small header #header-bottom {
  padding: 0.3125rem 0; }

@media only screen and (min-width: 760px) {
  header .nav-cart-container {
    margin-left: 0.625rem; }
    header .nav-cart-container .nav-cart-qty {
      font-size: 12px;
      width: 1.25rem;
      height: 1.25rem; }
  header #header-top .container #header-logo {
    width: 6.25rem; }
  header #header-top .container #header-top-nav > li {
    font-size: 1.0625em;
    margin-right: 1.5625rem; }
    header #header-top .container #header-top-nav > li svg {
      width: 0.9375rem;
      flex: 0 0 0.9375rem;
      height: 0.9375rem;
      padding: 0.3125rem;
      margin-left: 0.625rem;
      display: block; }
  header #header-top .container #header-top-nav span.underline {
    margin-right: 0.3125rem; }
  header #header-bottom {
    padding: 1.25rem 0; }
    header #header-bottom .container > * {
      margin-right: 1.875rem; }
    header #header-bottom .container .nav-desktop {
      display: flex; }
  body.nav-small header #header-top .container #header-logo {
    width: 5rem; }
  header .nav-tab {
    -webkit-overflow-scrolling: touch;
    background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%) !important; }
    header .nav-tab#header-nav {
      background: white;
      background-image: none !important; }
  header .sign-in {
    background: transparent;
    max-width: 36.25rem;
    margin: 3.125rem auto; }
    header .sign-in .sign-in--card {
      padding: 1.5625rem 0;
      border-radius: 0.625rem;
      overflow: hidden;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
      background: white;
      margin-bottom: 1.875rem; }
    header .sign-in .sign-in--header {
      background: white;
      text-align: left;
      padding-bottom: 0;
      text-align: left; }
      header .sign-in .sign-in--header * {
        color: #2C68A5;
        text-align: left; }
      header .sign-in .sign-in--header p {
        color: #7A7B78;
        padding: 0; }
      header .sign-in .sign-in--header h2 {
        margin: 0;
        text-align: left;
        justify-content: flex-start; }
        header .sign-in .sign-in--header h2 .icon {
          border-color: #2C68A5; }
          header .sign-in .sign-in--header h2 .icon svg * {
            fill: #2C68A5; }
        header .sign-in .sign-in--header h2 .signup-icon {
          border-color: #2C68A5; }
          header .sign-in .sign-in--header h2 .signup-icon svg * {
            fill: transparent;
            stroke: #2C68A5; }
    header .sign-in .sign-in--footer .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 100%; }
      header .sign-in .sign-in--footer .container p {
        color: white;
        flex: 0 0 100%; }
      header .sign-in .sign-in--footer .container .button {
        flex: 1;
        margin: 0 5% 0 0;
        max-width: 20rem; }
        header .sign-in .sign-in--footer .container .button svg {
          position: static;
          top: 0;
          transform: none;
          margin-right: 1.25rem; }
        header .sign-in .sign-in--footer .container .button:last-of-type {
          margin-right: 0; }
  header #user-edit .container a {
    flex: 0 0 24%; } }

@media only screen and (min-width: 980px) {
  header #header-top .container #header-top-nav > * .nav-title {
    transition: all ease-in-out 0.2s; }
  header #header-top .container #header-top-nav > *:hover .nav-title {
    color: #E25F2C;
    text-decoration: underline; }
  header #header-top .container #header-logo {
    width: 7.5rem; }
  header.cart-active #header-cart .top-nav--desktop-item {
    opacity: 1 !important;
    visibility: visible !important;
    z-index: 999 !important;
    height: auto !important; }
  header #header-top-nav > li, header .lottery-card-account--header > li {
    transition: all ease-in-out 0.2s !important; }
    header #header-top-nav > li > svg path, header #header-top-nav > li > svg polygon, header #header-top-nav > li .nav-cart-container > svg path, header #header-top-nav > li .nav-cart-container > svg polygon, header .lottery-card-account--header > li > svg path, header .lottery-card-account--header > li > svg polygon, header .lottery-card-account--header > li .nav-cart-container > svg path, header .lottery-card-account--header > li .nav-cart-container > svg polygon {
      transition: all ease-in-out 0.2s; }
    header #header-top-nav > li.has-hover:hover > a, header #header-top-nav > li.nav-active > a, header .lottery-card-account--header > li.has-hover:hover > a, header .lottery-card-account--header > li.nav-active > a {
      opacity: 1; }
    header #header-top-nav > li.has-hover:hover > svg, header #header-top-nav > li.has-hover:hover .nav-cart-container > svg, header #header-top-nav > li.nav-active > svg, header #header-top-nav > li.nav-active .nav-cart-container > svg, header .lottery-card-account--header > li.has-hover:hover > svg, header .lottery-card-account--header > li.has-hover:hover .nav-cart-container > svg, header .lottery-card-account--header > li.nav-active > svg, header .lottery-card-account--header > li.nav-active .nav-cart-container > svg {
      border-color: #E25F2C !important; }
      header #header-top-nav > li.has-hover:hover > svg path, header #header-top-nav > li.has-hover:hover .nav-cart-container > svg path, header #header-top-nav > li.nav-active > svg path, header #header-top-nav > li.nav-active .nav-cart-container > svg path, header .lottery-card-account--header > li.has-hover:hover > svg path, header .lottery-card-account--header > li.has-hover:hover .nav-cart-container > svg path, header .lottery-card-account--header > li.nav-active > svg path, header .lottery-card-account--header > li.nav-active .nav-cart-container > svg path {
        fill: #E25F2C;
        stroke: #E25F2C; }
      header #header-top-nav > li.has-hover:hover > svg polygon, header #header-top-nav > li.has-hover:hover .nav-cart-container > svg polygon, header #header-top-nav > li.nav-active > svg polygon, header #header-top-nav > li.nav-active .nav-cart-container > svg polygon, header .lottery-card-account--header > li.has-hover:hover > svg polygon, header .lottery-card-account--header > li.has-hover:hover .nav-cart-container > svg polygon, header .lottery-card-account--header > li.nav-active > svg polygon, header .lottery-card-account--header > li.nav-active .nav-cart-container > svg polygon {
        stroke: #E25F2C; }
    header #header-top-nav > li.has-hover:hover .top-nav--desktop-item, header #header-top-nav > li.nav-active .top-nav--desktop-item, header .lottery-card-account--header > li.has-hover:hover .top-nav--desktop-item, header .lottery-card-account--header > li.nav-active .top-nav--desktop-item {
      opacity: 1 !important;
      visibility: visible !important;
      z-index: 9999 !important;
      height: auto !important; }
      header #header-top-nav > li.has-hover:hover .top-nav--desktop-item li a:hover, header #header-top-nav > li.nav-active .top-nav--desktop-item li a:hover, header .lottery-card-account--header > li.has-hover:hover .top-nav--desktop-item li a:hover, header .lottery-card-account--header > li.nav-active .top-nav--desktop-item li a:hover {
        color: #E25F2C; }
    header #header-top-nav > li.nav-active, header .lottery-card-account--header > li.nav-active {
      opacity: 1; }
  header #header-bottom .nav-grey {
    transition: all ease-in-out 0.2s; }
    header #header-bottom .nav-grey svg * {
      transition: all ease-in-out 0.2s; }
    header #header-bottom .nav-grey:hover {
      color: #E25F2C; }
      header #header-bottom .nav-grey:hover svg * {
        fill: #E25F2C; }
  header .sign-in-action span {
    transition: all ease-in-out 0.2s;
    cursor: pointer; }
    header .sign-in-action span:hover {
      opacity: 0.7; }
  header #user-edit .container {
    width: 65%; }
    header #user-edit .container a {
      flex: 0 0 22%; }
  body.nav-small header #header-top .container #header-logo {
    width: 6.25rem; } }

footer {
  position: relative; }
  footer .footer-img-container {
    display: flex;
    align-items: center;
    justify-content: center; }
    footer .footer-img-container img {
      margin-right: 1.25rem;
      max-height: 5rem;
      flex: 0 0 auto;
      width: auto; }
      footer .footer-img-container img:last-of-type {
        margin-right: 0; }
  footer .footer-top {
    padding: 3.75rem 0 2.5rem;
    background-color: #7A7B78; }
    footer .footer-top .container {
      max-width: 37.5rem; }
      footer .footer-top .container img {
        width: 9.375rem;
        margin: 0 0 0.625rem; }
      footer .footer-top .container .secondary-logo {
        width: auto;
        margin-left: 1.25rem; }
      footer .footer-top .container p {
        color: white;
        font-size: 0.875em;
        font-family: "open-sans";
        line-height: 1.8; }
  footer .footer-bottom {
    background-color: #302C29;
    padding: 1.5625rem 0; }
    footer .footer-bottom .footer-nav ul {
      padding: 0;
      margin: 0 auto;
      list-style: none;
      max-width: 17.5rem; }
      footer .footer-bottom .footer-nav ul li {
        text-align: center;
        margin-bottom: 0.3125rem;
        display: inline-block;
        margin-right: 0.9375rem; }
        footer .footer-bottom .footer-nav ul li a {
          color: white;
          text-decoration: underline;
          font-family: "open-sans";
          font-weight: 400;
          font-size: 0.75em; }
        footer .footer-bottom .footer-nav ul li:nth-of-type(3n+3), footer .footer-bottom .footer-nav ul li:last-of-type {
          margin-right: 0; }
    footer .footer-bottom .footer-nav + .copy {
      margin: 0.9375rem 0 0; }
    footer .footer-bottom .copy {
      font-size: 0.625em;
      color: #7A7B78;
      font-family: "open-sans";
      margin: 0; }
  footer:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(images/tlo-logo-transparent.png);
    background-size: 115% auto;
    background-repeat: no-repeat;
    background-position: right -100px;
    pointer-events: none;
    touch-action: none;
    background-size: contain; }

@media only screen and (min-width: 780px) {
  footer:before {
    background-position: center right; }
  footer .footer-bottom .footer-nav ul {
    max-width: 100%; }
    footer .footer-bottom .footer-nav ul li:nth-of-type(3n + 3) {
      margin-right: 0.9375rem; } }

/* Cart */
#cart {
  padding: 1.5625rem 0;
  background-image: linear-gradient(to bottom, #2da0f1 0%, #289bee 44%, #178be4 68%, #0076d8 100%); }

.nav-cart {
  padding: 0 !important; }

.cart-items {
  padding: 1.25rem; }
  .cart-items .cart-item {
    display: flex;
    align-items: center;
    align-self: center;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #E3E3E3; }
    .cart-items .cart-item:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0; }
    .cart-items .cart-item .remove-cart-item {
      cursor: pointer; }
      .cart-items .cart-item .remove-cart-item svg {
        width: 1.25rem !important;
        height: 1.25rem !important;
        max-width: 100% !important;
        margin-left: 0.625rem;
        max-height: 1.25rem; }
        .cart-items .cart-item .remove-cart-item svg path {
          transition: all ease-in-out 0.2s; }
        .cart-items .cart-item .remove-cart-item svg:hover * {
          stroke: #D8262D; }
    .cart-items .cart-item .lottery-flag {
      margin: 0;
      width: 2.5rem;
      height: auto;
      margin-right: 0.9375rem; }
    .cart-items .cart-item .cart-item--main {
      text-align: left;
      margin-right: auto; }
      .cart-items .cart-item .cart-item--main h4 {
        margin: 0 0 0.3125rem;
        font-weight: 600;
        font-size: 0.875em; }
      .cart-items .cart-item .cart-item--main p {
        font-size: 0.625em;
        font-weight: 600;
        opacity: 0.6;
        color: #7A7B78;
        margin: 0; }
    .cart-items .cart-item p {
      margin: 0;
      color: #4C4D4A;
      font-weight: 700;
      opacity: 0.8; }
    .cart-items .cart-item i {
      margin-left: 0.625rem;
      font-size: 1.375em;
      transition: all ease-in-out 0.2s; }

.card-container {
  display: block; }

.cart-footer {
  border-top: 1px solid #2C68A5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem; }
  .cart-footer p {
    margin-bottom: 0 !important;
    color: #4C4D4A !important;
    font-weight: 700;
    font-size: 1em;
    opacity: 0.8; }
  .cart-footer input {
    margin-bottom: 0; }
  .cart-footer a.button {
    margin-top: 0; }

#checkout .flex {
  display: flex; }

#checkout p,
#checkout h3,
#checkout h4 {
  margin: 0;
  padding: 0;
  color: #2c68a5; }

#checkout .org,
#checkout .orange-text {
  color: #e25f2c !important; }

#checkout .taleft {
  text-align: left; }

#checkout .taright {
  text-align: right; }

#checkout .half {
  justify-content: space-between;
  flex-wrap: wrap; }
  #checkout .half .item {
    width: 48%; }

#checkout .trigger {
  padding-right: 2rem;
  display: inline;
  position: relative;
  color: #2c68a5; }
  #checkout .trigger:before, #checkout .trigger:after {
    transition: 0.5s ease all; }
  #checkout .trigger:before {
    content: 'Show numbers'; }
  #checkout .trigger:after {
    content: '';
    display: block;
    position: absolute;
    width: 0.9rem;
    height: 0.9rem;
    right: 0;
    top: 0.4rem;
    background: url(images/arrow.svg);
    background-repeat: no-repeat; }

#checkout .active .trigger:before {
  content: 'Hide numbers'; }

#checkout .active .trigger:after {
  transform: rotate(-180deg); }

#checkout .cart-header {
  padding: 3rem 0;
  position: relative; }
  #checkout .cart-header > h1 {
    color: white;
    font-size: 3rem;
    text-align: left;
    font-weight: 600;
    width: 92.5%;
    max-width: 73.75rem;
    margin: 0 auto;
    padding: 0 0 3.125rem; }
    #checkout .cart-header > h1:after {
      content: '';
      width: 100vw;
      height: 1px;
      background: white;
      display: block;
      position: absolute;
      left: 0;
      margin-top: 0.9375rem; }
  #checkout .cart-header .cart-empty {
    padding: 2rem; }
    #checkout .cart-header .cart-empty svg {
      width: 6rem;
      height: 6rem; }
    #checkout .cart-header .cart-empty h2 {
      font-size: 1.375em;
      line-height: 1.3; }
    #checkout .cart-header .cart-empty p {
      font-size: 1em; }

#checkout .lottery-card {
  padding: 2rem;
  margin-bottom: 1rem; }
  #checkout .lottery-card.checkout-card .top {
    position: relative;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid #f1f1f1; }
    #checkout .lottery-card.checkout-card .top .logo {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem; }
      #checkout .lottery-card.checkout-card .top .logo img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    #checkout .lottery-card.checkout-card .top p {
      font-size: 0.9rem;
      margin-bottom: 0.5rem; }
    #checkout .lottery-card.checkout-card .top .remove {
      position: absolute;
      top: 0;
      right: 0; }
      #checkout .lottery-card.checkout-card .top .remove svg g {
        transition: 0.5s ease all; }
      #checkout .lottery-card.checkout-card .top .remove:hover {
        cursor: pointer; }
        #checkout .lottery-card.checkout-card .top .remove:hover svg g {
          stroke: red;
          opacity: 1; }
  #checkout .lottery-card.checkout-card .details {
    padding: 2rem 0 0 !important;
    margin: 0 !important; }
    #checkout .lottery-card.checkout-card .details h1 {
      font-size: 3.5rem;
      font-weight: 500;
      color: #e25f2c;
      margin: 0 0 1rem; }
    #checkout .lottery-card.checkout-card .details #dates .trigger:before,
    #checkout .lottery-card.checkout-card .details #dates2 .trigger:before {
      content: 'Show draw dates'; }
    #checkout .lottery-card.checkout-card .details #dates.active .trigger:before,
    #checkout .lottery-card.checkout-card .details #dates2.active .trigger:before {
      content: 'Hide draw dates'; }
    #checkout .lottery-card.checkout-card .details .draw-dates {
      flex-wrap: wrap;
      border: 0;
      margin: 1rem 0;
      max-height: 0;
      overflow: hidden; }
      #checkout .lottery-card.checkout-card .details .draw-dates span {
        width: 20%;
        display: inline-block;
        font-size: 0.8rem;
        opacity: 0.7;
        color: #2c68a5;
        margin: 0.2rem; }
    #checkout .lottery-card.checkout-card .details .active .draw-dates {
      max-height: 1800px;
      transition: all ease 0.5s; }
    #checkout .lottery-card.checkout-card .details .number-list .single {
      margin: 1rem 0; }
      #checkout .lottery-card.checkout-card .details .number-list .single h4 {
        margin-left: 0.5rem;
        font-weight: 400; }
      #checkout .lottery-card.checkout-card .details .number-list .single .numbers {
        position: relative;
        margin-top: 0.5rem;
        width: 100%;
        padding: 0.5rem;
        background: #f3f9fe; }
        #checkout .lottery-card.checkout-card .details .number-list .single .numbers span {
          color: #2c68a5;
          padding-right: 0.75rem;
          font-size: 1.2rem;
          font-weight: 100; }
        #checkout .lottery-card.checkout-card .details .number-list .single .numbers .edit {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem; }
          #checkout .lottery-card.checkout-card .details .number-list .single .numbers .edit svg:hover {
            cursor: pointer; }
    #checkout .lottery-card.checkout-card .details #multiplier.number-list .single .flex svg g {
      opacity: 1; }
    #checkout .lottery-card.checkout-card .details #multiplier.number-list .single .flex svg path {
      fill: #41b304 !important; }
    #checkout .lottery-card.checkout-card .details #multiplier.number-list .single .flex svg circle {
      stroke: #41b304 !important; }
    #checkout .lottery-card.checkout-card .details #multiplier .trigger:before {
      content: 'Show all games' !important; }
    #checkout .lottery-card.checkout-card .details #multiplier.active .trigger:before {
      content: 'Hide all games' !important; }
    #checkout .lottery-card.checkout-card .details #multiplier.active .all-games {
      max-height: 1800px;
      transition: all ease 0.5s; }
    #checkout .lottery-card.checkout-card .details #multiplier .multiplier {
      align-items: center;
      justify-content: flex-end;
      font-size: 1.2rem;
      font-weight: 200;
      color: #41b304;
      margin: 0; }
      #checkout .lottery-card.checkout-card .details #multiplier .multiplier svg {
        margin-left: 0.5rem; }
    #checkout .lottery-card.checkout-card .details #multiplier .all-games {
      max-height: 0;
      overflow: hidden; }
    #checkout .lottery-card.checkout-card .details .card-total {
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid #f1f1f1; }
      #checkout .lottery-card.checkout-card .details .card-total .button {
        padding: 0.55rem 0.8rem 0.65rem 0.6rem !important;
        font-weight: 200;
        margin-bottom: 1rem;
        font-size: 0.9rem; }
      #checkout .lottery-card.checkout-card .details .card-total h3 {
        font-size: 1.6rem;
        font-weight: 100; }
        #checkout .lottery-card.checkout-card .details .card-total h3 span {
          font-weight: 400; }
    #checkout .lottery-card.checkout-card .details .total,
    #checkout .lottery-card.checkout-card .details .mob-total {
      width: 100%; }
      #checkout .lottery-card.checkout-card .details .total h3,
      #checkout .lottery-card.checkout-card .details .mob-total h3 {
        font-size: 1.6rem;
        font-weight: 100;
        margin-left: 1rem; }
        #checkout .lottery-card.checkout-card .details .total h3 span,
        #checkout .lottery-card.checkout-card .details .mob-total h3 span {
          font-size: 1.2rem; }
    #checkout .lottery-card.checkout-card .details .total {
      width: 100%;
      justify-content: flex-end; }
    #checkout .lottery-card.checkout-card .details .mob-total {
      display: none;
      padding-top: 1rem;
      margin-top: 1.5rem;
      border-top: 1px solid #f1f1f1; }
      #checkout .lottery-card.checkout-card .details .mob-total h3 {
        margin-bottom: 0; }

#checkout .game-container {
  max-width: 22.5rem;
  margin: 0 auto; }

#checkout .lottery-card-account {
  max-width: 22.5rem !important; }

#checkout .lottery-deck--quickplay {
  display: flex; }
  #checkout .lottery-deck--quickplay .lottery-card {
    margin-bottom: 0.9375rem;
    position: relative;
    padding: 1.5625rem 1.25rem 0.9375rem 1.25rem;
    min-width: 0; }
    #checkout .lottery-deck--quickplay .lottery-card .orange {
      font-weight: 600;
      font-size: 1.125em;
      margin: 0; }
    #checkout .lottery-deck--quickplay .lottery-card p {
      font-size: 0.875em;
      margin: 0.3125rem 0 0.625rem;
      text-align: left; }
    #checkout .lottery-deck--quickplay .lottery-card img {
      width: 2.1875rem;
      height: auto;
      position: absolute;
      top: 1.5625rem;
      right: 1.5625rem; }
    #checkout .lottery-deck--quickplay .lottery-card .add-quickplay {
      color: #E25F2C;
      font-size: 0.75em;
      display: block;
      text-decoration: underline; }

#checkout .checkout-footer {
  padding: 4rem 0; }
  #checkout .checkout-footer .cart-total,
  #checkout .checkout-footer .cart-total .flex {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
  #checkout .checkout-footer .cart-total h2,
  #checkout .checkout-footer .cart-total span.total {
    font-size: 2rem;
    display: inline-block; }
  #checkout .checkout-footer .cart-total h2 {
    color: #e25f2c; }
  #checkout .checkout-footer .cart-total span.total {
    padding: 1rem;
    border: 1px solid #2c68a5;
    border-radius: 0.5rem;
    color: #2c68a5;
    margin: 0 1rem; }
  #checkout .checkout-footer .cart-total .button.button-green-solid {
    font-size: 1.4rem !important;
    font-weight: 100 !important;
    padding: 0.3rem 2rem 0.3rem 1rem !important; }

.lottery-card.cart-empty {
  padding: 1.5625rem;
  margin-bottom: 0.3125rem; }
  .lottery-card.cart-empty h2 {
    color: #E25F2C;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0;
    margin: 1.25rem 0 0.625rem; }
    .lottery-card.cart-empty h2 span {
      font-weight: 600;
      font-size: 100%;
      vertical-align: baseline; }
  .lottery-card.cart-empty p {
    color: #7a7b78;
    font-size: 0.6875em;
    font-weight: 400; }
  .lottery-card.cart-empty .button {
    margin-top: 0.9375rem; }

@media screen and (min-width: 768px) {
  #checkout .bg-blue-gradient .lottery-card {
    max-width: 50rem !important; } }

@media screen and (max-width: 768px) {
  #checkout .bg-blue-gradient .lottery-card {
    max-width: 95% !important; } }

@media screen and (max-width: 600px) {
  #checkout .half .item {
    text-align: center;
    width: 100%; }
  #checkout .checkout-card .details .draw-dates {
    justify-content: center;
    text-align: center; }
  #checkout .checkout-card .number-list h3,
  #checkout .checkout-card .number-list .single {
    text-align: left; }
  #checkout .checkout-card .card-total {
    text-align: center; }
    #checkout .checkout-card .card-total .button {
      font-size: 0.9rem;
      margin-bottom: 0 !important; }
    #checkout .checkout-card .card-total h3,
    #checkout .checkout-card .card-total h4 {
      margin: 1rem auto 0;
      text-align: center; }
    #checkout .checkout-card .card-total .multiplier {
      justify-content: center !important; }
  #checkout .checkout-card.syndicates .details .total {
    display: none; }
  #checkout .checkout-card.syndicates .details .mob-total {
    display: block; }
  #checkout .checkout-card.syndicates .details h3.org {
    margin-bottom: 1rem; } }

@media screen and (max-width: 500px) {
  #checkout .lottery-card.checkout-card .top .taleft {
    display: block;
    text-align: center; }
    #checkout .lottery-card.checkout-card .top .taleft .logo {
      margin: auto; }
  #checkout .lottery-card.checkout-card .details h1 {
    font-size: 2.5rem; }
  #checkout .lottery-card.checkout-card .details .draw-dates span {
    width: 40%;
    font-size: 1rem; }
  #checkout .lottery-card.checkout-card .expand .tables .title span {
    display: block; }
  #checkout .lottery-card.checkout-card .card-total {
    flex-direction: column;
    justify-content: center !important; } }

.express-checkout {
  flex-direction: column; }
  .express-checkout.active {
    display: flex !important; }
  .express-checkout > .container {
    padding: 3.125rem 1.5625rem;
    text-align: center; }
    .express-checkout > .container h3 {
      line-height: 1.4; }
  .express-checkout .checkout-balance {
    background: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
    width: 100%; }
    .express-checkout .checkout-balance p {
      text-align: center;
      color: white;
      line-height: 1.5;
      font-size: 1.125em;
      margin: 0;
      padding: 0.9375rem 0; }
      .express-checkout .checkout-balance p span {
        display: block;
        font-weight: 700;
        font-size: 125%; }
  .express-checkout .checkout-actions {
    background: white;
    align-self: stretch;
    flex: 1;
    padding: 1.875rem 3.125rem !important;
    text-align: center;
    overflow: hidden;
    border-radius: 0 0 10px 10px; }
    .express-checkout .checkout-actions p {
      color: #2C68A5;
      font-weight: 700; }
      .express-checkout .checkout-actions p span {
        display: block;
        color: #E25F2C;
        font-size: 125%; }
    .express-checkout .checkout-actions .button {
      margin-bottom: 0.9375rem;
      width: 12.5rem; }
      .express-checkout .checkout-actions .button:last-of-type {
        margin-bottom: 0; }

/* Banner */
.banner {
  padding: 6.25rem 0 3.75rem;
  background-size: auto 120%;
  position: relative;
  overflow: hidden; }
  .banner .banner-overlay {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    max-width: 90%;
    width: 40rem;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
    transform: skew(10deg) translateX(-50%); }
  .banner .banner-image {
    position: absolute;
    left: 50%;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    max-width: none;
    transform: translateX(-50%); }
  .banner .container {
    width: 80%;
    max-width: 22.5rem;
    z-index: 2; }
    .banner .container * {
      color: white; }
    .banner .container h1 {
      color: white;
      font-weight: 600;
      margin: 0 0 2.5rem;
      line-height: 1.3;
      font-size: 1.375em;
      z-index: 1;
      position: relative; }
      .banner .container h1 span {
        font-weight: 800;
        display: block; }
    .banner .container p {
      font-size: 0.875em;
      font-weight: 400;
      font-family: "open-sans";
      line-height: 1.7;
      color: #FFFFFF; }
    .banner .container .button {
      font-size: 0.75em;
      font-weight: 400;
      display: inline-block;
      margin-top: 2.5rem;
      min-width: 14.375rem; }
  .banner.banner-game {
    padding: 7.8125rem 0 1.5625rem;
    position: relative; }
    .banner.banner-game .container {
      width: 92.5%; }
    .banner.banner-game .lottery-card {
      text-align: center;
      justify-content: center;
      background: transparent;
      box-shadow: none;
      padding: 0; }
      .banner.banner-game .lottery-card h1 {
        color: #2C68A5;
        text-align: center;
        justify-content: center;
        font-size: 1.0625em;
        margin-bottom: 0.625rem;
        font-weight: 600; }
      .banner.banner-game .lottery-card .lottery-card--amount {
        color: #E25F2C;
        font-size: 2em;
        line-height: 1;
        font-weight: 900;
        font-family: "proxima-soft"; }
      .banner.banner-game .lottery-card * {
        color: #2C68A5; }
      .banner.banner-game .lottery-card span {
        color: inherit; }
  .banner.secondary-banner {
    padding: 2.1875rem 0 1.25rem;
    position: relative; }
    .banner.secondary-banner .container {
      z-index: 1;
      position: relative;
      max-width: 13.125rem; }
      .banner.secondary-banner .container h1 {
        margin-bottom: 1.25rem; }
    .banner.secondary-banner p {
      font-weight: 400;
      font-family: "open-sans";
      font-size: 0.875em; }
      .banner.secondary-banner p a {
        font-weight: 600;
        text-decoration: underline; }
    .banner.secondary-banner:before {
      content: '';
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 15%, rgba(48, 44, 41, 0.2) 90%);
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.banner-full {
  min-height: 100vh;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center; }
  .banner-full .lottery-card {
    margin: 0 auto !important;
    text-align: center !important; }
    .banner-full .lottery-card svg {
      display: block;
      margin: 0 auto 1.25rem; }
    .banner-full .lottery-card.lottery-card-offset {
      top: -2.5rem; }

@media screen and (orientation: landscape) {
  .banner-full .section-image {
    max-height: 50vw !important; } }

@media only screen and (min-width: 760px) {
  .banner.banner-game {
    padding: 6.25rem 0;
    font-size: 22px; }
  .banner-full {
    display: flex;
    align-items: center;
    justify-content: center; } }

@media only screen and (min-width: 980px) {
  .banner {
    padding: 10vw 0;
    display: flex;
    align-items: center;
    max-height: 28.75rem;
    background-size: cover; }
    .banner .banner-overlay {
      left: calc((90% - 980px) / 2);
      transform: skew(10deg); }
    .banner .container {
      max-width: 100%;
      text-align: left; }
      .banner .container h1 {
        font-size: 2em;
        max-width: 30rem; }
        .banner .container h1 span {
          display: block; }
      .banner .container .button {
        font-size: 0.625em;
        min-width: 12.5rem; }
    .banner.banner-game {
      padding: 6vw 0; }
      .banner.banner-game .container {
        max-width: 73.75rem; }
      .banner.banner-game .lottery-card {
        margin-right: auto;
        margin-left: 0; }
  .banner-full {
    display: flex !important; } }

@media only screen and (min-width: 1080px) {
  .banner .container {
    max-width: 67.5rem; } }

@media only screen and (min-width: 1920px) {
  .banner {
    background-size: cover; } }

/* Lottery Cards */
.lottery-card {
  background-color: white;
  padding: 1.5625rem 0.625rem;
  border-radius: 0.625rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  max-width: 22.5rem;
  margin: 0 auto;
  flex: 0 0 auto;
  width: 100%; }
  .lottery-card.card-gradient {
    background-image: linear-gradient(to bottom right, #F5993C 0%, #F76B1C 100%);
    text-align: center;
    padding: 3.125rem 1.875rem; }
    .lottery-card.card-gradient p {
      color: white;
      font-size: 1.125em; }
    .lottery-card.card-gradient h2 {
      font-size: 1.875em;
      line-height: 1.3; }
  .lottery-card .flag-row {
    display: flex;
    align-items: center;
    justify-content: center; }
    .lottery-card .flag-row img {
      margin: 0 0.46875rem 0.9375rem; }
  .lottery-card .card-banner {
    width: 170px;
    background: #D8262D;
    position: absolute;
    top: 21px;
    left: -40px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0.3125rem 0;
    transform: rotate(-30deg);
    z-index: 999;
    color: white !important; }
  .lottery-card .card-trash {
    position: absolute;
    right: 0.9375rem;
    top: 0.9375rem;
    width: 1.4375rem;
    height: 1.4375rem;
    cursor: pointer; }
  .lottery-card .title {
    color: #E25F2C;
    font-size: 2.625em;
    font-weight: 400;
    margin-bottom: 1.25rem;
    letter-spacing: 0; }
  .lottery-card .icon-return:before {
    content: url(images/icon-return.svg);
    position: absolute;
    top: 0.9375rem;
    right: 0.9375rem;
    cursor: pointer; }
  .lottery-card .lottery-card--header {
    display: flex;
    max-width: 90%;
    margin: 0 auto;
    text-align: left;
    align-items: center;
    margin-bottom: 1.875rem; }
    .lottery-card .lottery-card--header img {
      width: 2.5625rem;
      height: 2.5625rem;
      margin: 0 0.9375rem 0 0; }
    .lottery-card .lottery-card--header h2 {
      margin: 0 0 0.3125rem; }
    .lottery-card .lottery-card--header p {
      font-size: 1em;
      font-weight: 700;
      margin: 0; }
  .lottery-card .lottery-card--content {
    max-width: 95%;
    margin: 0 auto; }
  .lottery-card .lottery-flag {
    margin: 0 auto 1.25rem;
    display: block; }
  .lottery-card h2 {
    font-size: 1.25em;
    color: #2C68A5;
    font-weight: 700;
    letter-spacing: 0.03125rem;
    margin: 0 0 0.3125rem; }
  .lottery-card h3 {
    font-size: 1.25em;
    color: #E25F2C;
    line-height: 1.4;
    margin: 0 0 1.5625rem; }
  .lottery-card p {
    color: #2C68A5;
    font-size: 1.125em;
    margin: 0 0 0.625rem;
    font-family: "open-sans"; }
    .lottery-card p a {
      color: inherit;
      text-decoration: underline;
      display: inline; }
    .lottery-card p.orange {
      color: #E25F2C;
      font-size: 0.8125em; }
  .lottery-card .card-icon svg {
    margin: 0 auto 1.5625rem;
    display: block;
    height: 3.125rem;
    width: auto; }
  .lottery-card .lottery-card--amount {
    color: #E25F2C;
    font-size: 2.625em;
    margin: 0 0 0.625rem;
    position: relative;
    display: inline-block;
    letter-spacing: 0;
    font-weight: 900;
    line-height: 1;
    font-family: "proxima-soft"; }
    .lottery-card .lottery-card--amount .lottery-card--currency {
      position: relative;
      top: -0.625rem;
      right: 0 !important; }
  .lottery-card .lottery-card--amount-thin {
    color: #E25F2C;
    font-size: 2.625em;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin: 1.25rem 0; }
    .lottery-card .lottery-card--amount-thin span {
      font-size: 45%;
      margin-right: 0.3125rem; }
  .lottery-card .lottery-card--currency {
    color: inherit;
    font-size: 50%;
    position: absolute;
    right: 100%;
    margin-right: 0.3125rem;
    line-height: 1.3;
    font-weight: 700; }
  .lottery-card .button {
    margin-top: 1.25rem; }
  .lottery-card .read-more {
    margin-top: 0.9375rem;
    color: #2C68A5;
    font-weight: 500;
    display: inline-flex;
    justify-content: center; }
  .lottery-card.lottery-card-offset {
    margin-bottom: -4.6875rem;
    position: relative;
    top: -4.6875rem; }
  .lottery-card.lottery-card-transparent {
    background: transparent; }
    .lottery-card.lottery-card-transparent .flipper {
      background: transparent;
      border: 2px solid white; }
  .lottery-card.lottery-card-left {
    text-align: left; }
  .lottery-card.no-sides {
    padding: 1.5625rem 0 !important; }
    .lottery-card.no-sides .card-container {
      padding: 0 0.625rem; }
  .lottery-card.lottery-card-contact {
    padding: 2.5rem 1.25rem; }
    .lottery-card.lottery-card-contact img {
      max-width: 12.5rem; }
    .lottery-card.lottery-card-contact h3 {
      font-family: "open-sans";
      font-size: 1.375em;
      font-weight: 600;
      margin: 1.875rem 0 0.625rem; }
    .lottery-card.lottery-card-contact p {
      font-size: 0.875em;
      font-weight: 400;
      font-family: "open-sans";
      color: #2C68A5;
      line-height: 1.5; }
      .lottery-card.lottery-card-contact p a {
        color: inherit;
        font-size: inherit;
        text-decoration: none; }
      .lottery-card.lottery-card-contact p span {
        font-weight: 600; }
    .lottery-card.lottery-card-contact > a {
      font-size: 0.875em;
      font-weight: 600;
      font-family: "open-sans";
      color: #2C68A5;
      line-height: 1.3;
      text-decoration: underline;
      max-width: 13.75rem; }
  .lottery-card.lottery-card-privacy {
    text-align: left;
    padding: 1.25rem 0.9375rem; }
    .lottery-card.lottery-card-privacy h3, .lottery-card.lottery-card-privacy p {
      font-family: "open-sans";
      font-size: 0.875em;
      font-weight: 600;
      color: #4C4D4A; }
    .lottery-card.lottery-card-privacy h3 {
      margin: 0 0 0.3125rem; }
    .lottery-card.lottery-card-privacy p {
      font-weight: 400;
      margin-bottom: 1.25rem; }
      .lottery-card.lottery-card-privacy p.bold {
        font-weight: 600; }
      .lottery-card.lottery-card-privacy p a {
        color: inherit;
        line-height: 1;
        text-decoration: underline; }
  .lottery-card.lottery-card--basic {
    padding: 1.5625rem 0.625rem !important; }
    .lottery-card.lottery-card--basic *:last-child {
      margin-bottom: 0; }
  .lottery-card.lottery-card-pending {
    background-color: #E3E3E3; }
  .lottery-card.lottery-card--grey {
    text-align: left; }
    .lottery-card.lottery-card--grey .radio-container {
      margin: 1.5625rem 0 0 !important; }
    .lottery-card.lottery-card--grey p {
      color: #7A7B78;
      margin: 0;
      font-size: 1em;
      font-weight: 600; }
      .lottery-card.lottery-card--grey p.orange {
        color: #E25F2C;
        font-size: 1.125em;
        font-weight: 600;
        margin-bottom: 0.9375rem; }
    .lottery-card.lottery-card--grey.flip .card-flip:before, .lottery-card.lottery-card--grey.flip .card-flip:hover {
      background-color: transparent !important; }
    .lottery-card.lottery-card--grey .flipper {
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1); }
      .lottery-card.lottery-card--grey .flipper .card-flip {
        top: 1.71875rem; }
      .lottery-card.lottery-card--grey .flipper .lottery-card-front, .lottery-card.lottery-card--grey .flipper .lottery-card-back {
        padding: 1.5625rem 5.3125rem 1.875rem 1.875rem; }
      .lottery-card.lottery-card--grey .flipper .lottery-card-back {
        background-color: rgba(0, 0, 0, 0.05); }
  .lottery-card.lottery-card--works {
    background-color: transparent;
    border: 2px solid white;
    box-shadow: none;
    padding: 1.875rem;
    font-size: 16px; }
    .lottery-card.lottery-card--works svg, .lottery-card.lottery-card--works img {
      margin: 0 auto 1.25rem;
      height: 3.125rem;
      min-width: 3.125rem; }
    .lottery-card.lottery-card--works img {
      height: 2.5rem;
      margin-bottom: 1.875rem; }
    .lottery-card.lottery-card--works p {
      color: white;
      font-size: 1.125em;
      margin: 0; }
  .lottery-card.lottery-card--combo p {
    font-weight: 600; }
    .lottery-card.lottery-card--combo p.lottery-card--amount {
      font-weight: 900; }
  .lottery-card.lottery-card--combo .combo-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.25rem 0; }
    .lottery-card.lottery-card--combo .combo-actions input {
      color: #2C68A5;
      width: 3.125rem;
      height: auto;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      margin: 0 0.625rem;
      padding: 0 0 0.15625rem;
      border-radius: 0;
      text-align: center;
      font-size: 1.125em !important; }
    .lottery-card.lottery-card--combo .combo-actions span {
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.2);
      opacity: 0.6;
      width: 1.5625rem;
      height: 1.5625rem;
      font-size: 1.25em;
      line-height: 1; }

.lottery-combo--row {
  width: 95vw;
  max-width: 860px;
  margin: 0 auto;
  justify-content: center; }
  .lottery-combo--row .lottery-card {
    margin-top: 0.9375rem; }
  .lottery-combo--row + input[type="submit"] {
    margin-top: 1.875rem; }

.lottery-card--row {
  border-top: 1px solid white;
  margin-top: 2.5rem;
  padding-top: 1.25rem; }
  .lottery-card--row .container > h3 {
    flex: 0 0 100%;
    width: 100%;
    color: white;
    font-size: 1.375em;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 1.875rem; }
  .lottery-card--row .container .lottery-card {
    margin-bottom: 0.9375rem; }
    .lottery-card--row .container .lottery-card p.lottery-card--amount {
      font-size: 2em; }

@media only screen and (min-width: 415px) {
  .lottery-card {
    min-width: 18.75rem; } }

@media only screen and (min-width: 560px) {
  .lottery-card.lottery-card-offset {
    margin-bottom: -20vw;
    top: -20vw; } }

@media only screen and (min-width: 768px) {
  .lottery-card--row .container {
    display: flex;
    flex-wrap: wrap;
    max-width: 39.375rem; }
    .lottery-card--row .container .lottery-card {
      flex: 0 0 40%; } }

@media only screen and (min-width: 860px) {
  .lottery-combo--row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .lottery-combo--row .lottery-card--combo {
      flex: 0 0 32%;
      width: 32%;
      margin: 1.25rem calc(4% / 2) 0 0;
      min-width: 0; }
      .lottery-combo--row .lottery-card--combo:nth-of-type(3n + 3) {
        margin-right: 0; }
      .lottery-combo--row .lottery-card--combo .combo-actions span {
        transition: all ease-in-out 0.2s;
        cursor: pointer; }
      .lottery-combo--row .lottery-card--combo .combo-actions span:hover {
        opacity: 1; } }

@media only screen and (min-width: 980px) {
  a.lottery-card:hover {
    opacity: 1; }
    a.lottery-card:hover .button.button-orange {
      background-color: #E25F2C;
      color: white; }
    a.lottery-card:hover .dropdown-link {
      opacity: 0.6; } }

@media only screen and (min-width: 1280px) {
  .lottery-card--row .container {
    max-width: 100%;
    justify-content: space-between; }
    .lottery-card--row .container .lottery-card {
      max-width: 17.5rem;
      flex: 0 0 17.5rem;
      min-width: 0;
      margin: 0; } }

@keyframes flipOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.lottery-deck-tab .select-dropdown {
  margin: 0 0 1.25rem; }

.lottery-deck {
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  width: 100%; }
  .lottery-deck.lottery-slider--slider {
    display: block;
    padding: 0 !important; }
  .lottery-deck .lottery-card.lottery-deck--card {
    flex: 0 0 48.5%;
    margin-right: 3%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    min-width: 0;
    max-width: 100%; }
    .lottery-deck .lottery-card.lottery-deck--card .lottery-flag {
      width: 2.375rem;
      height: 2.375rem;
      margin-bottom: 0.625rem; }
    .lottery-deck .lottery-card.lottery-deck--card h2 {
      font-size: 1.125em;
      line-height: 1.2;
      margin: 0 0 0.9375rem; }
    .lottery-deck .lottery-card.lottery-deck--card .lottery-card--amount {
      font-size: 1.375em; }
      .lottery-deck .lottery-card.lottery-deck--card .lottery-card--amount span {
        right: 75%; }
    .lottery-deck .lottery-card.lottery-deck--card .add-quickplay {
      margin-top: auto; }
    .lottery-deck .lottery-card.lottery-deck--card .cart-qp {
      color: #E25F2C;
      font-size: 1.375em;
      font-weight: 900;
      font-family: "proxima-soft";
      margin: 0 0 0.625rem;
      line-height: 1; }
    .lottery-deck .lottery-card.lottery-deck--card .read-more {
      margin-top: auto; }
    .lottery-deck .lottery-card.lottery-deck--card .timer {
      font-size: 0.75em !important;
      margin-top: auto; }
      .lottery-deck .lottery-card.lottery-deck--card .timer + .button {
        margin-top: 0; }
      .lottery-deck .lottery-card.lottery-deck--card .timer svg {
        flex: 0 0 0.9375rem;
        margin-right: 0.625rem; }
    .lottery-deck .lottery-card.lottery-deck--card:nth-of-type(even) {
      margin-right: 0; }
    .lottery-deck .lottery-card.lottery-deck--card .flipper {
      padding: 0 !important;
      display: flex;
      justify-content: center; }
      .lottery-deck .lottery-card.lottery-deck--card .flipper .card-flip {
        transition: opacity ease-in 0s !important; }
      .lottery-deck .lottery-card.lottery-deck--card .flipper h3 {
        color: white;
        font-size: 0.8125em;
        font-weight: 400;
        line-height: 1.3;
        margin-bottom: 0.9375rem; }
      .lottery-deck .lottery-card.lottery-deck--card .flipper .flip-trigger {
        color: white;
        text-decoration: underline;
        font-size: 0.875em;
        font-weight: 400;
        margin-top: auto;
        top: 0.625rem;
        right: 0.625rem; }
      .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-front {
        padding: 0.9375rem 0.625rem;
        display: flex;
        flex-direction: column; }
      .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back {
        margin-top: 2.5rem;
        background: white;
        padding: 0rem 0.625rem 0.9375rem;
        font-size: initial;
        width: 100%;
        border-radius: 0.625rem; }
        .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back p {
          color: #E25F2C;
          font-weight: 400;
          font-size: 0.8125em; }
        .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back .lottery-numbers-container {
          margin-bottom: 0.625rem; }
          .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back .lottery-numbers-container p {
            font-size: 0.75em !important;
            margin-bottom: 0.3125rem; }
          .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back .lottery-numbers-container .lottery-numbers {
            margin-bottom: 0.15625rem; }
            .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back .lottery-numbers-container .lottery-numbers .lottery-number {
              margin: 0 0.0625rem 0 0;
              width: calc((100% - (6 * 0.0625rem)) / 7); }
              .lottery-deck .lottery-card.lottery-deck--card .flipper .lottery-card-back .lottery-numbers-container .lottery-numbers .lottery-number span {
                font-size: 0.625em; }
    .lottery-deck .lottery-card.lottery-deck--card.flip .card-flip {
      left: 0.625rem !important;
      top: 0.625rem !important; }
    .lottery-deck .lottery-card.lottery-deck--card .timer-countdown {
      font-size: 10px; }
  .lottery-deck.lottery-deck--quickplay {
    justify-content: center !important; }

#play-now--quick-play .container > h2 {
  color: white;
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 1.375em;
  letter-spacing: 0.5px; }

#play-now--quick-play .container > p {
  color: white;
  margin: 1.25rem 0 1.5625rem; }

.lottery-card--quickplay {
  margin-bottom: 1.25rem;
  text-align: left;
  position: relative;
  padding: 1.25rem 1.875rem;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  min-width: 0;
  margin: 0 0 1.25rem;
  flex: 0 0 100%;
  transform-style: preserve-3d;
  overflow: visible; }
  .lottery-card--quickplay.popular:before {
    content: 'POPULAR';
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10%, -60%);
    background-color: #F8E71C;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    color: #E25F2C;
    font-size: 14px;
    border-radius: 5px;
    padding: 7.5px 15px;
    letter-spacing: 1px; }
  .lottery-card--quickplay p {
    font-weight: 600;
    margin: 0;
    font-family: "proxima-soft"; }
    .lottery-card--quickplay p.orange {
      font-size: 1.375em;
      letter-spacing: 0; }
  .lottery-card--quickplay .icon-cart, .lottery-card--quickplay img {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    width: 3.125rem;
    height: auto;
    max-height: 3.125rem;
    transform: scale(0);
    animation: scaleUp 0.2s ease-in-out 0.05s;
    animation-fill-mode: forwards;
    transform-style: preserve-3d; }
    .lottery-card--quickplay .icon-cart ellipse, .lottery-card--quickplay img ellipse {
      display: none; }
    .lottery-card--quickplay .icon-cart #cart-arrow, .lottery-card--quickplay img #cart-arrow {
      position: relative;
      transform-style: preserve-3d;
      transform: scaleY(1); }
  .lottery-card--quickplay img {
    width: 2.1875rem;
    top: 1.15625rem;
    right: 1.15625rem; }
  .lottery-card--quickplay .icon-check-circle {
    stroke: #79B409;
    position: absolute;
    right: 1.40625rem;
    top: 1.53125rem;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    transform-style: preserve-3d;
    transform: scale(0); }
  .lottery-card--quickplay .add-quickplay {
    color: #E25F2C;
    text-align: center;
    font-size: 0.6875em;
    margin: 0.9375rem auto 0;
    text-decoration: underline;
    display: block; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .lottery-card--quickplay .icon-cart, .lottery-card--quickplay img {
      transform: scale(1); } }
  .lottery-card--quickplay.active {
    animation: scaleCard 0.75s ease-in-out 0s; }
    .lottery-card--quickplay.active .icon-cart, .lottery-card--quickplay.active img {
      animation: cartAnimation 0.75s ease-in-out 0.05s;
      animation-fill-mode: forwards; }
      .lottery-card--quickplay.active .icon-cart #cart-arrow, .lottery-card--quickplay.active img #cart-arrow {
        transform: scaleY(1.5);
        transition: all ease-in-out 0.3s; }
    .lottery-card--quickplay.active .icon-check-circle {
      animation-name: dash;
      animation-duration: 0.4s;
      animation-delay: 0.4s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      transform: scale(1); }
    @supports (-ms-ime-align: auto) {
      .lottery-card--quickplay.active .icon-cart, .lottery-card--quickplay.active img {
        transform: scale(0); }
      .lottery-card--quickplay.active .icon-check-circle {
        stroke-dasharray: 100;
        stroke-dashoffset: 200;
        transition: all ease-in-out 0.4s 0.4s;
        transform: scale(1);
        /*animation: none;*/ } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .lottery-card--quickplay.active .icon-cart, .lottery-card--quickplay.active img {
        transform: scale(0);
        animation: none;
        transition: none;
        display: none; }
      .lottery-card--quickplay.active .icon-check-circle {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        transition: none;
        transform: scale(1);
        animation: none; } }

@media only screen and (min-width: 420px) {
  .lottery-deck .lottery-card.lottery-deck--card .timer-countdown {
    font-size: 1.0625em; } }

@media only screen and (min-width: 540px) {
  .button.add-quickplay {
    font-size: 0.75em;
    padding: 0.625rem 0.3125rem; } }

@media only screen and (min-width: 680px) {
  .lottery-card--quickplay {
    flex: 0 0 16.875rem;
    max-width: 16.875rem;
    margin-right: 1.25rem; }
    .lottery-card--quickplay:nth-of-type(2n + 2) {
      margin-right: 0; } }

@media only screen and (min-width: 980px) {
  #play-now--quick-play .container {
    max-width: 100%;
    width: 100%; }
  .lottery-card--quickplay {
    flex: 0 0 16.5625rem;
    max-width: 16.5625rem;
    margin-right: 1.25rem !important;
    cursor: pointer; }
    .lottery-card--quickplay:nth-of-type(3n+3) {
      margin-right: 0; }
    .lottery-card--quickplay:hover {
      background-color: rgba(255, 255, 255, 0.85); }
      .lottery-card--quickplay:hover .icon-cart, .lottery-card--quickplay:hover img {
        transform: scale(1); }
    .lottery-card--quickplay.active {
      background-color: white; }
      .lottery-card--quickplay.active:hover {
        transform: none !important; } }

@keyframes cartAnimation {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(0); } }

@keyframes scaleCard {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.05); }
  100% {
    transform: scale(1); } }

@keyframes dash {
  to {
    stroke-dashoffset: 200; } }

@keyframes scaleUp {
  to {
    transform: scale(1); } }

.results-type {
  font-weight: 700;
  color: #2C68A5 !important; }
  .results-type span {
    color: #E25F2C; }

.lottery-card.lottery-card--results {
  max-width: 37.5rem; }
  .lottery-card.lottery-card--results .lottery-numbers-container {
    margin-bottom: 0.625rem; }
    .lottery-card.lottery-card--results .lottery-numbers-container .lottery-numbers .lottery-number {
      max-width: 10vw; }

.lottery-card.lottery-card-results {
  margin-bottom: 0.3125rem;
  padding: 1.25rem 0;
  text-align: center; }
  .lottery-card.lottery-card-results .flipper > .flip-trigger {
    display: none; }
  .lottery-card.lottery-card-results.flip .flip-trigger {
    right: auto !important;
    left: 0.625rem !important;
    display: block; }
  .lottery-card.lottery-card-results.flip .game-type {
    display: none !important; }
  .lottery-card.lottery-card-results p {
    font-size: 1em; }
  .lottery-card.lottery-card-results:last-of-type {
    margin-bottom: 0; }
  .lottery-card.lottery-card-results .lottery-date {
    color: #E25F2C;
    font-weight: 700;
    font-size: 0.75em;
    text-align: left;
    margin: 0 0 0.3125rem 0; }
  .lottery-card.lottery-card-results .draw-card--header {
    display: flex;
    text-align: left;
    align-items: center;
    margin: 0 auto 1.5625rem;
    max-width: 90%; }
    .lottery-card.lottery-card-results .draw-card--header .lottery-flag {
      width: 2.1875rem;
      height: 2.1875rem;
      margin: 0 0.625rem; }
    .lottery-card.lottery-card-results .draw-card--header .draw-details {
      flex: 1;
      margin-right: 0.625rem; }
      .lottery-card.lottery-card-results .draw-card--header .draw-details h3 {
        margin: 0;
        font-size: 1em;
        font-weight: 700;
        color: #2C68A5;
        margin: 0 0 0.3125rem;
        line-height: 1; }
      .lottery-card.lottery-card-results .draw-card--header .draw-details .lottery-price {
        margin: 0;
        font-size: 1.375em;
        font-weight: 800;
        color: #E25F2C;
        line-height: 1;
        font-family: "proxima-soft"; }
    .lottery-card.lottery-card-results .draw-card--header .draw-cost, .lottery-card.lottery-card-results .draw-card--header .draw-result {
      font-weight: 700;
      color: #E25F2C;
      line-height: 1.3;
      margin: auto 0 0;
      font-size: 1em; }
      .lottery-card.lottery-card-results .draw-card--header .draw-cost span, .lottery-card.lottery-card-results .draw-card--header .draw-result span {
        display: block; }
    .lottery-card.lottery-card-results .draw-card--header .draw-result {
      margin-top: 0; }
  .lottery-card.lottery-card-results .lottery-numbers-container {
    margin-bottom: 0.625rem; }
    .lottery-card.lottery-card-results .lottery-numbers-container .lottery-numbers .lottery-number {
      font-size: initial; }
      .lottery-card.lottery-card-results .lottery-numbers-container .lottery-numbers .lottery-number > span {
        font-size: initial; }
        .lottery-card.lottery-card-results .lottery-numbers-container .lottery-numbers .lottery-number > span > span {
          font-size: 0.875em; }
    .lottery-card.lottery-card-results .lottery-numbers-container:last-of-type {
      margin-bottom: 0; }
  .lottery-card.lottery-card-results .draw-card--winnings {
    background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
    display: inline-flex;
    justify-content: center;
    border-radius: 0.625rem; }
    .lottery-card.lottery-card-results .draw-card--winnings p {
      text-align: center;
      display: flex;
      flex-direction: column;
      color: white;
      margin: 0;
      font-size: 0.875em;
      font-weight: 700;
      padding: 0.9375rem 3.75rem;
      line-height: 1; }
      .lottery-card.lottery-card-results .draw-card--winnings p > span {
        font-size: 137.5%;
        margin-top: 0.625rem;
        font-weight: 800;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .lottery-card.lottery-card-results .draw-card--winnings p > span .currency {
          font-size: 75%;
          margin-right: 0.125rem; }
      .lottery-card.lottery-card-results .draw-card--winnings p.winnings-details {
        text-align: left;
        display: block;
        margin: 0.625rem 0;
        font-weight: 400;
        font-size: 1.0625em; }
        .lottery-card.lottery-card-results .draw-card--winnings p.winnings-details span {
          font-size: inherit;
          display: inline;
          font-weight: inherit;
          text-decoration: underline; }
  .lottery-card.lottery-card-results .draw-card--footer {
    margin: 1.5625rem 0; }
    .lottery-card.lottery-card-results .draw-card--footer h4 {
      color: #E25F2C;
      font-size: 1.375em;
      margin: 0 0 0.3125rem; }
    .lottery-card.lottery-card-results .draw-card--footer h5 {
      color: #E25F2C; }
    .lottery-card.lottery-card-results .draw-card--footer p {
      color: #2C68A5;
      font-size: 0.875em;
      font-weight: 400; }
  .lottery-card.lottery-card-results .results-expand {
    justify-content: flex-start;
    margin-left: 0rem;
    margin-top: -0.625rem;
    line-height: 1;
    justify-content: center;
    cursor: pointer; }
  .lottery-card.lottery-card-results .results-content {
    text-align: center;
    max-width: 22.5rem;
    margin: 0 auto; }
    .lottery-card.lottery-card-results .results-content > p {
      font-size: 1em !important;
      font-weight: 700;
      margin: 0 0 0.9375rem;
      line-height: 1; }
    .lottery-card.lottery-card-results .results-content .results-expand {
      margin-top: 0;
      display: inline-flex !important; }
      .lottery-card.lottery-card-results .results-content .results-expand.active, .lottery-card.lottery-card-results .results-content .results-expand:after {
        display: flex; }
  .lottery-card.lottery-card-results .draw-dates {
    border-top: 1px solid #E5E5E5; }
    .lottery-card.lottery-card-results .draw-dates h4 {
      color: #2C68A5 !important;
      font-size: 1em !important;
      font-weight: 600 !important;
      margin: 0.625rem 0 0.9375rem !important; }
    .lottery-card.lottery-card-results .draw-dates .draw-date {
      margin: 0;
      font-size: 1em;
      font-family: "proxima-soft";
      font-weight: 400; }

.lottery-card .number-lines-expand {
  width: 100%;
  justify-content: center; }

.lottery-card .subtotal {
  color: #E25F2C !important;
  font-size: 1.375em !important;
  font-weight: 400;
  font-family: "proxima-soft" !important;
  text-align: center;
  margin: 1.25rem 0 0; }

.lottery-card .currency-exchange {
  color: #4C4D4A;
  font-size: 0.875em;
  text-align: right;
  margin: 0 auto;
  font-weight: 700;
  max-width: 30.9375rem;
  margin-top: 0.625rem; }

.lottery-card .order-number {
  margin-top: 1.25rem !important;
  font-weight: 400 !important; }

.results-actions {
  margin: 1.25rem 0 0; }
  .results-actions p {
    color: #7A7B78 !important;
    font-size: 0.75em;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0.625rem 1% !important; }
    .results-actions p svg {
      width: 1.5625rem;
      flex: 0 0 1.5625rem;
      height: 1.5625rem;
      margin-right: 0.625rem; }
    .results-actions p.active {
      color: #E25F2C !important; }
      .results-actions p.active svg {
        stroke: #E25F2C; }
        .results-actions p.active svg.icon-check-circle path {
          fill: inherit; }
        .results-actions p.active svg ellipse {
          stroke: #E25F2C; }
        .results-actions p.active svg path {
          fill: #E25F2C;
          stroke: #E25F2C; }

.results-expand {
  position: relative;
  line-height: 1;
  display: flex-inline;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  margin-top: 1.25rem; }
  .results-expand span {
    text-decoration: underline; }
  .results-expand:after {
    content: '';
    background-image: url(images/icon-angle-down.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2C68A5;
    border-radius: 50%;
    height: 0.9375rem;
    width: 0.9375rem;
    left: calc(100% + 1.25rem);
    text-decoration: none;
    transition: all ease-in-out 0.2s;
    margin-left: 1.25rem;
    margin-top: 1px; }
  .results-expand.active:after {
    transform: rotate(-180deg); }
  .results-expand.active + .results-content {
    margin-top: 1.5625rem;
    display: block; }
    .results-expand.active + .results-content.no-slide {
      max-height: 100% !important; }

.results-content {
  max-height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.3s; }
  .results-content.no-slide {
    display: none; }
  .results-content .game-types {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 18.75rem;
    margin: 0 auto 0.9375rem;
    flex-wrap: wrap; }
  .results-content .game-type {
    display: flex;
    font-weight: 400 !important;
    justify-content: center;
    align-items: center; }
    .results-content .game-type svg {
      margin-left: 0.3125rem; }
      .results-content .game-type svg * {
        stroke: #E25F2C; }

.division-results {
  display: flex;
  text-align: left !important; }
  .division-results div {
    margin-right: 0.9375rem;
    flex: 1;
    display: flex;
    flex-direction: column; }
    .division-results div span {
      display: block;
      margin-top: 0.625rem;
      font-size: 0.875em;
      color: #312C29;
      font-family: "open-sans";
      font-weight: 400; }
      .division-results div span.division-title {
        color: #2C68A5;
        margin-bottom: auto;
        flex: 1 0 auto;
        font-weight: 700; }
    .division-results div:last-child {
      margin-right: 0; }
  .division-results > * {
    flex: 1;
    margin: 0 1%; }
  .division-results .flex-2 {
    flex: 1.5; }

@media only screen and (min-width: 860px) {
  .lottery-card.lottery-card-results .lottery-card-back {
    padding-top: 0; } }

@media only screen and (min-width: 980px) {
  .lottery-card.lottery-card--results > .lottery-numbers-container {
    display: flex;
    max-width: 28.75rem;
    margin: 0 auto;
    justify-content: center; }
    .lottery-card.lottery-card--results > .lottery-numbers-container .lottery-numbers {
      width: auto;
      margin-right: 0.1875rem; }
      .lottery-card.lottery-card--results > .lottery-numbers-container .lottery-numbers + .lottery-numbers {
        margin-right: 0; }
      .lottery-card.lottery-card--results > .lottery-numbers-container .lottery-numbers .lottery-number {
        width: 2.1875rem;
        height: 2.1875rem;
        padding: 0; }
  .division-results {
    max-width: 30rem;
    margin: 0 auto;
    position: relative; }
  .results-actions {
    display: flex;
    justify-content: space-around; }
    .results-actions.results-actions--column {
      display: block;
      margin-top: 0.625rem; }
      .results-actions.results-actions--column p {
        margin-bottom: 0.3125rem !important; } }

.lottery-card.lottery-card-favourite .favourite-lottery {
  margin-bottom: 3.125rem; }
  .lottery-card.lottery-card-favourite .favourite-lottery:last-of-type {
    margin-bottom: 0; }

.lottery-card.lottery-card-favourite .favourite-header {
  display: flex;
  align-items: center;
  justify-content: center; }
  .lottery-card.lottery-card-favourite .favourite-header img {
    margin: 0;
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.625rem; }
  .lottery-card.lottery-card-favourite .favourite-header h4 {
    margin: 0; }

.lottery-card.lottery-card-favourite .custom-numbers--action .button {
  margin: 0.625rem 0 0; }

.lottery-card.lottery-card-favourite .lottery-favourite {
  display: none !important; }

.lottery-card-flip {
  perspective: 1000px;
  padding: 3px;
  box-shadow: none;
  align-items: stretch;
  background: transparent; }
  .lottery-card-flip .card-trash {
    right: 0.625rem;
    top: 0.9375rem;
    opacity: 1;
    transition: all ease-in-out 0.2s 0.2s;
    z-index: 999; }
  .lottery-card-flip .card-flip {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    color: #2C68A5;
    width: 1.4375rem;
    height: 1.4375rem;
    font-size: 1.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #2C68A5;
    border-radius: 50%;
    z-index: 99999;
    transition: opacity ease-in 0.3s;
    cursor: pointer; }
    .lottery-card-flip .card-flip:before {
      content: 'i';
      font-weight: 700;
      font-size: 13px;
      color: #2C68A5; }
  .lottery-card-flip .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    background: white;
    border-radius: 0.625rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    height: 100%; }
    .lottery-card-flip .flipper.no-padding {
      padding: 0;
      box-shadow: none;
      width: auto; }
      .lottery-card-flip .flipper.no-padding .card-flip {
        border-color: white;
        background-color: transparent !important; }
        .lottery-card-flip .flipper.no-padding .card-flip:before, .lottery-card-flip .flipper.no-padding .card-flip:after {
          background-color: transparent !important;
          color: white; }
    .lottery-card-flip .flipper.draw-card--winnings {
      padding: 1.875rem 0; }
      .lottery-card-flip .flipper.draw-card--winnings > div {
        padding: 0 1.5625rem;
        min-width: 16.25rem; }
      .lottery-card-flip .flipper.draw-card--winnings p {
        padding: 0; }
  .lottery-card-flip .lottery-card-front, .lottery-card-flip .lottery-card-back {
    backface-visibility: hidden;
    transition: transform ease-in-out 0.3s;
    padding: 1.875rem 5% 1.5625rem;
    overflow: hidden;
    border-radius: 0.625rem; }
  .lottery-card-flip .lottery-card-front {
    transform: rotateY(0deg);
    z-index: 2;
    height: auto; }
    .lottery-card-flip .lottery-card-front .card-flip {
      position: static !important;
      margin-left: 0.625rem; }
    .lottery-card-flip .lottery-card-front .lottery-date {
      display: flex;
      align-items: center; }
  .lottery-card-flip .lottery-card-back {
    -webkit-transform: translateZ(-1px);
    transform: rotateY(-180deg);
    height: 0;
    display: none; }
    .lottery-card-flip .lottery-card-back .subtotal {
      text-align: center !important; }
    .lottery-card-flip .lottery-card-back.lottery-card-back--spacing {
      padding-top: 3.125rem; }
  .lottery-card-flip.flip .card-flip {
    left: 1.25rem;
    border: none;
    animation: flipOpacity ease-in-out 0.2s; }
    .lottery-card-flip.flip .card-flip:before {
      content: '';
      background-image: url(images/icon-return.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: block;
      width: 1.5625rem;
      height: 1.5625rem;
      transform: rotateY(180deg);
      background-color: white !important; }
  .lottery-card-flip.flip .card-trash {
    right: auto;
    left: 0.625rem;
    opacity: 0;
    transition: all ease-in-out 0s;
    visibility: hidden; }
  .lottery-card-flip.flip .draw-card--winnings .card-flip:before {
    background-image: url(images/icon-return-white.svg); }
  .lottery-card-flip.flip .flipper {
    transform: rotateY(180deg); }
    .lottery-card-flip.flip .flipper .lottery-card-front {
      height: 0;
      display: none; }
    .lottery-card-flip.flip .flipper .lottery-card-back {
      height: auto;
      display: block;
      position: relative;
      z-index: 9999; }

@media only screen and (min-width: 980px) {
  .lottery-card-front .flip-trigger {
    transition: background-color ease-in-out 0.2s; }
    .lottery-card-front .flip-trigger:hover {
      background-color: #2C68A5; }
      .lottery-card-front .flip-trigger:hover:before {
        color: white !important; }
  .card-trash:hover * {
    stroke: #D8262D; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .lottery-card-flip .flipper {
    overflow: hidden; }
    .lottery-card-flip .flipper .card-flip {
      z-index: 9999; }
    .lottery-card-flip .flipper .lottery-card-back, .lottery-card-flip .flipper .lottery-card-front {
      transform: none;
      height: auto;
      z-index: 999; }
    .lottery-card-flip .flipper .lottery-card-front {
      transition: all ease-in-out 1s 1s !important;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    .lottery-card-flip .flipper .lottery-card-back {
      opacity: 0; }
  .lottery-card-flip.flip .card-flip {
    left: 1.25rem;
    z-index: 9999; }
  .lottery-card-flip.flip .lottery-card-front {
    opacity: 0; }
  .lottery-card-flip.flip .lottery-card-back {
    transform: rotateY(180deg) !important;
    backface-visibility: visible !important;
    animation-name: flipOpacity;
    animation-duration: 0.3s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; }
  .lottery-card-flip.flip-front .lottery-card-front {
    opacity: 0;
    backface-visibility: visible !important;
    animation-name: flipOpacity;
    animation-duration: 0.3s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards; } }

@keyframes flipOpacity {
  to {
    opacity: 1; } }

.lottery-card-account {
  width: 32.5rem;
  max-width: 100%;
  padding: 2.1875rem 1.5625rem !important;
  cursor: initial; }
  .lottery-card-account .lottery-card-account--header {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #7A7B78;
    padding-bottom: 1.5625rem; }
    .lottery-card-account .lottery-card-account--header li {
      margin: 0;
      text-align: center;
      font-size: 1em;
      flex: 0 0 30%;
      margin-right: 1.875rem;
      cursor: pointer;
      text-decoration: none !important;
      font-weight: 600; }
      .lottery-card-account .lottery-card-account--header li:last-of-type {
        margin-right: 0; }
      .lottery-card-account .lottery-card-account--header li .card-icon {
        border: 3px solid #7A7B78;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.75rem;
        flex: 0 0 3.75rem;
        height: 3.75rem;
        align-self: center;
        margin: 0 auto 0.9375rem;
        transition: all ease-in-out 0.15s; }
        .lottery-card-account .lottery-card-account--header li .card-icon svg {
          border: none !important;
          width: 2.1875rem !important;
          flex: 0 0 2.1875rem !important;
          max-height: 2.1875rem !important;
          min-height: 1.875rem;
          border-radius: 0 !important;
          margin: 0 !important; }
          .lottery-card-account .lottery-card-account--header li .card-icon svg * {
            transition: all ease-in-out 0.15s; }
      .lottery-card-account .lottery-card-account--header li:hover .card-icon {
        color: #E25F2C;
        border-color: #E25F2C; }
        .lottery-card-account .lottery-card-account--header li:hover .card-icon svg path {
          stroke: #E25F2C;
          fill: #E25F2C; }
        .lottery-card-account .lottery-card-account--header li:hover .card-icon svg circle, .lottery-card-account .lottery-card-account--header li:hover .card-icon svg polyline {
          stroke: #E25F2C; }
      .lottery-card-account .lottery-card-account--header li svg * {
        stroke: #7A7B78;
        transition: none !important; }
      .lottery-card-account .lottery-card-account--header li svg circle {
        transition: all ease-in-out 0.2s !important; }
      .lottery-card-account .lottery-card-account--header li svg path {
        fill: #7A7B78;
        transition: all ease-in-out 0.2s !important; }
      .lottery-card-account .lottery-card-account--header li svg #Oval-4 {
        fill: none; }
      .lottery-card-account .lottery-card-account--header li svg #ICON-Cart > path {
        fill: transparent; }
      .lottery-card-account .lottery-card-account--header li.active {
        color: #E25F2C; }
        .lottery-card-account .lottery-card-account--header li.active svg {
          border-color: #E25F2C !important; }
          .lottery-card-account .lottery-card-account--header li.active svg * {
            stroke: #E25F2C; }
          .lottery-card-account .lottery-card-account--header li.active svg path {
            fill: #E25F2C; }
  .lottery-card-account .account-funds {
    padding: 0 2.1875rem; }
    .lottery-card-account .account-funds .choose-plays h4 {
      text-align: left;
      margin-bottom: 0.625rem;
      color: #4C4D4A;
      padding-left: 0.625rem;
      font-size: 1.125em; }
    .lottery-card-account .account-funds .choose-plays .choose-plays-container {
      margin: 0; }
      .lottery-card-account .account-funds .choose-plays .choose-plays-container label {
        flex: 0 0 24%;
        max-width: 24%;
        margin: 0 0 0.9375rem; }
        .lottery-card-account .account-funds .choose-plays .choose-plays-container label.input-full {
          flex: 0 0 100%;
          max-width: 100%; }
    .lottery-card-account .account-funds input[type="text"] {
      width: 100% !important; }
    .lottery-card-account .account-funds .account-nav-funds-actions {
      display: flex;
      align-items: center;
      margin: 0 0 0.9375rem; }
      .lottery-card-account .account-funds .account-nav-funds-actions > * {
        margin: 0 !important; }
      .lottery-card-account .account-funds .account-nav-funds-actions .select-dropdown {
        flex: 2; }
  .lottery-card-account .account-nav-funds--footer {
    padding: 1.5625rem 2.1875rem 0;
    border-top: 1px solid #7A7B78;
    margin-top: 1.5625rem;
    text-align: left; }
    .lottery-card-account .account-nav-funds--footer h3 {
      color: #4C4D4A;
      text-align: left;
      justify-content: flex-start !important;
      cursor: pointer;
      display: inline-flex !important;
      transition: all ease-in-out 0.2s;
      margin-bottom: 0 !important; }
      .lottery-card-account .account-nav-funds--footer h3 span {
        border: 3px solid #4C4D4A;
        border-radius: 50%;
        width: 1.5625rem;
        height: 1.5625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.625rem;
        font-size: 0.75em;
        transition: border-color ease-in-out 0.2s; }
      .lottery-card-account .account-nav-funds--footer h3:hover, .lottery-card-account .account-nav-funds--footer h3.active {
        color: #E25F2C; }
        .lottery-card-account .account-nav-funds--footer h3:hover span, .lottery-card-account .account-nav-funds--footer h3.active span {
          border-color: #E25F2C; }
    .lottery-card-account .account-nav-funds--footer .tab-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .lottery-card-account .account-nav-funds--footer .tab-content.active {
        margin-top: 0.9375rem; }
      .lottery-card-account .account-nav-funds--footer .tab-content p {
        color: #7A7B78;
        margin: 0; }
        .lottery-card-account .account-nav-funds--footer .tab-content p span {
          font-weight: 600; }

/* Lottery Slider */
.flickity-enabled.is-draggable .flickity-viewport {
  transition: height ease-in-out 0.3s; }

.lottery-slider {
  background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  padding: 1.5625rem 0 3.125rem; }
  .lottery-slider > .tab-nav {
    margin-bottom: 1.25rem;
    width: 100%; }
  .lottery-slider .tab-nav {
    padding: 0; }
    .lottery-slider .tab-nav > li {
      font-size: 0.8125em !important;
      font-weight: 700 !important; }
  .lottery-slider .lottery-deck {
    padding: 0 1.5%; }
  .lottery-slider .lotery-slider--nav {
    border-bottom: 1px solid white;
    display: flex;
    align-items: center;
    margin-bottom: 2.1875rem; }
    .lottery-slider .lotery-slider--nav p {
      text-align: left;
      color: white;
      line-height: 1.3;
      margin: 0;
      letter-spacing: 0.0625rem;
      display: inline-block;
      flex: 0 0 20%;
      position: relative;
      top: -3px;
      font-weight: 800;
      margin-right: 0.625rem; }
  .lottery-slider .container .lottery-slider--slider {
    max-width: 17.5rem;
    margin: 0 auto;
    position: relative; }
    .lottery-slider .container .lottery-slider--slider.mobile-slider {
      display: block;
      max-width: 20.625rem;
      margin: 0 auto;
      padding: 0 0.125rem; }
    .lottery-slider .container .lottery-slider--slider .lottery-slider--slide {
      opacity: 0; }
    .lottery-slider .container .lottery-slider--slider:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 99;
      background-image: url(images/spinner.gif);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50px; }
    .lottery-slider .container .lottery-slider--slider.ready:before {
      display: none; }
    .lottery-slider .container .lottery-slider--slider.ready .lottery-slider--slide {
      opacity: 1; }
    .lottery-slider .container .lottery-slider--slider.deck-slider {
      max-width: 100%; }
      .lottery-slider .container .lottery-slider--slider.deck-slider .lottery-deck--card {
        width: 48%;
        margin-bottom: 1%; }
    .lottery-slider .container .lottery-slider--slider .lottery-slider--slide {
      margin: 0.1875rem 1%;
      text-align: center;
      width: 280px;
      min-width: 0 !important;
      flex: 0 0 auto !important;
      font-size: 16px;
      display: flex;
      flex-direction: column; }
      .lottery-slider .container .lottery-slider--slider .lottery-slider--slide .read-more {
        margin-top: auto; }
      .lottery-slider .container .lottery-slider--slider .lottery-slider--slide .lottery-number {
        max-width: 100%; }
      .lottery-slider .container .lottery-slider--slider .lottery-slider--slide .flipper {
        width: 100%; }
        .lottery-slider .container .lottery-slider--slider .lottery-slider--slide .flipper .lottery-card-front .button {
          margin-top: 1rem; }
        .lottery-slider .container .lottery-slider--slider .lottery-slider--slide .flipper .lottery-card--amount {
          font-size: 2em; }
      .lottery-slider .container .lottery-slider--slider .lottery-slider--slide .lottery-deck {
        padding: 0 0.3125rem; }
  .lottery-slider .container .slider-read-more {
    color: white;
    text-decoration: underline;
    font-size: 1em;
    font-weight: 500;
    margin-top: 1.25rem;
    display: none; }
  .lottery-slider .mobile-slide {
    width: 100%; }
    .lottery-slider .mobile-slide .lottery-slider--slide {
      display: block;
      width: 100% !important;
      margin: 0 auto 0.625rem !important; }
      .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front {
        display: flex;
        text-align: left;
        position: relative; }
        .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front img {
          margin: 0 0.9375rem 0 0; }
          .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front img.cta {
            margin: 0;
            background-color: #2C68A5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            width: 1.25rem;
            height: 1.25rem;
            padding: 0.25rem; }
        .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front .lottery-card--content {
          margin: 0; }
          .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front .lottery-card--content h2 {
            font-size: 1.125em; }
          .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front .lottery-card--content p {
            text-align: left;
            display: block;
            margin: 0; }
            .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front .lottery-card--content p.timer {
              margin-top: 0.3125rem; }
              .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front .lottery-card--content p.timer span {
                font-size: 1em; }
        .lottery-slider .mobile-slide .lottery-slider--slide .lottery-card-front p.lottery-card--amount {
          font-size: 1.375em !important; }

.flickity-page-dots {
  position: static;
  margin-top: 1.875rem; }
  .flickity-page-dots .dot {
    background-color: transparent;
    border: 2px solid white;
    opacity: 1;
    transition: all ease-in-out 0.2s;
    margin: 0 0.3125rem;
    width: 0.9375rem;
    height: 0.9375rem; }
    .flickity-page-dots .dot.is-selected {
      background-color: white; }
    .flickity-page-dots .dot:only-child {
      display: none; }

@media only screen and (max-width: 620px) {
  .lottery-slider .container .lottery-slider--slider.desktop-slider {
    display: none; } }

@media only screen and (min-width: 620px) {
  .lottery-slider .container .lottery-slider--slider {
    max-width: calc(560px + 2%);
    display: flex; }
    .lottery-slider .container .lottery-slider--slider.ready {
      display: block; }
  .lottery-slider .container .slider-read-more {
    display: block; }
  .lottery-slider .mobile-slider {
    display: none !important; } }

@media only screen and (min-width: 760px) {
  .lottery-slider .lotery-slider--nav > .tab-nav, .lottery-slider > .tab-nav, .lottery-play--nav > .container .lotery-slider--nav > .tab-nav, .lottery-play--nav > .container > .tab-nav {
    width: 100%;
    font-size: 1.25em;
    justify-content: center; }
    .lottery-slider .lotery-slider--nav > .tab-nav > li, .lottery-slider > .tab-nav > li, .lottery-play--nav > .container .lotery-slider--nav > .tab-nav > li, .lottery-play--nav > .container > .tab-nav > li {
      opacity: 0.7;
      font-size: 1em !important; }
      .lottery-slider .lotery-slider--nav > .tab-nav > li br, .lottery-slider > .tab-nav > li br, .lottery-play--nav > .container .lotery-slider--nav > .tab-nav > li br, .lottery-play--nav > .container > .tab-nav > li br {
        display: none; }
      .lottery-slider .lotery-slider--nav > .tab-nav > li.active, .lottery-slider .lotery-slider--nav > .tab-nav > li:hover, .lottery-slider > .tab-nav > li.active, .lottery-slider > .tab-nav > li:hover, .lottery-play--nav > .container .lotery-slider--nav > .tab-nav > li.active, .lottery-play--nav > .container .lotery-slider--nav > .tab-nav > li:hover, .lottery-play--nav > .container > .tab-nav > li.active, .lottery-play--nav > .container > .tab-nav > li:hover {
        opacity: 1; } }

@media only screen and (min-width: 860px) {
  .lottery-slider .lottery-deck {
    justify-content: space-between;
    padding: 0 !important; }
    .lottery-slider .lottery-deck .lottery-deck--card {
      width: 23% !important;
      flex: 0 0 23%; } }

@media only screen and (min-width: 980px) {
  .lottery-slider .container .lottery-slider--slider {
    max-width: calc(860px + 3%); }
    .lottery-slider .container .lottery-slider--slider.inactive-3 {
      display: flex;
      justify-content: space-between; }
      .lottery-slider .container .lottery-slider--slider.inactive-3 .flickity-viewport {
        height: auto !important;
        width: 100% !important;
        cursor: initial; }
        .lottery-slider .container .lottery-slider--slider.inactive-3 .flickity-viewport .flickity-slider {
          position: static !important;
          transform: none !important;
          display: flex;
          justify-content: center; }
      .lottery-slider .container .lottery-slider--slider.inactive-3 .lottery-slider--slide {
        margin: 0;
        position: static !important;
        /*flex: 0 0 24%;*/
        /*width: 24%;*/
        max-width: auto;
        margin: 0 0.75%; }
      .lottery-slider .container .lottery-slider--slider.inactive-3 .flickity-page-dots {
        display: none; } }

@media only screen and (min-width: 1280px) {
  .lottery-slider .container .lottery-slider--slider {
    max-width: 100%; }
    .lottery-slider .container .lottery-slider--slider .lottery-slider--slide {
      margin: 0 0.625rem;
      max-width: 350px; }
    .lottery-slider .container .lottery-slider--slider.inactive {
      display: flex;
      justify-content: space-between; }
      .lottery-slider .container .lottery-slider--slider.inactive .flickity-viewport {
        height: auto !important;
        width: 100% !important;
        cursor: initial; }
        .lottery-slider .container .lottery-slider--slider.inactive .flickity-viewport .flickity-slider {
          position: static !important;
          transform: none !important;
          display: flex;
          justify-content: center; }
      .lottery-slider .container .lottery-slider--slider.inactive .lottery-slider--slide {
        margin: 0;
        position: static !important;
        /*flex: 0 0 24%;*/
        /*width: 24%;*/
        max-width: auto;
        margin: 0 0.75%; }
      .lottery-slider .container .lottery-slider--slider.inactive .flickity-page-dots {
        display: none; }
  .lottery-slider .slider-read-more {
    margin-top: 3.125rem !important; } }

/* Lottery CTA */
.lottery-cta {
  background: #4C4D4A;
  padding-bottom: 3.125rem;
  overflow: hidden;
  display: block; }
  .lottery-cta .button {
    width: 12.5rem; }
  .lottery-cta .lottery-cta--image {
    height: 70vw;
    max-height: 25rem;
    position: relative;
    background-size: auto 130%; }
    .lottery-cta .lottery-cta--image:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(to top, #4C4D4A 0, transparent 100%); }
  .lottery-cta .lottery-card {
    padding: 1.875rem; }
    .lottery-cta .lottery-card .lottery-flag {
      width: 5.125rem;
      height: auto; }
  .lottery-cta .lottery-cta--countdown {
    margin-top: 1.25rem;
    margin-bottom: 1.875rem; }
    .lottery-cta .lottery-cta--countdown p {
      color: white;
      margin: 0 0 0.625rem;
      letter-spacing: 0.03125rem;
      font-size: 1.25em;
      font-weight: 700; }

@media only screen and (min-width: 980px) {
  .lottery-cta {
    position: relative;
    padding: 3.125rem 0; }
    .lottery-cta .lottery-card {
      min-width: 23.75rem; }
    .lottery-cta .lottery-cta--image {
      width: 100%;
      height: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .lottery-cta .container {
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 9; }
      .lottery-cta .container .lottery-card.lottery-card-offset {
        margin: 0;
        top: 0; }
      .lottery-cta .container .lottery-cta--countdown {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 100%; }
        .lottery-cta .container .lottery-cta--countdown p {
          line-height: 1;
          margin: 0; }
        .lottery-cta .container .lottery-cta--countdown .timer {
          margin-top: 0.625rem; }
          .lottery-cta .container .lottery-cta--countdown .timer svg {
            max-height: 1.875rem; }
    .lottery-cta.xlarge-banner {
      padding: 12.5rem 0 25rem; } }

/* Lottery General Sections */
.lottery-about {
  background: linear-gradient(to bottom, #464D50 0%, #4C4D4A 100%);
  padding: 3.125rem 0; }
  .lottery-about * {
    color: white; }
  .lottery-about .container .lottery-about--logo {
    max-width: 10.9375rem;
    margin-bottom: 0.625rem; }
  .lottery-about .container .title {
    font-size: 1.125em; }
  .lottery-about .container p {
    letter-spacing: 0.0625rem;
    max-width: 37.5rem;
    margin: 0.9375rem auto 2.5rem; }
  .lottery-about .container .lottery-about--items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1.875rem 0 0; }
    .lottery-about .container .lottery-about--items .lottery-about--item {
      flex: 0 0 47%;
      margin-bottom: 1.875rem; }
      .lottery-about .container .lottery-about--items .lottery-about--item:nth-of-type(3n + 3) {
        flex: 0 0 100%;
        justify-content: center; }
      .lottery-about .container .lottery-about--items .lottery-about--item .icon-container {
        width: 3.4375rem;
        height: 3.4375rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        margin: 0 auto 0.625rem; }
        .lottery-about .container .lottery-about--items .lottery-about--item .icon-container svg {
          width: auto;
          height: 3.125rem !important; }
      .lottery-about .container .lottery-about--items .lottery-about--item h3 {
        color: #E25F2C;
        font-size: 1.125em;
        font-weight: 700;
        margin: 0; }
      .lottery-about .container .lottery-about--items .lottery-about--item p {
        margin: 0.625rem 0 0;
        font-size: 0.875em;
        line-height: 1.5;
        font-family: "open-sans";
        letter-spacing: 0.03125rem; }
  .lottery-about .container .read-more {
    max-width: 80%;
    font-weight: 500; }
  .lottery-about.video-container {
    background: linear-gradient(to top, #464D50 0%, #4C4D4A 100%);
    width: 100%;
    padding: 0; }
    .lottery-about.video-container .video-controls {
      transform: translate(-50%, -50%); }

@media only screen and (min-width: 780px) {
  .lottery-about .container .lottery-about--items {
    flex-wrap: nowrap; }
    .lottery-about .container .lottery-about--items .lottery-about--item {
      flex: 1;
      max-width: 18%; } }

@media only screen and (min-width: 1180px) {
  .lottery-about .container .lottery-about--items {
    justify-content: space-between; }
    .lottery-about .container .lottery-about--items .lottery-about--item {
      display: flex;
      text-align: left;
      max-width: 18%;
      flex: 0 0 auto; }
      .lottery-about .container .lottery-about--items .lottery-about--item .icon-container {
        margin-right: 1.25rem;
        width: 2.8125rem;
        flex: 0 0 2.8125rem; }
        .lottery-about .container .lottery-about--items .lottery-about--item .icon-container svg {
          height: 2.5rem !important; } }

@media only screen and (min-width: 1270px) {
  .lottery-about.video-container {
    padding: 2.5rem 0; } }

.lottery-general {
  padding: 1.875rem 0 0.625rem;
  font-size: 18px; }
  .lottery-general .container-small {
    max-width: 47.5rem; }
  .lottery-general h1 {
    color: #E25F2C;
    font-size: 1.875em;
    padding-bottom: 1.25rem; }
  .lottery-general p, .lottery-general li {
    font-size: 0.875em;
    color: #4C4D4A;
    font-family: "open-sans";
    line-height: 1.45; }
    .lottery-general p:first-child, .lottery-general li:first-child {
      margin-top: 0; }
  .lottery-general.align-left {
    text-align: left; }
    .lottery-general.align-left h1 {
      text-align: center;
      margin: 0; }
    .lottery-general.align-left h3 {
      margin-bottom: 0;
      margin-top: 1.25rem; }
    .lottery-general.align-left h3, .lottery-general.align-left p, .lottery-general.align-left li {
      color: #7A7B78;
      font-size: 0.75em; }

@media only screen and (min-width: 768px) {
  .lottery-general {
    padding-bottom: 1.5625rem; }
    .lottery-general h1 {
      text-align: left !important;
      font-size: 2.25em; }
      .lottery-general h1:first-child {
        margin-bottom: 1.5625rem !important; }
    .lottery-general h3 {
      font-size: 1.125em !important; }
    .lottery-general p, .lottery-general li {
      font-size: 1em !important; }
  .bg-blue-gradient .lottery-card {
    max-width: 67.5rem; } }

.lottery-blurb {
  background: linear-gradient(to top left, #4C4D4A 0%, #464D50 100%);
  padding: 1.875rem 0; }
  .lottery-blurb .container {
    max-width: 35.625rem; }
  .lottery-blurb * {
    color: white; }
  .lottery-blurb p {
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.125em;
    letter-spacing: 0; }

/* Lottery Game */
.lottery-game {
  position: relative;
  padding-bottom: 2.1875rem; }
  .lottery-game .tab-nav {
    justify-content: space-around; }
    .lottery-game .tab-nav li {
      font-size: 0.9375em; }
  .lottery-game .tab-toggle {
    margin-bottom: 1.25rem; }
  .lottery-game .lottery-date-search {
    margin-bottom: 0.625rem; }

.play-game--right {
  margin: 1.875rem auto 0; }

.game-numbers--container {
  max-width: 17.5rem;
  margin: 0 auto; }

.lottery-number {
  font-family: "proxima-soft" !important; }

.add-game-favourites {
  margin-top: 1.25rem; }
  .add-game-favourites.disabled {
    display: none; }

.lottery-game--play-now {
  max-width: 53.75rem;
  margin: 0 auto; }
  .lottery-game--play-now .play-now--header {
    margin-top: 1.5625rem; }
    .lottery-game--play-now .play-now--header h3 {
      color: white;
      font-size: 1.125em;
      margin: 0 0 1.5625rem; }
  .lottery-game--play-now .play-now--game .container .play-game--numbers {
    max-width: 25rem;
    margin: 0 auto; }
  .lottery-game--play-now h5 {
    color: #E25F2C;
    font-size: 1.125em;
    font-weight: 700;
    margin: 0.625rem 0 0;
    letter-spacing: 0;
    line-height: 1.3; }

.lottery-game--why-play h2 {
  color: white;
  margin-bottom: 2.8125rem; }

.lottery-game--why-play .why-play--header {
  display: flex;
  justify-content: space-between;
  padding: 0 0 3.125rem; }
  .lottery-game--why-play .why-play--header .lottery-contain-four {
    flex: 0 0 49%;
    margin: 0;
    padding: 0; }
  .lottery-game--why-play .why-play--header .video {
    border-radius: 0.625rem;
    overflow: hidden;
    flex: 0 0 49%;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); }
    .lottery-game--why-play .why-play--header .video iframe {
      width: 100%;
      height: 100%; }

.lottery-game--why-play .why-play--content {
  background: white;
  padding: 4.0625rem 0;
  text-align: left; }
  .lottery-game--why-play .why-play--content .container * {
    max-width: 36.25rem;
    margin-left: auto;
    margin-right: auto; }
  .lottery-game--why-play .why-play--content .container .why-play--cta {
    display: flex;
    max-width: 63.75rem;
    margin: 2.5rem auto; }
    .lottery-game--why-play .why-play--content .container .why-play--cta * {
      max-width: 100%;
      min-width: 0; }
    .lottery-game--why-play .why-play--content .container .why-play--cta .section-image {
      border-radius: 0.625rem;
      flex: 0 1 65%;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: flex-end;
      padding: 1.875rem;
      position: relative;
      margin-right: 1.25rem;
      overflow: hidden; }
      .lottery-game--why-play .why-play--content .container .why-play--cta .section-image * {
        z-index: 1; }
      .lottery-game--why-play .why-play--content .container .why-play--cta .section-image:before {
        content: '';
        background: rgba(0, 0, 0, 0.3);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 0; }
      .lottery-game--why-play .why-play--content .container .why-play--cta .section-image p {
        color: white;
        font-size: 1.625em;
        margin: 0;
        line-height: 1.3;
        max-width: 22.5rem;
        font-weight: 400; }
    .lottery-game--why-play .why-play--content .container .why-play--cta .lottery-card {
      flex: 1;
      min-width: 17.5rem; }
  .lottery-game--why-play .why-play--content .container .why-play--testimonial {
    border-bottom: 1px solid #2C68A5;
    padding-bottom: 10px;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center; }
    .lottery-game--why-play .why-play--content .container .why-play--testimonial svg {
      margin-right: 1.875rem;
      flex: 0 0 2.1875rem; }
    .lottery-game--why-play .why-play--content .container .why-play--testimonial p {
      font-size: 1.375em;
      color: #4C4D4A; }
  .lottery-game--why-play .why-play--content .container h3 {
    color: #E25F2C;
    line-height: 1.5;
    font-size: 1.75em;
    margin-top: 0; }
  .lottery-game--why-play .why-play--content .container p {
    font-size: 1.125em; }
  .lottery-game--why-play .why-play--content .container blockquote {
    color: #E25F2C;
    line-height: 1.4;
    font-size: 1.375em;
    padding: 1.25rem 3.125rem; }

.lottery-card .play-now--game input[type="submit"] {
  margin: 0.9375rem auto 0;
  font-weight: 700; }

.lottery-card .play-now--game:before {
  display: none; }

.play-now--game {
  background: white; }
  .play-now--game .play-game {
    padding: 0 0.625rem 2.5rem; }
    .play-now--game .play-game > .container > h2 {
      color: white; }
    .play-now--game .play-game .play-game--numbers {
      padding: 2.5rem 0 0; }

.game-number-lines {
  margin-top: 1.25rem;
  overflow: hidden; }
  .game-number-lines p {
    font-weight: 700;
    margin: 0 0 0.625rem !important;
    font-size: 0.875em !important; }
    .game-number-lines p.game-count {
      color: #2C68A5;
      text-align: center !important; }
  .game-number-lines .game-number-line {
    transition: all ease-in-out 0.3s;
    opacity: 0;
    position: relative;
    left: -100%; }
    .game-number-lines .game-number-line .number-line--numbers {
      position: relative;
      display: inline-block;
      padding-right: 2.5rem; }
      .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column; }
        .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span {
          border: 2px solid #D8262D;
          border-radius: 50%;
          margin-bottom: 0.46875rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.25rem;
          height: 1.25rem;
          color: #D8262D;
          font-weight: 700;
          font-size: 0.625em;
          cursor: pointer; }
          .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span svg {
            width: 0.5625rem; }
            .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span svg * {
              fill: #2C68A5; }
          .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite {
            border-color: #79B409;
            color: #79B409; }
            .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite svg * {
              stroke: #79B409;
              fill: transparent;
              stroke-width: 3.5px; }
            .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite.active {
              background-color: #79B409; }
              .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite.active svg * {
                stroke: white;
                fill: white; }
          .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-edit {
            border-color: #2C68A5; }
          .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span:last-of-type {
            margin-bottom: 0; }
        .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions .lottery-close svg * {
          fill: #D8262D;
          transition: all ease-in-out .2s; }
    .game-number-lines .game-number-line .lottery-numbers {
      justify-content: flex-start;
      align-items: center; }
      .game-number-lines .game-number-line .lottery-numbers .lottery-number {
        font-size: 0.9375em; }
    .game-number-lines .game-number-line.added {
      overflow: visible;
      opacity: 1;
      margin-bottom: 1.875rem;
      left: 0; }
    .game-number-lines .game-number-line.remove {
      max-height: 0;
      right: 500px; }
  .game-number-lines.no-favourite .lottery-favourite {
    display: none !important; }
  .game-number-lines.no-favourite .custom-numbers--title {
    padding: 0 0.9375rem; }
  .game-number-lines.no-favourite .game-number-line {
    margin-bottom: 0.625rem; }
  .game-number-lines.number-lines-minimised .game-number-line:nth-of-type(n + 4) {
    display: none; }
  .game-number-lines.number-lines-minimised + .number-lines-expand::after {
    transform: rotate(0); }

.number-lines-expand {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  float: left;
  text-decoration: underline; }
  .number-lines-expand:after {
    content: '';
    background-image: url(images/icon-angle-down.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2C68A5;
    border-radius: 50%;
    height: 0.9375rem;
    width: 0.9375rem;
    display: block;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
    margin-left: 0.625rem;
    margin-top: 0.15625rem;
    transform: rotate(180deg); }
  .number-lines-expand:hover {
    opacity: 0.7; }

.choose-numbers {
  min-width: 75%;
  padding: 0.78125rem 1.875rem;
  letter-spacing: 0;
  font-weight: 700; }

.draw-multipliers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.25rem 0.625rem 1.25rem 1.5625rem !important; }
  .draw-multipliers label {
    flex: 0 0 33.33%;
    display: flex;
    align-items: center;
    margin: 0.9375rem 0; }
    .draw-multipliers label:last-of-type {
      margin: 0.9375rem 0; }

.custom-numbers .custom-numbers--title {
  position: relative;
  text-align: center !important; }

.custom-numbers .quick-select, .custom-numbers .remove-line {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid purple;
  color: purple;
  border-radius: 50%;
  font-size: 0.75em;
  cursor: pointer;
  transition: all ease-in-out 0.2s; }
  .custom-numbers .quick-select svg, .custom-numbers .remove-line svg {
    width: 0.625rem; }
    .custom-numbers .quick-select svg path, .custom-numbers .remove-line svg path {
      transition: all ease-in-out 0.2s; }
  .custom-numbers .quick-select *, .custom-numbers .remove-line * {
    stroke: purple;
    fill: purple; }

.custom-numbers .remove-line {
  right: 0;
  left: auto;
  border-color: #D8262D; }
  .custom-numbers .remove-line svg {
    width: 0.4375rem; }
  .custom-numbers .remove-line * {
    stroke: #D8262D;
    fill: #D8262D; }

.custom-numbers .lottery-number {
  color: white;
  font-size: 1em;
  font-style: italic;
  text-indent: -2px;
  font-weight: 800;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

.custom-numbers .custom-numbers--selected {
  display: flex;
  margin: 0.9375rem 0; }
  .custom-numbers .custom-numbers--selected .custom-selected {
    border-radius: 50%;
    width: calc((100% - (2*6%)) / 7);
    padding-bottom: calc((100% - (2*6%)) / 7);
    height: 0;
    min-height: 2.1875rem;
    position: relative;
    margin: 0 0.3125rem 0 0 !important; }
    .custom-numbers .custom-numbers--selected .custom-selected .lottery-number {
      border: 3px solid #E25F2C;
      position: absolute !important;
      width: 100%;
      height: 100%;
      transition: all ease-in-out 0.1s;
      font-size: 0;
      background: transparent; }
      .custom-numbers .custom-numbers--selected .custom-selected .lottery-number.selected {
        background: #E25F2C; }
    .custom-numbers .custom-numbers--selected .custom-selected.selected {
      background: #E25F2C; }
      .custom-numbers .custom-numbers--selected .custom-selected.selected .lottery-number {
        opacity: 1;
        font-size: 1em; }
    .custom-numbers .custom-numbers--selected .custom-selected:last-of-type {
      margin-right: 0 !important; }

.custom-numbers .custom-numbers--selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0.9375rem;
  user-select: none; }
  .custom-numbers .custom-numbers--selection .custom-selection {
    width: calc((100% - (2*7%)) / 8);
    padding-bottom: calc((100% - (2*7%)) / 8);
    position: relative;
    min-height: 1.875rem;
    margin: 0 0.3125rem 0.3125rem 0 !important;
    border: none !important;
    outline: none; }
    .custom-numbers .custom-numbers--selection .custom-selection .lottery-number {
      position: absolute;
      background: #CBCBCB;
      width: 100%;
      height: 100%; }
    .custom-numbers .custom-numbers--selection .custom-selection.selected .lottery-number {
      background: #E25F2C; }
    .custom-numbers .custom-numbers--selection .custom-selection:nth-of-type(8n + 8) {
      margin-right: 0 !important; }

.custom-numbers .custom-numbers--action {
  display: flex;
  justify-content: space-between;
  margin: 1.875rem 0 1.25rem; }
  .custom-numbers .custom-numbers--action .button {
    font-size: 0.875em;
    padding: 0.90625rem 1.25rem;
    line-height: 1;
    letter-spacing: 0;
    font-weight: 700;
    flex: 0 0 60%;
    margin: 0 auto; }

.custom-numbers.custom-numbers--lucky .custom-numbers--selected .custom-selected .lottery-number {
  border-color: #38A4D0; }

.custom-numbers.custom-numbers--lucky .custom-numbers--selected .custom-selected.selected {
  background: #38A4D0; }

.custom-numbers.custom-numbers--lucky .custom-numbers--selection .custom-selection.selected .lottery-number {
  background: #38A4D0; }

.custom-numbers.hidden {
  display: none; }

.add-game {
  width: 14.0625rem;
  margin: 1.25rem auto 0 !important;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0.75rem 1.25rem 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 1; }
  .add-game svg {
    position: absolute;
    left: 0.625rem;
    top: 50%;
    transform: translateY(-50%); }
    .add-game svg circle {
      stroke: #2C68A5; }
  .add-game.disabled {
    opacity: 0.5; }

.play-game--draws {
  max-width: 15.625rem;
  margin: 1.875rem auto; }
  .play-game--draws p {
    letter-spacing: 0;
    margin-top: 0.625rem; }
  .play-game--draws .input-range {
    margin-top: 3.125rem; }

.play-game--footer .game-footer--title {
  color: #E25F2C;
  font-size: 1.375em;
  font-weight: 700;
  margin: 0 0 0.9375rem; }

.play-game--footer .play-game--dates {
  color: #E25F2C;
  font-weight: 700;
  margin: 0 0 1.5625rem;
  font-size: 1em; }

.play-game--footer input[type="submit"], .play-game--footer button {
  width: 80%;
  border-radius: 1.25rem;
  padding: 0.9375rem;
  margin: 0 auto;
  font-weight: 700;
  font-family: "proxima-soft";
  letter-spacing: 0;
  color: white;
  background-image: linear-gradient(to right, #F5993C 0%, #F76B1C 51%, #F5993C 100%);
  border: none;
  background-size: 150% auto;
  text-align: center;
  display: block; }
  .play-game--footer input[type="submit"]:hover, .play-game--footer button:hover {
    background-position: right center; }

.play-game--legend {
  display: flex;
  margin: 1.25rem auto 0;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 0.625rem 0.9375rem;
  max-width: 95vw;
  flex-wrap: wrap; }
  .play-game--legend p {
    display: flex;
    align-items: center;
    flex: 0 0 45%;
    color: #2C68A5;
    text-align: left;
    margin: 0.3125rem 0; }
    .play-game--legend p svg {
      height: 1.25rem;
      flex: 0 0 1.875rem;
      width: 100%;
      margin-right: 0.625rem; }
    .play-game--legend p.quick svg * {
      stroke: purple; }
    .play-game--legend p.favourite svg * {
      stroke: #79B409; }
    .play-game--legend p.edit svg * {
      fill: #2C68A5; }
    .play-game--legend p.remove svg * {
      stroke: #D8262D; }

@media only screen and (min-width: 740px) {
  .play-game--legend p {
    flex: 0 0 23%;
    justify-content: center; } }

.lottery-game--lottery-results {
  max-width: 26.25rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .lottery-game--lottery-results .results {
    flex: 0 0 75% !important; }
  .lottery-game--lottery-results .lottery-results--nav {
    margin-bottom: 1.875rem; }
    .lottery-game--lottery-results .lottery-results--nav * {
      color: white; }
    .lottery-game--lottery-results .lottery-results--nav p {
      margin: 0 0 0.9375rem;
      font-weight: 700; }
    .lottery-game--lottery-results .lottery-results--nav .results-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 72.5%;
      margin: 0 auto;
      font-size: 14px; }
      .lottery-game--lottery-results .lottery-results--nav .results-nav p {
        padding: 0 1.25rem;
        margin-bottom: 0;
        flex: 1 1 100%; }
      .lottery-game--lottery-results .lottery-results--nav .results-nav a {
        line-height: 1.3;
        text-align: center;
        display: block;
        position: relative;
        flex: 1 0 auto; }
        .lottery-game--lottery-results .lottery-results--nav .results-nav a:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-size: 50%;
          background-position: center;
          background-repeat: no-repeat;
          width: 0.9375rem;
          height: 0.9375rem;
          background-color: white;
          border-radius: 50%;
          line-height: 1;
          font-weight: 900;
          font-size: 0.75em; }
        .lottery-game--lottery-results .lottery-results--nav .results-nav a.result-prev:before {
          background-image: url(images/icon-angle-left-blue.svg);
          right: calc(100% + 0.9375rem); }
        .lottery-game--lottery-results .lottery-results--nav .results-nav a.result-next:before {
          background-image: url(images/icon-angle-right-blue.svg);
          left: calc(100% + 0.9375rem); }
        .lottery-game--lottery-results .lottery-results--nav .results-nav a.disabled {
          opacity: 0.3 !important;
          cursor: default; }
  .lottery-game--lottery-results .lottery-card--results {
    padding: 1.25rem 1.875rem;
    max-width: 100% !important; }
    .lottery-game--lottery-results .lottery-card--results .lottery-numbers-container {
      margin-bottom: 1.25rem; }
    .lottery-game--lottery-results .lottery-card--results h3 {
      color: #2C68A5;
      margin: 0 0 0.9375rem;
      font-size: 1em; }
    .lottery-game--lottery-results .lottery-card--results .results-expand + .results-content {
      opacity: 0;
      visibility: hidden;
      transition: opacity ease-in-out 0.2s; }
    .lottery-game--lottery-results .lottery-card--results .results-expand.active + .results-content {
      opacity: 1;
      visibility: visible;
      overflow: visible !important; }

.divisions-breakdown .lottery-numbers {
  margin: 0 auto; }

.divisions-breakdown .lottery-number {
  margin-left: 0 !important; }

.lottery-game--how-to-play:before {
  display: none; }

.lottery-game--how-to-play .how-to-play--information {
  padding: 1.25rem 0; }
  .lottery-game--how-to-play .how-to-play--information .lottery-card {
    padding: 1.5625rem 1.25rem; }
    .lottery-game--how-to-play .how-to-play--information .lottery-card h3 {
      margin-bottom: 1.875rem;
      font-size: 1em;
      font-weight: 600;
      font-family: "open-sans"; }
    .lottery-game--how-to-play .how-to-play--information .lottery-card p {
      text-align: left;
      font-size: 0.875em;
      margin: 0 0 0.3125rem;
      font-family: "open-sans";
      display: flex;
      justify-content: space-between; }
      .lottery-game--how-to-play .how-to-play--information .lottery-card p span {
        font-weight: 700;
        flex: 0 0 42.5%;
        margin-left: 2.5%; }

.lottery-game--how-to-play .how-to-play--divisions {
  background: white;
  padding: 2.5rem 0; }
  .lottery-game--how-to-play .how-to-play--divisions .container {
    width: 80%; }
  .lottery-game--how-to-play .how-to-play--divisions .lottery-flag {
    width: 2.8125rem;
    height: auto;
    margin-bottom: 0.9375rem; }
  .lottery-game--how-to-play .how-to-play--divisions h2 {
    color: #E25F2C;
    font-size: 1.125em;
    font-weight: 700;
    font-family: "open-sans";
    margin: 0 0 1.5625rem; }
  .lottery-game--how-to-play .how-to-play--divisions h3 {
    color: #E25F2C;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.3;
    font-family: "open-sans";
    margin: 0 0 0.46875rem; }
  .lottery-game--how-to-play .how-to-play--divisions p {
    font-size: 1em;
    font-family: "open-sans";
    color: #7A7B78;
    margin: 0; }
  .lottery-game--how-to-play .how-to-play--divisions .divisions-breakdown {
    margin-top: 2.5rem; }
    .lottery-game--how-to-play .how-to-play--divisions .divisions-breakdown .lottery-number {
      margin-left: 0 !important; }

.lottery-game--how-to-play .how-to-play--descriptions {
  background: white; }
  .lottery-game--how-to-play .how-to-play--descriptions .container {
    width: 80%; }
  .lottery-game--how-to-play .how-to-play--descriptions .how-to-description {
    border-top: 1px solid #E6E6E6;
    padding: 2.03125rem 0 1.25rem; }
    .lottery-game--how-to-play .how-to-play--descriptions .how-to-description h2 {
      margin: 0 0 1.875rem;
      color: #E25F2C;
      font-size: 1em;
      font-family: "open-sans";
      font-weight: 600;
      letter-spacing: 0.3px; }
    .lottery-game--how-to-play .how-to-play--descriptions .how-to-description p {
      text-align: left;
      color: #2C68A5;
      font-size: 0.875em;
      font-weight: 400;
      letter-spacing: 0.3px; }

.lottery-game--how-to-play .how-to-play--footer {
  padding: 1.25rem 0 0; }

.choose-plays {
  padding: 1.5625rem 0 0; }
  .choose-plays .button {
    transform: none !important;
    transition: none !important; }
  .choose-plays h4 {
    font-size: 1em;
    letter-spacing: 0;
    line-height: 1.3;
    margin: 0; }
  .choose-plays .choose-plays-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.5625rem; }
    .choose-plays .choose-plays-container label {
      flex: 0 0 48.5%;
      max-width: 48.5%;
      cursor: pointer;
      padding: 0.8125rem 0.9375rem;
      letter-spacing: 0.03125rem;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      margin-bottom: 0.5rem;
      overflow: hidden;
      font-weight: 700; }
      .choose-plays .choose-plays-container label.input-full {
        padding: 0;
        display: none;
        flex: 0 0 100%; }
        .choose-plays .choose-plays-container label.input-full.active {
          display: block; }
    .choose-plays .choose-plays-container .input-blue {
      flex: 0 0 100% !important;
      max-width: 100%;
      padding: 0; }
      .choose-plays .choose-plays-container .input-blue input {
        margin: 0;
        font-weight: 700;
        text-align: center;
        width: 100%; }
    .choose-plays .choose-plays-container input[type="radio"] {
      display: none; }
      .choose-plays .choose-plays-container input[type="radio"]:checked + label {
        color: white;
        background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
        border-color: transparent; }
      @media only screen and (min-width: 760px) {
        .choose-plays .choose-plays-container input[type="radio"] + label:hover {
          color: white;
          background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
          border-color: transparent; } }
  .choose-plays.border-bottom:after {
    content: '';
    width: 100%;
    height: 0.625rem;
    background: linear-gradient(to top, #36A8F5 0%, #36A8F6 100%);
    display: block;
    margin-top: 1.375rem; }

.divisions-breakdown {
  max-width: 20rem;
  margin: 0 auto; }
  .divisions-breakdown.active {
    overflow: visible; }
  .divisions-breakdown .divisions-container {
    text-align: center;
    margin-bottom: 4.6875rem !important; }
    .divisions-breakdown .divisions-container h4 {
      display: block;
      width: 100%;
      margin: 0 0 0.9375rem;
      color: #2C68A5;
      font-size: 1em;
      font-family: "open-sans";
      font-weight: 700; }
    .divisions-breakdown .divisions-container p {
      font-size: 1em;
      color: #7A7B78;
      font-family: "open-sans";
      font-weight: 400;
      margin-bottom: 0.9375rem !important; }
    .divisions-breakdown .divisions-container .lottery-numbers {
      margin-top: 0.3125rem !important; }

.total-cost {
  display: inline-block;
  color: #E25F2C;
  font-weight: 700;
  font-size: 1.875em;
  padding: 1.25rem;
  border-radius: 0.625rem;
  line-height: 1;
  margin-bottom: 1.875rem;
  border: 0.125rem solid #E25F2C;
  user-select: none; }

@media only screen and (max-width: 760px) {
  .lottery-game--why-play .why-play--header {
    flex-direction: column; }
    .lottery-game--why-play .why-play--header .video {
      margin-top: 1.25rem;
      height: 0;
      padding-bottom: 56.25%;
      position: relative; }
      .lottery-game--why-play .why-play--header .video iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; }
  .lottery-game--why-play .why-play--content .container .why-play--cta {
    flex-direction: column; }
    .lottery-game--why-play .why-play--content .container .why-play--cta .section-image {
      flex: 0 0 100%;
      width: 100%;
      padding-top: 30%;
      margin-bottom: 1.875rem; } }

@media only screen and (min-width: 760px) {
  .play-now--game {
    max-width: 53.75rem;
    margin: 0 auto; }
    .play-now--game:before {
      display: none; }
    .play-now--game .choose-plays {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 1.875rem;
      margin: 0 auto;
      text-align: center; }
      .play-now--game .choose-plays .choose-plays-container {
        max-width: 28.75rem;
        margin: 1.5625rem auto 0; }
    .play-now--game .play-game {
      padding: 3.125rem 0; }
      .play-now--game .play-game .container {
        align-items: flex-start;
        width: 100%;
        max-width: 25rem; }
        .play-now--game .play-game .container > * {
          flex: 1;
          padding-top: 0;
          margin-top: 0;
          width: 100%; }
        .play-now--game .play-game .container .play-game--numbers {
          top: 6.875rem;
          left: 0px;
          margin: 0 auto;
          max-width: 100%; }
        .play-now--game .play-game .container .play-game--right {
          top: 6.875rem;
          right: 0px;
          padding: 0 5%;
          margin: 1.875rem auto 0; }
  .lottery-game--lottery-results {
    width: 92.5%;
    max-width: 73.75rem;
    margin: 0 auto; }
    .lottery-game--lottery-results.active {
      display: flex !important;
      flex-wrap: wrap; }
    .lottery-game--lottery-results .lottery-results--nav {
      margin: 1.875rem 0 0.9375rem;
      width: 92.5%; }
      .lottery-game--lottery-results .lottery-results--nav .container {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center; }
        .lottery-game--lottery-results .lottery-results--nav .container p {
          margin: 0 auto; }
        .lottery-game--lottery-results .lottery-results--nav .container .results-nav {
          justify-content: flex-end;
          align-items: center;
          flex: 0 0 100%;
          margin: 0;
          padding: 0 1.875rem 0; }
          .lottery-game--lottery-results .lottery-results--nav .container .results-nav a {
            opacity: 0.8; }
            .lottery-game--lottery-results .lottery-results--nav .container .results-nav a:last-child {
              margin-right: 0; }
          .lottery-game--lottery-results .lottery-results--nav .container .results-nav br {
            display: none; }
    .lottery-game--lottery-results .results {
      flex: 0 0 75% !important; }
      .lottery-game--lottery-results .results .container {
        width: 100%; }
    .lottery-game--lottery-results > .container {
      flex: 1;
      min-width: 45%;
      width: 100% !important;
      max-width: 100% !important; }
      .lottery-game--lottery-results > .container .lottery-card {
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
    .lottery-game--lottery-results .lottery-date-search {
      flex: 1 0 100%;
      width: 100%;
      order: 2;
      max-width: 35rem;
      margin: 1.5625rem 0 0; }
    .lottery-game--lottery-results .lottery-card--results {
      max-width: 90% !important; } }

@media only screen and (min-width: 860px) {
  .lottery-game .play-now--game {
    border-radius: 0.625rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); } }

@media only screen and (min-width: 980px) {
  .add-game svg path {
    transition: all ease-in-out 0.2s; }
  .add-game svg circle {
    transition: all ease-in-out 0.2s; }
  .add-game:hover svg path {
    fill: white; }
  .add-game:hover svg circle {
    stroke: white; }
  .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span {
    transition: all ease-in-out 0.2s; }
    .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span:hover {
      color: white; }
      .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span:hover path {
        color: white;
        stroke: white; }
    .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-edit * {
      transition: all ease-in-out 0.2s; }
    .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-edit:hover {
      background-color: #2C68A5; }
    .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite:hover, .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite.active {
      background-color: #79B409; }
      .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite:hover svg *, .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-favourite.active svg * {
        stroke: white;
        fill: white; }
    .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-close:hover {
      background-color: #D8262D; }
      .game-number-lines .game-number-line .number-line--numbers .lottery-line-actions > span.lottery-close:hover path {
        fill: white;
        stroke: transparent; }
  .custom-numbers .quick-select {
    transition: all ease-in-out 0.15s;
    cursor: pointer; }
    .custom-numbers .quick-select:hover {
      background-color: purple; }
      .custom-numbers .quick-select:hover * {
        stroke: white;
        fill: white; }
  .custom-numbers .remove-line:hover {
    background-color: #D8262D; }
    .custom-numbers .remove-line:hover * {
      stroke: white;
      fill: white; }
  .lottery-game--how-to-play .how-to-play--main {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 92.5%;
    max-width: 73.75rem;
    margin: 0 auto;
    padding: 0.9375rem 0 2.5rem; }
    .lottery-game--how-to-play .how-to-play--main .how-to-play--information, .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions {
      flex: 0 0 49%;
      max-width: 49%;
      display: flex; }
      .lottery-game--how-to-play .how-to-play--main .how-to-play--information > .container, .lottery-game--how-to-play .how-to-play--main .how-to-play--information .lottery-card, .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions > .container, .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions .lottery-card {
        width: 100%;
        flex: 1;
        max-width: 100% !important; }
      .lottery-game--how-to-play .how-to-play--main .how-to-play--information > .container, .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions > .container {
        display: flex;
        flex-direction: column; }
      .lottery-game--how-to-play .how-to-play--main .how-to-play--information .lottery-card, .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions .lottery-card {
        height: 100%; }
        .lottery-game--how-to-play .how-to-play--main .how-to-play--information .lottery-card h3, .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions .lottery-card h3 {
          font-size: 1.125em;
          font-weight: 700; }
    .lottery-game--how-to-play .how-to-play--main .how-to-play--information {
      padding: 0; }
    .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions {
      border-radius: 0.625rem;
      padding: 0.9375rem 1.5625rem 2.5rem; }
      .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions .how-to-play--divisions-title {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.9375rem; }
        .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions .how-to-play--divisions-title h2 {
          margin: 0; }
        .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions .how-to-play--divisions-title img {
          flex: 0 0 auto;
          margin: 0 0 0 0.9375rem; }
      .lottery-game--how-to-play .how-to-play--main .how-to-play--divisions h3 {
        margin-bottom: 0; }
  .lottery-game--how-to-play .how-to-play--descriptions .container-desktop {
    display: flex;
    justify-content: space-between;
    text-align: left; }
  .lottery-game--how-to-play .how-to-play--descriptions .how-to-description {
    flex: 0 0 45%; }
    .lottery-game--how-to-play .how-to-play--descriptions .how-to-description .container {
      max-width: 100%;
      width: 100%; }
    .lottery-game--how-to-play .how-to-play--descriptions .how-to-description h2 {
      margin-bottom: 0.625rem;
      font-size: 1.125em; }
  .lottery-game--how-to-play .how-to-play--footer {
    padding-top: 3.125rem; }
  .divisions-breakdown {
    display: flex !important;
    align-items: flex-end;
    width: 90%;
    max-width: 100%;
    justify-content: space-between; }
    .divisions-breakdown .divisions-container {
      flex: 0 0 47.5%;
      min-width: 47.5%;
      margin-bottom: 0 !important; }
    .divisions-breakdown .input-range {
      width: 40%;
      display: flex;
      margin-bottom: 0.3125rem; }
  .custom-selection, .custom-selected.selected {
    cursor: pointer;
    transition: all ease-in-out 0.15s; }
    .custom-selection:hover, .custom-selected.selected:hover {
      opacity: 0.5; }
  .lottery-game--lottery-results .lottery-results--nav .container .results-nav {
    font-size: 16px; }
    .lottery-game--lottery-results .lottery-results--nav .container .results-nav a:hover {
      opacity: 1; } }

/*	My Account	*/
.banner.banner-account {
  position: relative;
  padding: 0 0 2vw; }
  .banner.banner-account .button {
    transform: none !important; }
  .banner.banner-account > .container {
    width: 90%;
    max-width: 30rem;
    padding: 3.75rem 0 1.875rem;
    text-align: center; }
  .banner.banner-account:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: red;
    z-index: 0;
    background: linear-gradient(to bottom right, #F76B1C 0%, #F9AC49 100%);
    opacity: 0.85;
    background-blend-mode: overlay; }
  .banner.banner-account * {
    z-index: 1;
    position: relative; }
  .banner.banner-account img {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    border: 3px solid white;
    margin-bottom: 0.9375rem; }
  .banner.banner-account h1 {
    font-weight: 700;
    margin-bottom: 1.25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1; }
    .banner.banner-account h1 span {
      margin-left: 0.9375rem; }
    .banner.banner-account h1.verified:after {
      font-size: 40%;
      background-color: white;
      border-color: white; }
  .banner.banner-account p {
    font-family: "proxima-soft";
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 0.3px; }
  .banner.banner-account .account-unverified {
    margin: -0.3125rem auto;
    text-decoration: underline;
    font-weight: 400;
    display: block;
    max-width: 6.25rem; }
  .banner.banner-account .icon-round {
    border-radius: 50%;
    border: 2px solid white;
    width: 2rem;
    flex: 0 0 2rem;
    height: 2rem;
    position: relative;
    display: inline-block;
    display: flex; }
    .banner.banner-account .icon-round svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      overflow: visible; }
    .banner.banner-account .icon-round.active {
      background: white; }
      .banner.banner-account .icon-round.active svg * {
        fill: #E25F2C; }
  .banner.banner-account #account-notifications {
    position: absolute;
    top: 1.0625rem;
    right: 0; }
    .banner.banner-account #account-notifications svg {
      width: 1.125rem; }
  .banner.banner-account #account-edit svg {
    width: 0.9375rem; }
  .banner.banner-account .balance {
    line-height: 1;
    margin: 1.5625rem 0 2.1875rem; }
    .banner.banner-account .balance > span {
      display: flex;
      justify-content: center;
      font-size: 145%;
      margin-top: 0.9375rem;
      align-items: center; }
      .banner.banner-account .balance > span .icon-round {
        margin-left: 0.9375rem;
        width: 1.625rem;
        height: 1.625rem;
        flex: 0 0 1.625rem; }
        .banner.banner-account .balance > span .icon-round svg {
          height: 0.78125rem; }
  .banner.banner-account .account-balance {
    display: flex;
    max-height: 0;
    transition: all ease-in-out 0.5s;
    visibility: visible;
    opacity: 1;
    height: auto; }
    .banner.banner-account .account-balance > p {
      text-align: center;
      flex: 1;
      margin: 0 0.78125rem 0.9375rem 0;
      font-size: 1.125em;
      position: relative;
      opacity: 0;
      transition: all ease-in-out 0.5s 0.2s; }
      .banner.banner-account .account-balance > p:first-of-type {
        right: 150px; }
      .banner.banner-account .account-balance > p:last-of-type {
        left: 150px; }
      .banner.banner-account .account-balance > p span {
        display: block;
        font-size: 125%; }
      .banner.banner-account .account-balance > p:last-of-type {
        margin-right: 0; }
    .banner.banner-account .account-balance.active {
      max-height: 500px; }
      .banner.banner-account .account-balance.active > p {
        opacity: 1; }
        .banner.banner-account .account-balance.active > p:first-of-type {
          right: 0; }
        .banner.banner-account .account-balance.active > p:last-of-type {
          left: 0; }
  .banner.banner-account .account-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .banner.banner-account .account-actions > a {
      flex: 1;
      width: 0;
      margin-right: 0.78125rem;
      font-size: 1em;
      padding: 0.78125rem 0 0.84375rem; }
      .banner.banner-account .account-actions > a:last-of-type {
        margin-right: 0; }
      .banner.banner-account .account-actions > a.active + p {
        display: block; }
    .banner.banner-account .account-actions .button {
      margin-top: 1.5625rem;
      min-width: 0; }
    .banner.banner-account .account-actions > p {
      flex: 0 0 100%;
      margin-bottom: 0;
      display: none; }
      .banner.banner-account .account-actions > p span {
        display: block;
        font-size: 125%; }

.account--action-tab .container {
  max-width: 53.75rem; }

.account--action-tab .lottery-card-account {
  padding: 1.5625rem 1.25rem !important; }
  .account--action-tab .lottery-card-account:last-of-type {
    margin-bottom: 0; }

.account--action-tab.active {
  padding: 1.25rem 0; }

.lottery-card.lottery-card-account {
  padding: 1.5625rem 1.25rem;
  margin-bottom: 0.9375rem; }
  .lottery-card.lottery-card-account h3 {
    font-size: 1em;
    font-weight: 600;
    font-family: "open-sans";
    margin: 0 0 1.5625rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .lottery-card.lottery-card-account h3 span {
      display: flex;
      align-items: center; }
    .lottery-card.lottery-card-account h3 svg {
      margin-left: 0.9375rem;
      cursor: pointer; }
      .lottery-card.lottery-card-account h3 svg * {
        fill: #E25F2C; }
  .lottery-card.lottery-card-account h4 {
    font-size: 1.25em;
    color: #2C68A5;
    margin: 0 0 0.625rem; }
  .lottery-card.lottery-card-account p {
    font-size: 0.875em;
    text-align: left;
    margin: 0 0 0.3125rem; }
  .lottery-card.lottery-card-account form h3 {
    margin: 1.875rem 0 1.5625rem;
    display: inline-flex;
    position: relative; }
  .lottery-card.lottery-card-account form input[type="submit"] {
    margin: 0.625rem 0 0; }
  .lottery-card.lottery-card-account > .payment-detail {
    margin-bottom: 0; }
  .lottery-card.lottery-card-account:last-of-type {
    margin-bottom: 0; }

.account-favourites .favourites-container {
  max-width: 48.75rem;
  margin: 0 auto;
  padding-top: 1.25rem; }
  .account-favourites .favourites-container .lottery-card-favourite {
    overflow: visible;
    max-height: none; }
    .account-favourites .favourites-container .lottery-card-favourite .play-now--game {
      max-width: 25rem;
      width: 100%;
      margin: 0 auto; }

.account-favourites .results-content .select-dropdown ul {
  overflow: auto; }

.select-add-lottery {
  max-width: 22.5rem;
  margin: 0 auto; }

/*	Payment Details */
.payment-detail {
  color: #2C68A5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em !important;
  font-weight: 400;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  margin-right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  padding-right: 0 !important; }
  .payment-detail img {
    width: 1.875rem;
    height: auto; }
  .payment-detail .remove-payment {
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #D8262D;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.15s;
    cursor: pointer; }
    .payment-detail .remove-payment svg {
      width: 75%;
      height: 75%;
      fill: #D8262D;
      transition: all ease-in-out 0.15s; }
    .payment-detail .remove-payment:hover {
      background-color: #D8262D; }
      .payment-detail .remove-payment:hover svg {
        fill: white; }

.payment-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .payment-details .payment-detail {
    margin: 0;
    width: 95%;
    padding: 0.3125rem 5%;
    border-top: 1px solid #2C68A5;
    justify-content: space-between; }
    .payment-details .payment-detail:first-of-type {
      border-top: none;
      padding-top: 0;
      margin-top: 0; }
    .payment-details .payment-detail:last-of-type {
      margin-bottom: 0; }

/*	Set Limit 	*/
#account--spending-edit.active {
  margin-top: 1.5625rem; }

#account--notifications .lottery-card {
  max-width: 100%;
  padding: 1.5625rem 2.5rem; }
  #account--notifications .lottery-card p {
    font-size: 1em; }

/*	Account Funds 	*/
.account-funds {
  background: white; }
  .account-funds.active {
    margin-bottom: -3.125rem; }
  .account-funds h4 {
    font-weight: 600; }
  .account-funds .container {
    max-width: 53.75rem; }
  .account-funds .account-funds--top {
    border-bottom: 1px solid #E5E5E5;
    padding: 1.5625rem 0; }
    .account-funds .account-funds--top h2 {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600; }
      .account-funds .account-funds--top h2 svg {
        margin-right: 0.9375rem; }
    .account-funds .account-funds--top .account-amount {
      display: block;
      line-height: 1.5;
      font-size: 1.25em;
      color: #2C68A5;
      margin: 0.9375rem 0; }
      .account-funds .account-funds--top .account-amount span {
        display: block;
        font-size: 90%; }
    .account-funds .account-funds--top p {
      color: #4C4D4A;
      margin: 0.9375rem 0 2.5rem;
      font-weight: 600; }
    .account-funds .account-funds--top .container {
      max-width: 30rem; }
    .account-funds .account-funds--top .choose-plays {
      padding-top: 0; }
  .account-funds .account-funds--bottom {
    padding: 2.1875rem 0 0.9375rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .account-funds .account-funds--bottom .container {
      background-color: white; }
    .account-funds .account-funds--bottom h3 {
      margin: 0 0 1.5625rem;
      text-align: center; }
    .account-funds .account-funds--bottom *[type="submit"] {
      margin: 0.9375rem 0; }
    .account-funds .account-funds--bottom .input-row input {
      width: 100%; }
    .account-funds .account-funds--bottom .container {
      max-width: 40rem; }
    .account-funds .account-funds--bottom .select-rendered:after {
      background-image: url(images/icon-angle-down.svg); }

.verified, .unverified {
  position: relative; }
  .verified:after, .unverified:after {
    content: '\2714';
    margin-left: 0.9375rem;
    color: #79B409;
    border: 2px solid #79B409;
    width: 1.25rem;
    height: 1.25rem;
    overflow: visible;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%); }

.unverified:after {
  cursor: pointer;
  content: 'i';
  color: #D8262D;
  border-color: #D8262D; }

.verify {
  text-align: center;
  margin: 0 auto;
  width: 80%;
  font-size: 12px; }
  .verify * {
    text-align: center !important; }
  .verify a {
    color: #E25F2C;
    font-weight: 700; }
  .verify.active {
    margin-bottom: 1.5625rem; }

/*Account Results*/
.account-draws .tab-toggle {
  margin-top: 1.5625rem;
  max-width: 32.5rem; }

.account-draws .account-results--left {
  margin-bottom: 0.3125rem; }

.account-draws .draws-sort {
  max-width: 18.75rem;
  margin: 0 auto 1.5625rem;
  display: flex;
  align-items: flex-start; }
  .account-draws .draws-sort p {
    color: white;
    flex: 0 0 auto;
    margin: 0.625rem 0.625rem 0.625rem 0; }
  .account-draws .draws-sort > * {
    flex: 1; }

.account-draws .lottery-card .lottery-card--draw {
  font-size: 1em; }

.account--edit .container .account-edit--lower #reset-password {
  margin-top: 0.9375rem; }
  .account--edit .container .account-edit--lower #reset-password .lottery-card {
    max-width: 100%; }

@media only screen and (min-width: 560px) {
  .account--edit .container .account-edit--lower #reset-password .lottery-card {
    max-width: 100%;
    width: 32.5rem; } }

@media only screen and (min-width: 760px) {
  #account--landing .tab-nav li {
    font-size: 1em;
    font-weight: 600; }
    #account--landing .tab-nav li br {
      display: none; } }

@media only screen and (min-width: 860px) {
  .account-funds .account-funds--top .choose-plays {
    padding: 1.25rem 0 0;
    text-align: center;
    width: 100%; }
    .account-funds .account-funds--top .choose-plays .choose-plays-container {
      max-width: 22.5rem;
      margin: 0 auto; }
      .account-funds .account-funds--top .choose-plays .choose-plays-container > * {
        margin-right: 0; }
        .account-funds .account-funds--top .choose-plays .choose-plays-container > *:first-child {
          margin-left: 0; }
      .account-funds .account-funds--top .choose-plays .choose-plays-container .input-other {
        margin: 0.625rem 0 0;
        width: 100%; }
  .account-funds .account-funds--bottom {
    padding: 2.8125rem 0;
    background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%); }
    .account-funds .account-funds--bottom .select-left {
      max-width: calc(100% - 15.625rem); }
    .account-funds .account-funds--bottom .container {
      position: relative;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
      padding: 1.25rem;
      border-radius: 0.625rem; }
      .account-funds .account-funds--bottom .container > label {
        width: 13.75rem;
        position: absolute;
        right: 1.25rem;
        top: 46px;
        margin: 0;
        text-align: center;
        display: block; }
        .account-funds .account-funds--bottom .container > label .button {
          margin-top: 0;
          width: 100%; }
    .account-funds .account-funds--bottom h3 {
      text-align: left;
      margin: 0 0 0.625rem; }
    .account-funds .account-funds--bottom .tab-content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: calc(100% - 15.625rem); }
      .account-funds .account-funds--bottom .tab-content > * {
        flex: 0 0 100%; }
      .account-funds .account-funds--bottom .tab-content.active {
        margin-top: 0.78125rem; }
      .account-funds .account-funds--bottom .tab-content .terms-conditions-check {
        flex: 0 0 100%;
        text-align: left;
        justify-content: center;
        margin: 0.625rem 0; }
        .account-funds .account-funds--bottom .tab-content .terms-conditions-check span {
          line-height: 1.5; }
        .account-funds .account-funds--bottom .tab-content .terms-conditions-check:only-child {
          margin-top: 0; } }

@media only screen and (min-width: 980px) {
  .banner.banner-account .icon-round {
    cursor: pointer;
    transition: all ease-in-out 0.2s; }
    .banner.banner-account .icon-round:hover {
      background: white; }
      .banner.banner-account .icon-round:hover i {
        color: #E25F2C; }
      .banner.banner-account .icon-round:hover svg * {
        stroke-color: #E25F2C;
        fill: #E25F2C; }
  #account--edit .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 57.5rem; }
    #account--edit .container .lottery-card {
      min-width: 0;
      min-height: 100%;
      max-width: 32%;
      height: 15.625rem;
      display: flex;
      flex-direction: column; }
      #account--edit .container .lottery-card .button {
        margin-top: auto;
        flex: 0 0 auto;
        align-self: center; }
    #account--edit .container > div {
      flex: 0 0 32%;
      margin: 0 2% 1.25rem 0;
      min-width: 0;
      max-width: 100%;
      overflow: visible;
      max-height: none; }
      #account--edit .container > div:nth-child(3n + 3) {
        margin-right: 0; }
  .account-draws .lottery-card, .account-results .lottery-card {
    min-width: 32.5rem; } }

.account-draws p.subtitle {
  font-size: 1.125em;
  font-weight: 700;
  margin: 0 0 1.25rem;
  line-height: 1;
  color: white;
  letter-spacing: 0.3px; }

.account-draws .account-draws--next {
  margin-top: 0.625rem; }
  .account-draws .account-draws--next .lottery-card--draw-next {
    padding: 1.875rem 0.9375rem 1.5625rem;
    margin-bottom: 0.625rem; }
    .account-draws .account-draws--next .lottery-card--draw-next .draw-card--header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.625rem; }
      .account-draws .account-draws--next .lottery-card--draw-next .draw-card--header h2 {
        font-size: 1.125em;
        margin: 0; }
      .account-draws .account-draws--next .lottery-card--draw-next .draw-card--header .lottery-flag {
        margin: 0 0.625rem 0 0;
        width: 1.8125rem;
        height: 1.8125rem; }
    .account-draws .account-draws--next .lottery-card--draw-next .lottery-card--amount {
      font-size: 1.875em;
      line-height: 1.3; }
    .account-draws .account-draws--next .lottery-card--draw-next .lottery-card--draw {
      color: #E25F2C;
      font-weight: 700;
      font-size: 1em;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 1.5625rem; }
      .account-draws .account-draws--next .lottery-card--draw-next .lottery-card--draw > * {
        flex: 0 0 45%;
        width: 0; }
  .account-draws .account-draws--next .lottery-card .svg-container svg {
    margin-bottom: 0.9375rem;
    width: 6.25rem;
    height: auto; }
  .account-draws .account-draws--next .results-expand {
    margin: 0;
    justify-content: center; }
  .account-draws .account-draws--next:last-child {
    margin-bottom: 0; }

.account-draws .account-draws--upcoming {
  margin-top: 1.5625rem; }
  .account-draws .account-draws--upcoming .lottery-card {
    margin-bottom: 0.3125rem; }

.account-draws .results-expand {
  justify-content: flex-start;
  margin-top: -0.625rem;
  line-height: 1; }
  .account-draws .results-expand:after {
    display: none; }

.account-draws .results-content {
  margin-top: 0;
  text-align: center; }
  .account-draws .results-content > p {
    font-size: 1em !important;
    font-weight: 700;
    margin: 0 0 0.9375rem;
    line-height: 1; }
    .account-draws .results-content > p.game-type {
      font-size: 1.25em !important; }
  .account-draws .results-content .lottery-numbers-container {
    max-width: 22.5rem;
    margin: 0 auto 1.25rem; }
    .account-draws .results-content .lottery-numbers-container .lottery-numbers .lottery-number {
      font-size: initial; }
      .account-draws .results-content .lottery-numbers-container .lottery-numbers .lottery-number > span {
        font-size: initial; }
        .account-draws .results-content .lottery-numbers-container .lottery-numbers .lottery-number > span > span {
          font-size: 0.875em; }
    .account-draws .results-content .lottery-numbers-container:last-of-type {
      margin-bottom: 0; }

.load-more {
  max-width: 11.5625rem;
  line-height: 1;
  margin: 1.875rem auto 0;
  font-size: 0.875em;
  font-weight: 800; }

.account-history .history-print-title {
  display: none; }

.account-history.active {
  margin-top: 1.25rem;
  margin-bottom: -3.1875rem; }

.account-history .tab-nav {
  background-color: #F8F8F8;
  padding-top: 0.625rem; }
  .account-history .tab-nav li {
    color: #2C68A5;
    font-size: 0.875em !important; }
    .account-history .tab-nav li.active {
      border-color: #2C68A5; }

.account-history .tabs-container {
  padding: 1.5625rem 0;
  background: white;
  max-width: 73.75rem;
  margin: 0 auto; }
  .account-history .tabs-container .container {
    width: 100%; }

.account-history .history-row-titles {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.9375rem !important; }
  .account-history .history-row-titles * {
    font-weight: 700 !important; }

.account-history .history-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  margin-bottom: 1.25rem;
  padding: 0 0.9375rem;
  position: relative; }
  .account-history .history-row .lottery-flag {
    width: 1.5625rem;
    height: auto;
    position: absolute;
    top: 50%;
    left: 1.5625rem;
    transform: translateY(-50%); }
  .account-history .history-row p, .account-history .history-row a {
    margin: 0 0.9375rem 0 0;
    font-size: 0.75em;
    line-height: 1.5;
    font-weight: 600;
    font-family: "open-sans";
    flex: 1; }
    .account-history .history-row p.date, .account-history .history-row a.date {
      color: #2C68A5;
      flex: 0 0 3.75rem; }
    .account-history .history-row p.lottery, .account-history .history-row a.lottery {
      color: #2C68A5;
      flex: 1 0 5rem; }
      .account-history .history-row p.lottery span, .account-history .history-row a.lottery span {
        color: #7A7B78; }
    .account-history .history-row p.cost, .account-history .history-row a.cost {
      color: #E25F2C;
      flex: 2; }
    .account-history .history-row p.balance, .account-history .history-row a.balance {
      margin-right: 0 !important;
      flex: 2; }
    .account-history .history-row p span, .account-history .history-row a span {
      margin-left: 0.3125rem; }

.account-history .buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.875rem 0 3.125rem !important;
  width: 100%;
  max-width: 100%; }

.account-history .button {
  margin-bottom: 3.125rem;
  display: block !important;
  margin: 0 0.3125rem !important;
  padding: 0.9375rem 1.875rem;
  max-height: 50px; }
  .account-history .button.print {
    margin-bottom: 0 !important;
    background: white;
    color: #2C68A5;
    display: flex !important;
    flex: 0 0 9.375rem;
    max-width: 150px; }

@media only screen and (max-width: 480px) {
  .account-history .history-row a, .account-history .history-row p {
    font-size: 0.5625em; } }

@media only screen and (min-width: 480px) {
  .account-history .history-row p.date, .account-history .history-row a.date {
    flex: 0 0 6.25rem; }
  .account-history .history-row p.lottery, .account-history .history-row a.lottery {
    flex: 5; } }

@media only screen and (min-width: 760px) {
  .account-history .tabs-container .history-row {
    padding: 0 4.6875rem; }
    .account-history .tabs-container .history-row p.date, .account-history .tabs-container .history-row a.date {
      flex: 2; }
    .account-history .tabs-container .history-row p.cost, .account-history .tabs-container .history-row a.cost {
      flex: 1; }
    .account-history .tabs-container .history-row p.balance, .account-history .tabs-container .history-row a.balance {
      flex: 1; }
    .account-history .tabs-container .history-row.history-row-titles {
      font-size: 24px; } }

@media only screen and (min-width: 980px) {
  .account-history .tabs-container {
    border-radius: 0.625rem;
    margin-top: 2.5rem;
    width: 92.5%; } }

/*	Secondary Pages */
.how-it-works {
  padding-bottom: 1.25rem; }
  .how-it-works h2 {
    color: white;
    font-weight: 400;
    margin: 0 0 1.5625rem; }
  .how-it-works .lottery-deck {
    margin-top: 1.875rem; }
    .how-it-works .lottery-deck .lottery-card.lottery-card--works {
      width: 100%;
      flex: 0 0 100%; }

@media only screen and (min-width: 560px) {
  .how-it-works .lottery-deck .lottery-card.lottery-card--works {
    width: 48%;
    flex: 0 0 48%; } }

@media only screen and (min-width: 860px) {
  .how-it-works .lottery-deck .lottery-card.lottery-card--works {
    max-width: 24%;
    flex: 0 1 24%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0.9375rem; } }

@media only screen and (min-width: 980px) {
  .how-it-works .flickity-viewport {
    height: auto !important;
    width: 100% !important;
    cursor: initial; }
    .how-it-works .flickity-viewport .flickity-slider {
      position: static !important;
      transform: none !important;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
  .how-it-works .flickity-page-dots {
    display: none; }
  .how-it-works .lottery-deck {
    position: static !important;
    padding: 0;
    flex: 0 0 100%; }
    .how-it-works .lottery-deck:first-of-type {
      margin-bottom: 0.625rem; } }

.contact-section {
  padding-top: 0 !important; }
  .contact-section .lottery-card {
    max-width: 22.5rem;
    width: 100% !important;
    padding: 1.5625rem 0.9375rem; }
    .contact-section .lottery-card .button {
      display: inline-flex; }
  .contact-section .bg-blue-gradient {
    padding: 2.5rem 0; }
    .contact-section .bg-blue-gradient .lottery-card {
      min-width: 100%; }
  .contact-section .contact-form {
    margin-top: 1.875rem; }
    .contact-section .contact-form h3 {
      font-size: 1.25em;
      margin-bottom: 1.5625rem; }
  .contact-section form {
    max-width: 22.5rem;
    margin: 0 auto; }

@media only screen and (min-width: 980px) {
  .contact-section {
    margin: 0 auto;
    background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%); }
    .contact-section .container {
      width: 100%;
      max-width: 100%; }
    .contact-section .contact-container {
      display: inline-flex;
      width: 92.5%;
      max-width: 73.75rem;
      justify-content: space-between;
      padding: 3.125rem 0; }
      .contact-section .contact-container .container {
        height: 100%; }
      .contact-section .contact-container .bg-blue-gradient {
        background: transparent;
        padding: 0;
        display: flex;
        align-items: stretch;
        flex-direction: column; }
        .contact-section .contact-container .bg-blue-gradient .container {
          flex: 1;
          display: flex;
          flex-direction: column; }
          .contact-section .contact-container .bg-blue-gradient .container .lottery-card {
            flex: 1; }
      .contact-section .contact-container .lottery-card {
        padding: 3.125rem 2.5rem;
        text-align: left;
        height: 100%;
        margin: 0; }
        .contact-section .contact-container .lottery-card h3 {
          font-size: 1.875em;
          margin: 0 0 1.5625rem; }
        .contact-section .contact-container .lottery-card p {
          font-size: 1em; }
        .contact-section .contact-container .lottery-card .button {
          padding: 0 !important;
          text-align: left; }
          .contact-section .contact-container .lottery-card .button:hover {
            background: white;
            color: #E25F2C;
            opacity: 0.8; }
      .contact-section .contact-container .contact-form {
        background: white;
        border-radius: 0.625rem;
        flex: 1;
        margin-left: 3%;
        padding: 3.125rem 2.5rem;
        text-align: left;
        margin-top: 0;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); }
        .contact-section .contact-container .contact-form form {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          max-width: 100%; }
          .contact-section .contact-container .contact-form form h3 {
            flex: 0 0 100%;
            font-size: 1.875em;
            margin: 0 0 1.875rem;
            font-weight: 700; }
          .contact-section .contact-container .contact-form form div {
            flex: 0 0 40%; }
            .contact-section .contact-container .contact-form form div textarea {
              flex: 1;
              overflow: auto;
              margin-bottom: 0; }
            .contact-section .contact-container .contact-form form div + div {
              flex: 0 0 55%;
              display: flex;
              flex-direction: column; }
            .contact-section .contact-container .contact-form form div label {
              display: flex;
              flex-direction: column;
              flex: 1; }
              .contact-section .contact-container .contact-form form div label:last-of-type {
                margin-bottom: 0; }
                .contact-section .contact-container .contact-form form div label:last-of-type input {
                  margin-bottom: 0; }
                .contact-section .contact-container .contact-form form div label:last-of-type .input-error {
                  margin-top: 0.46875rem; }
            .contact-section .contact-container .contact-form form div .input-error {
              flex: 0 0 auto !important; }
          .contact-section .contact-container .contact-form form .input-orange-solid {
            text-align: right; }
          .contact-section .contact-container .contact-form form input[type="submit"] {
            flex: 0 0 auto;
            margin-left: auto;
            width: 30%;
            margin-top: 1.5625rem; } }

.section-site-map {
  padding: 0;
  margin-bottom: 3.125rem;
  display: block;
  position: relative; }
  .section-site-map h1 {
    color: white;
    font-size: 1.0625em;
    font-weight: 600;
    width: 92.5%;
    max-width: 73.75rem;
    margin: 0 auto;
    padding: 0 0 3.125rem; }
    .section-site-map h1:after {
      content: '';
      width: 100vw;
      height: 1px;
      background: white;
      display: block;
      position: absolute;
      left: 0;
      right: auto;
      margin-top: 0.9375rem; }
  .section-site-map .lottery-card {
    max-width: 22.5rem;
    text-align: left; }
    .section-site-map .lottery-card .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
    .section-site-map .lottery-card .nav-listing {
      flex: 0 0 100%;
      margin-bottom: 2.5rem; }
    .section-site-map .lottery-card h3 {
      font-size: 1.375em;
      position: relative;
      padding-bottom: 0.625rem;
      margin-bottom: 0.9375rem; }
      .section-site-map .lottery-card h3:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #979797;
        display: block; }
    .section-site-map .lottery-card ul {
      margin: 0;
      padding: 0; }
      .section-site-map .lottery-card ul li {
        list-style-type: none; }
        .section-site-map .lottery-card ul li a {
          font-size: 1em;
          color: #4C4D4A;
          font-weight: 400; }
        .section-site-map .lottery-card ul li .sub-menu {
          margin: 0 0 0.625rem 1.25rem; }
          .section-site-map .lottery-card ul li .sub-menu li a {
            color: #7A7B78;
            opacity: 0.7; }
    .section-site-map .lottery-card .list-type-alternate li:nth-of-type(even) {
      margin-left: 0.75rem; }

.page-site-map .lottery-footer-cards {
  padding-top: 1.25rem; }

@media only screen and (min-width: 680px) {
  .section-site-map .lottery-card {
    max-width: 100%; }
    .section-site-map .lottery-card h3:after {
      width: 100%; }
    .section-site-map .lottery-card .nav-listing {
      flex: 0 0 45%; } }

@media only screen and (min-width: 860px) {
  .section-site-map h1 {
    text-align: left;
    font-size: 1.875em;
    padding: 0 0 3.125rem; }
    .section-site-map h1:after {
      margin-top: 1.5625rem; }
  .section-site-map .container {
    padding-top: 1.25rem; } }

@media only screen and (min-width: 980px) {
  .section-site-map .lottery-card {
    width: 100%; }
    .section-site-map .lottery-card .nav-listing {
      flex: 0 0 28%; }
    .section-site-map .lottery-card ul li a:hover {
      color: #E25F2C !important;
      opacity: 1 !important; } }

@media only screen and (min-width: 1125px) {
  .section-site-map .lottery-card {
    width: 100%; }
    .section-site-map .lottery-card .nav-listing {
      flex: 0 0 28%; }
      .section-site-map .lottery-card .nav-listing h3 {
        font-size: 1.75em; }
      .section-site-map .lottery-card .nav-listing ul li a {
        font-size: 1.125em; } }

/*	Confirmations	*/
.lottery-confirmations .lottery-cta--image {
  max-height: 75vw;
  background-size: cover; }

.lottery-confirmations .lottery-card {
  padding: 1.875rem 1.25rem;
  max-width: 35rem;
  width: auto; }
  .lottery-confirmations .lottery-card a {
    text-decoration: none; }
  .lottery-confirmations .lottery-card h1 {
    color: #E25F2C;
    font-size: 1.875em;
    margin: 0 0 0.9375rem;
    font-weight: 600;
    line-height: 1.3; }
  .lottery-confirmations .lottery-card p {
    font-size: 1.125em;
    margin-bottom: 1.25rem;
    font-family: "open-sans"; }
  .lottery-confirmations .lottery-card .buttons-container {
    margin-top: 1.25rem; }
  .lottery-confirmations .lottery-card .button {
    width: 12.5rem;
    justify-content: center;
    margin: 0.625rem auto 0;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    display: block;
    flex: 1; }
  .lottery-confirmations .lottery-card .print {
    width: auto;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    margin: 0 auto 0.625rem !important;
    max-width: 12.5rem; }

.bpay-container {
  text-align: left;
  border: 1.5px solid #183168;
  padding: 0.9375rem;
  margin: 1.25rem 0 0.625rem; }
  .bpay-container p {
    color: #183168;
    font-size: 0.6875em !important;
    font-weight: 600; }
    .bpay-container p span {
      font-weight: 700; }
    .bpay-container p:last-child {
      margin-bottom: 0; }
  .bpay-container .bpay-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.9375rem; }
    .bpay-container .bpay-header img {
      width: auto;
      margin-right: 0.625rem;
      width: 2.5rem;
      height: auto; }
    .bpay-container .bpay-header .bpay-details {
      border: 1.5px solid #183168;
      flex: 1;
      padding: 0.3125rem 0.9375rem;
      align-self: stretch;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .bpay-container .bpay-header .bpay-details p {
        margin: 0;
        font-size: 0.875em !important;
        font-weight: 700;
        line-height: 1.5; }

@media only screen and (min-width: 860px) {
  .lottery-confirmations .lottery-card {
    text-align: left;
    padding: 2.5rem 3.125rem;
    min-width: 22.5rem; }
    .lottery-confirmations .lottery-card .buttons-container {
      display: flex;
      width: 100%; }
      .lottery-confirmations .lottery-card .buttons-container .button {
        display: flex;
        margin-right: 0.625rem; }
        .lottery-confirmations .lottery-card .buttons-container .button:last-child {
          margin-right: 0; }
    .lottery-confirmations .lottery-card.bpay-card {
      max-width: 40rem;
      width: 100%; }
      .lottery-confirmations .lottery-card.bpay-card .buttons-container {
        float: right;
        width: calc(100% - 21.25rem);
        flex-direction: column;
        display: flex;
        align-items: flex-start; }
        .lottery-confirmations .lottery-card.bpay-card .buttons-container .print {
          margin-top: 0;
          width: auto !important;
          transition: none; }
          .lottery-confirmations .lottery-card.bpay-card .buttons-container .print:hover {
            background-color: white;
            color: #E25F2C; }
        .lottery-confirmations .lottery-card.bpay-card .buttons-container .button {
          margin-bottom: 0.625rem;
          width: 100%; }
    .lottery-confirmations .lottery-card.payment-success {
      min-width: 31.25rem; }
  .bpay-container {
    max-width: 18.75rem;
    width: 100%;
    float: left; } }

@media only screen and (min-width: 980px) {
  .lottery-confirmations {
    padding: 8vw 0; }
    .lottery-confirmations .lottery-card .buttons-container {
      display: flex;
      width: 100%; }
      .lottery-confirmations .lottery-card .buttons-container .button {
        margin-right: 0.9375rem; }
        .lottery-confirmations .lottery-card .buttons-container .button:last-child {
          margin-right: 0; } }

/*	Popups	*/
.popup {
  width: 100vw;
  position: fixed;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  z-index: 9999;
  margin: 10vh 0;
  display: none;
  -webkit-overflow-scrolling: touch;
  overflow: visible !important; }
  .popup.active {
    display: block; }
  .popup .popup-container {
    height: 75vh;
    overflow: auto !important;
    width: 100% !important; }
  .popup .popup-container > .container {
    padding: 1.25rem 5%;
    max-width: 35rem;
    background: linear-gradient(to top left, #047ADA 0%, #39ABF7 100%);
    border-radius: 10px;
    margin: auto; }
    .popup .popup-container > .container.container-large {
      padding: 0;
      width: 95%;
      text-align: center; }
  .popup .lottery-card {
    text-align: center; }
  .popup .popup-close {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    color: white;
    width: 2.1875rem;
    height: 2.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50%;
    font-size: 0.75em;
    z-index: 9999;
    top: 0;
    right: calc(50% - 17.5rem);
    transform: translate(40%, -40%);
    background: white;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1); }
    .popup .popup-close svg * {
      fill: #2C68A5; }
  .popup .popup-inner {
    text-align: center;
    margin: 3.125rem auto 1.5625rem;
    overflow: hidden;
    max-width: 35rem;
    width: 95%; }
    .popup .popup-inner h3 {
      text-align: center !important; }
    .popup .popup-inner .popup-icon {
      width: 5.3125rem;
      height: 5.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid white;
      margin: 0 auto 2.5rem; }
      .popup .popup-inner .popup-icon.icon-spinner {
        width: 10.9375rem;
        height: 10.9375rem; }
        .popup .popup-inner .popup-icon.icon-spinner svg {
          width: 100% !important;
          height: 100% !important; }
      .popup .popup-inner .popup-icon svg {
        height: 55% !important;
        width: auto !important;
        overflow: visible; }
        .popup .popup-inner .popup-icon svg * {
          stroke-width: 0.5px;
          fill: white; }
    .popup .popup-inner h3 {
      color: white;
      font-size: 1.125em;
      font-weight: 600;
      margin: 0 0 1.5625rem; }
    .popup .popup-inner p {
      color: white;
      font-size: 0.875em;
      font-family: "open-sans";
      line-height: 1.5;
      margin-top: 0; }
    .popup .popup-inner .buttons-container {
      margin-top: 1.875rem; }
      .popup .popup-inner .buttons-container p {
        font-size: 0.875em !important;
        text-align: left;
        width: 95%;
        margin: 0.625rem auto; }
        .popup .popup-inner .buttons-container p a {
          text-decoration: underline;
          color: inherit; }
    .popup .popup-inner .button {
      display: block;
      padding: 0.875rem;
      margin-top: 0.625rem;
      font-size: 0.875em;
      font-weight: 600;
      font-family: "open-sans";
      min-width: 150px; }
  .popup.popup-waiting .container {
    padding-bottom: 3.125rem;
    padding-right: 0;
    padding-left: 0; }
  .popup.popup-waiting h3 {
    font-size: 1.5em; }
  .popup.popup-waiting p {
    text-align: center; }
  .popup.popup-waiting .lottery-card {
    width: 95%; }
  .popup h4 {
    color: white;
    margin-bottom: 0.625rem;
    font-weight: 400; }
  .popup .popup-card {
    border-top: 1px solid white;
    width: 95%;
    margin: 0 auto;
    padding-top: 1.875rem; }
    .popup .popup-card .lottery-card {
      padding: 1.5625rem 1.875rem; }
      .popup .popup-card .lottery-card.lottery-card-results {
        padding-right: 5%;
        padding-left: 5%; }
      .popup .popup-card .lottery-card p {
        text-align: right; }
      .popup .popup-card .lottery-card .lottery-date {
        text-align: left;
        font-size: 0.625em !important;
        margin-left: 0 !important; }
      .popup .popup-card .lottery-card .draw-card--header {
        text-align: left;
        font-size: 23px;
        max-width: 100%; }
        .popup .popup-card .lottery-card .draw-card--header * {
          text-align: left; }
        .popup .popup-card .lottery-card .draw-card--header .lottery-flag {
          width: 2.5rem;
          height: 2.5rem; }
      .popup .popup-card .lottery-card .lottery-numbers-container p {
        text-align: center; }
  .popup.express-checkout {
    padding-bottom: 0; }
    .popup.express-checkout .container {
      max-width: 35rem;
      text-align: left;
      padding: 3.125rem 0 0; }
    .popup.express-checkout .popup-inner {
      padding-bottom: 1.5625rem; }
      .popup.express-checkout .popup-inner h3 {
        font-size: 1.25em;
        text-align: left; }
      .popup.express-checkout .popup-inner p {
        max-width: 22.5rem;
        text-align: center;
        margin: 0 auto; }
    .popup.express-checkout .button {
      margin: 0 0.46875rem 0.9375rem; }
      .popup.express-checkout .button span {
        width: 1.5625rem;
        height: 1.5625rem;
        flex: 0 0 1.5625rem; }
    .popup.express-checkout .checkout-actions {
      padding-bottom: 1.5625rem; }
      .popup.express-checkout .checkout-actions p {
        margin-right: auto !important;
        margin-left: auto !important;
        max-width: 23.75rem; }
  .popup.voucher-details .container {
    padding: 0;
    max-width: 21.875rem;
    width: 85%; }
  .popup.voucher-details .popup-inner h3 {
    text-align: center;
    max-width: 100%;
    font-weight: 400; }
  .popup.voucher-details .checkout-actions {
    padding: 2.5rem 0.9375rem !important; }
    .popup.voucher-details .checkout-actions .container {
      padding: 0; }
    .popup.voucher-details .checkout-actions p {
      font-weight: 400;
      margin: 0 0 0.9375rem !important; }
  .popup.voucher-details form {
    display: flex; }
    .popup.voucher-details form input[type="text"] {
      border: 0.125rem solid #2C68A5;
      color: #38A4D0;
      width: 100%;
      text-align: center;
      margin-right: 0.3125rem; }
    .popup.voucher-details form input::-webkit-input-placeholder {
      color: #38A4D0; }
    .popup.voucher-details form input:-moz-placeholder {
      /* Firefox 18- */
      color: #38A4D0; }
    .popup.voucher-details form input::-moz-placeholder {
      /* Firefox 19+ */
      color: #38A4D0; }
    .popup.voucher-details form input:-ms-input-placeholder {
      color: #38A4D0; }
    .popup.voucher-details form input[type="submit"], .popup.voucher-details form button {
      border: none;
      width: 12.5rem;
      max-width: 40%;
      background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
      color: white; }
  .popup.voucher-added {
    padding-top: 1.25rem; }
    .popup.voucher-added .container {
      padding: 0;
      width: 85%; }
    .popup.voucher-added .popup-inner h3 {
      text-align: center;
      font-weight: 400; }
    .popup.voucher-added .popup-inner p {
      text-align: center;
      max-width: 100%;
      margin-bottom: 0; }
    .popup.voucher-added .checkout-actions {
      padding: 2.5rem 0.9375rem !important;
      text-align: left; }
      .popup.voucher-added .checkout-actions .container {
        width: 85%;
        padding: 0;
        max-width: 28.125rem; }
      .popup.voucher-added .checkout-actions p {
        font-weight: 400;
        margin: 0 0 0.625rem; }
      .popup.voucher-added .checkout-actions .button-container {
        position: relative;
        margin: 0 auto 2.5rem;
        border-radius: 100px;
        width: 22.5rem;
        border: 0.125rem solid #2C68A5; }
        .popup.voucher-added .checkout-actions .button-container .button-title {
          color: #38A4D0;
          font-size: 0.875em;
          flex: 1;
          margin: 0;
          border-radius: 100px;
          padding: 0.625rem 9.375rem 0.625rem 0.9375rem; }
        .popup.voucher-added .checkout-actions .button-container .button {
          position: absolute;
          top: 0;
          right: 0;
          height: 101%;
          min-height: 0;
          margin: 0;
          width: auto;
          transform: none !important;
          font-weight: 400;
          padding: 0 1.5625rem;
          font-size: 0.875em; }
  .popup.popup-maintenance .container {
    background: white;
    border-radius: 10px; }
  .popup.popup-maintenance .popup-icon svg {
    height: 90% !important; }
    .popup.popup-maintenance .popup-icon svg * {
      stroke-width: 2px !important; }
  .popup.popup-maintenance .popup-close {
    border-color: #2C68A5; }
    .popup.popup-maintenance .popup-close svg * {
      fill: #2C68A5; }
  .popup.popup-maintenance h3, .popup.popup-maintenance p {
    color: #2C68A5; }
  .popup.signup-popup {
    padding: 0;
    background-image: none;
    background: transparent; }
    .popup.signup-popup .tab-toggle {
      background: transparent;
      padding: 0; }
    .popup.signup-popup .popup-container > .container {
      padding: 0 !important;
      width: 92.5% !important;
      background: white;
      border-radius: 10px;
      overflow: hidden; }
    .popup.signup-popup .container-small {
      padding: 0 0.9375rem;
      width: 100%;
      max-width: 26.25rem; }
    .popup.signup-popup .label-for {
      position: relative; }
    .popup.signup-popup .input-row label button {
      margin-top: 0; }
    .popup.signup-popup .terms-conditions-check .checkbox {
      top: 0.6875rem; }
    .popup.signup-popup label {
      position: relative; }
      .popup.signup-popup label .checkbox {
        top: 0.46875rem; }
      .popup.signup-popup label input[type="submit"], .popup.signup-popup label button {
        /*padding: rem(15) rem(75);*/
        margin-top: 1.25rem;
        min-width: 150px; }
    .popup.signup-popup form .input-row .select-dropdown .select-rendered:after {
      right: 0.3125rem; }
    .popup.signup-popup form .input-row .select-dropdown:last-of-type {
      margin-right: 0; }
    .popup.signup-popup #sign-up.nav-tab.tab-content {
      height: 100%;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      position: static; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .container {
        width: 100%;
        padding: 0; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header {
        background: linear-gradient(to top left, #0B80DE 0%, #44B5FD 100%);
        padding: 1.5625rem 0 1.875rem; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 {
          margin: 0 auto;
          font-size: 1.375em;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.2;
          text-align: center;
          font-weight: 700 !important;
          color: white; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 .signup-icon {
            margin-right: 0.9375rem;
            border: 2px solid white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6.5px; }
            .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 .signup-icon svg {
              width: 0.9375rem;
              height: 0.9375rem;
              box-sizing: content-box; }
              .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 .signup-icon svg * {
                stroke: white; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 i {
            font-size: 2.125em;
            margin-right: 0.9375rem; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header p {
          margin-top: 0;
          font-size: 0.875em;
          font-weight: 400;
          font-family: "open-sans";
          color: white; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header p:first-of-type {
            margin-top: 1.25rem; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header p:last-child {
            margin-bottom: 0; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .button {
        margin-bottom: 1.25rem;
        position: relative;
        font-weight: 700;
        padding-left: 0.625rem;
        padding-right: 0; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .button i {
          position: absolute;
          left: 1.125rem;
          font-size: 1.125em;
          top: 50%;
          transform: translateY(-50%); }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .button:last-child {
          margin-bottom: 0; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--main {
        padding: 2.1875rem 0 0; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--main .container {
          width: 90%; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--main .container > p {
            color: #2C68A5; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in-action {
        margin-top: 0.625rem;
        margin-bottom: 2.1875rem; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in-action p {
          color: #2C68A5;
          display: flex;
          justify-content: space-between;
          margin: 0;
          line-height: 1;
          font-size: 0.875em;
          font-family: "open-sans";
          padding: 0 0.625rem; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in-action p span {
            color: #E25F2C;
            margin-left: 0.625rem;
            font-weight: 700;
            text-decoration: underline; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in form h3 {
        color: #E25F2C;
        font-weight: 600;
        font-size: 1em;
        margin: 0.9375rem 0 1.25rem;
        display: inline-block; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in#sign-up input[type="submit"] {
        margin-bottom: 1.5625rem; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in#sign-up input[type="submit"] + .sign-in-action {
          margin-bottom: 0; }
  .popup.popup-status .sign-in--main .radio-container label input {
    margin-right: 2.1875rem !important; }
  .popup.popup-bpay .popup-icon {
    width: 5.3125rem;
    height: 5.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid white;
    margin: 0 auto; }
    .popup.popup-bpay .popup-icon svg {
      width: 50%;
      height: 50%; }
      .popup.popup-bpay .popup-icon svg * {
        fill: white; }
  .popup.popup-bpay h2 {
    margin-top: 0;
    color: #E25F2C; }
  .popup.popup-bpay p {
    margin-bottom: 1.875rem; }
  .popup.popup-bpay .bpay-container {
    float: none;
    margin: 0 auto 1rem;
    max-width: 17.75rem; }
  .popup.popup-bpay .buttons-container {
    display: block; }
    .popup.popup-bpay .buttons-container .button {
      max-width: 8.75rem;
      width: auto;
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      margin: 0 auto; }
      .popup.popup-bpay .buttons-container .button svg {
        flex: 0 0 1.875rem; }
        .popup.popup-bpay .buttons-container .button svg #Rectangle-Copy {
          display: none; }
      .popup.popup-bpay .buttons-container .button:hover {
        background-color: transparent;
        color: #E25F2C; }

.popup-page {
  background: linear-gradient(to top left, #047ADA 0%, #39ABF7 100%);
  padding: 5rem 0 2.5rem; }
  .popup-page.page-error p {
    text-align: center !important; }
  .popup-page.page-error .button {
    margin-top: 1.25rem; }
  .popup-page .popup-inner {
    padding: 2.5rem 1.25rem;
    max-width: 95%;
    background-color: white;
    position: relative;
    border-radius: 10px;
    margin: auto;
    position: relative;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1); }
    .popup-page .popup-inner svg {
      height: 3.75rem;
      margin-bottom: 1.875rem; }
    .popup-page .popup-inner h2 {
      color: #2C68A5; }
      .popup-page .popup-inner h2 span {
        border-color: #2C68A5; }
        .popup-page .popup-inner h2 span svg {
          margin: 0; }
          .popup-page .popup-inner h2 span svg * {
            fill: #2C68A5; }
    .popup-page .popup-inner h3, .popup-page .popup-inner p {
      color: #2C68A5;
      font-size: 1.125em; }
    .popup-page .popup-inner p {
      text-align: left;
      font-size: 0.875em;
      font-family: "open-sans";
      line-height: 1.5;
      margin-top: 0; }
    .popup-page .popup-inner a {
      font-weight: 600;
      text-decoration: none; }
    .popup-page .popup-inner .sign-in--main {
      margin-top: 2.5rem; }
    .popup-page .popup-inner input {
      min-width: 150px; }

#account--spending-edit {
  margin-top: 1.875rem; }

@media only screen and (min-width: 420px) {
  .popup-page {
    padding: 6.25rem 0; }
  .popup.popup-bpay .bpay-container {
    max-width: 18.75rem; } }

@media only screen and (min-width: 580px) {
  .popup.voucher-details .container {
    max-width: 34.375rem;
    padding: 0; }
  .popup-page {
    padding: 8.75rem 0 7.5rem; }
    .popup-page .popup-inner {
      max-width: 41.25rem;
      padding: 5rem 6.25rem 3.75rem; }
      .popup-page .popup-inner svg {
        height: 100%; }
      .popup-page .popup-inner h3 {
        font-size: 1.375em; }
      .popup-page .popup-inner p {
        font-size: 1em; } }

@media only screen and (min-width: 760px) {
  .popup {
    border-radius: 0.625rem; }
    .popup .popup-inner {
      text-align: left; }
      .popup .popup-inner h3 {
        text-align: center; }
    .popup .buttons-container {
      display: flex;
      flex-wrap: wrap;
      text-align: center; }
      .popup .buttons-container .button {
        flex: 1;
        margin-right: 3%;
        line-height: 1.3; }
        .popup .buttons-container .button + .button {
          margin-right: 0; }
        .popup .buttons-container .button.button-orange-solid {
          flex: 0 0 100%;
          margin-top: 0.9375rem; }
    .popup.voucher-details {
      border-radius: 0.625rem 0.625rem 0 0; }
      .popup.voucher-details .container {
        width: 70%; }
      .popup.voucher-details .checkout-actions {
        padding: 2.5rem 5rem !important; }
    .popup.voucher-added {
      border-radius: 0.625rem 0.625rem 0 0; }
      .popup.voucher-added .container {
        width: 80%; }
      .popup.voucher-added .checkout-actions .container {
        width: 70%; }
      .popup.voucher-added .checkout-actions .button-container .button-title {
        padding: 0.625rem 9.375rem 0.625rem 1.875rem; }
      .popup.voucher-added .checkout-actions .button-container .button {
        padding: 0 1.875rem; }
    .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header {
      background-image: none;
      padding: 0; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 {
        justify-content: flex-start;
        color: #2C68A5; }
        .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 .signup-icon {
          border-color: #2C68A5; }
          .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header h2 .signup-icon svg * {
            stroke: #2C68A5; }
      .popup.signup-popup #sign-up.nav-tab.tab-content .sign-in .sign-in--header p {
        text-align: left;
        color: #7A7B78; } }

@media only screen and (max-width: 620px) {
  .popup .popup-close {
    right: 5%; } }

@media only screen and (min-width: 980px) {
  .popup .popup-close {
    cursor: pointer;
    transition: all ease-in-out 0.15s; }
    .popup .popup-close svg path {
      transition: fill ease-in-out 0.15s; }
    .popup .popup-close:hover {
      background: #2C68A5;
      border-color: #2C68A5; }
      .popup .popup-close:hover svg path {
        fill: white; }
  .popup.popup-maintenance .popup-close:hover {
    background: #2C68A5;
    border-color: #2C68A5; }
    .popup.popup-maintenance .popup-close:hover svg path {
      fill: white; } }

.icon-oops {
  height: 100% !important;
  min-width: 5.625rem; }
  .icon-oops * {
    fill: #2C68A5 !important; }

/*	Document Upload	*/
.lottery-card.lottery-card-document-upload {
  padding: 0;
  margin-top: 2.1875rem; }
  .lottery-card.lottery-card-document-upload .button {
    width: auto;
    margin: 0 auto 1.09375rem;
    padding: 0.9375rem 2.5rem;
    min-width: 9.6875rem;
    display: inline-block; }
  .lottery-card.lottery-card-document-upload .terms {
    text-align: left;
    padding: 1.25rem 1.5625rem;
    border-top: 1px solid #E3E3E3; }
    .lottery-card.lottery-card-document-upload .terms p {
      font-size: 0.6875em;
      color: #7A7B78;
      margin: 0; }

.file-upload {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding: 1.5625rem 0.9375rem 1.25rem;
  margin: 0.9375rem 0.625rem; }
  .file-upload .upload-image {
    margin: auto 0;
    width: 30%;
    flex: 0 0 30%;
    margin-right: 1.5625rem;
    height: auto;
    align-self: flex-start; }
  .file-upload .file-upload-container {
    font-size: 1em;
    font-family: "open-sans";
    line-height: 1.2;
    flex: 1; }
    .file-upload .file-upload-container .file-upload-title {
      display: block;
      font-weight: 600;
      padding: 0.625rem;
      margin-bottom: 0.625rem;
      margin-left: -5px; }
    .file-upload .file-upload-container span {
      line-height: 1.3; }
    .file-upload .file-upload-container .error {
      color: red; }
  .file-upload input[type="file"] {
    display: none; }

@media only screen and (max-width: 390px) {
  .lottery-card.lottery-card-document-upload .button {
    min-width: 0;
    margin-right: 0; } }

/* Footer Sections */
/* Lottery Search */
.lottery-play-search {
  max-width: 35rem;
  margin: 2.1875rem auto; }
  .lottery-play-search p {
    color: white;
    font-weight: 700; }
  .lottery-play-search form input[type="submit"] {
    margin-top: 0.625rem; }
  .lottery-play-search form label {
    flex: 0 0 100%; }
  .lottery-play-search form:first-of-type {
    margin-top: 1.875rem; }

.lottery-search-result {
  padding: 2.8125rem 0 3.125rem; }
  .lottery-search-result .container {
    max-width: 35rem; }
  .lottery-search-result h2 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .lottery-search-result h2 svg {
      margin-right: 0.625rem; }
  .lottery-search-result p {
    color: #4C4D4A;
    margin: 0.9375rem 0 2rem;
    font-weight: 600; }
  .lottery-search-result form {
    margin-top: 1.875rem; }
    .lottery-search-result form .lottery-select {
      margin: 0; }
    .lottery-search-result form label {
      flex: 0 0 100%; }
    .lottery-search-result form .select-dropdown .select-rendered:after {
      background-image: url(images/icon-angle-down.svg);
      height: 1rem;
      width: 1rem;
      right: 0.9375rem; }

.lottery-search-result form .lottery-select, .lottery-play-search form .lottery-select {
  margin-right: 0.625rem; }

.lottery-search-result form label, .lottery-play-search form label {
  flex: 1;
  margin-top: 0; }

/* Lottery Signup */
.lottery-signup {
  background: linear-gradient(to top left, #097EDD 0%, #2194EA 50%, #36A8F6 100%);
  padding: 2.5rem 0 1.875rem; }
  .lottery-signup * {
    color: white; }
  .lottery-signup h3 {
    margin-top: 0.9375rem;
    font-size: 1.5em; }
  .lottery-signup form {
    margin: 1.875rem auto 0;
    max-width: 22.5rem; }
    .lottery-signup form .input-row {
      justify-content: center;
      max-width: 100%;
      display: block; }
      .lottery-signup form .input-row input {
        max-width: 14.375rem;
        width: 100%; }
      .lottery-signup form .input-row button {
        background-color: white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.9375rem;
        height: 0.9375rem;
        padding: 0; }
        .lottery-signup form .input-row button svg {
          height: 0.875rem;
          width: auto;
          margin: 0 auto; }

/* Lottery Regulations */
.lottery-regulations {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .lottery-regulations .lottery-regulations--regulation {
    border-bottom: 1px solid #2C68A5;
    padding: 2.1875rem 0; }
    .lottery-regulations .lottery-regulations--regulation h3 {
      color: #E25F2C;
      font-weight: 700;
      margin: 0.9375rem 0 1.875rem; }
    .lottery-regulations .lottery-regulations--regulation img {
      max-width: 2.5rem; }
    .lottery-regulations .lottery-regulations--regulation:last-of-type {
      border-bottom: 0; }

.regulation-digits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 25rem;
  margin: 0 auto; }
  .regulation-digits .img-container {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px; }
    .regulation-digits .img-container img {
      display: block;
      max-width: 100%;
      flex: 0 0 auto;
      width: auto;
      height: 70px; }
    .regulation-digits .img-container:nth-of-type(3n+3), .regulation-digits .img-container:last-of-type {
      margin-right: 0; }
  .regulation-digits.secure-logos .img-container img {
    height: 40px; }

@media only screen and (min-width: 760px) {
  .lottery-regulations {
    display: flex;
    margin: 0 auto;
    padding: 0 calc(7.5% / 2); }
    .lottery-regulations .lottery-regulations--regulation {
      flex: 1;
      margin: 0;
      border-bottom: none; }
      .lottery-regulations .lottery-regulations--regulation:first-child {
        border-right: 1px solid #2C68A5;
        padding-right: 5%; }
      .lottery-regulations .lottery-regulations--regulation:last-child {
        padding-left: 5%; }
      .lottery-regulations .lottery-regulations--regulation .regulation-digits .img-container {
        height: 80px; }
        .lottery-regulations .lottery-regulations--regulation .regulation-digits .img-container img {
          height: 65px; }
        .lottery-regulations .lottery-regulations--regulation .regulation-digits .img-container.icon-tall img {
          height: 80px; }
      .lottery-regulations .lottery-regulations--regulation .regulation-digits.secure-logos .img-container {
        height: 80px; }
        .lottery-regulations .lottery-regulations--regulation .regulation-digits.secure-logos .img-container img {
          height: 40px; }
        .lottery-regulations .lottery-regulations--regulation .regulation-digits.secure-logos .img-container.icon-tall img {
          height: 45px; } }

@media only screen and (min-width: 1080px) {
  .lottery-regulations {
    padding: 0 calc((100% - 67.5rem) / 2); }
    .lottery-regulations .lottery-regulations--regulation .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .lottery-regulations .lottery-regulations--regulation .container h3 {
        margin: 0 0 0 1.25rem; }
      .lottery-regulations .lottery-regulations--regulation .container .regulation-digits {
        flex: 0 0 100%;
        margin-top: 2.5rem; } }

/* Lottery Benefits */
.lottery-benefits {
  background: linear-gradient(to top, #E25F2C 0%, #F29756 100%);
  padding: 1.5625rem 0; }
  .lottery-benefits .container ul {
    padding: 0 0.3125rem;
    margin: 0;
    list-style: none;
    text-align: left;
    display: inline-block; }
    .lottery-benefits .container ul li {
      color: white;
      font-weight: 700;
      font-size: 1.25em;
      display: flex;
      align-items: center;
      line-height: 1.2;
      margin-bottom: 1.5625rem; }
      .lottery-benefits .container ul li svg {
        flex: 0 0 1.875rem;
        margin-right: 0.9375rem; }
      .lottery-benefits .container ul li:last-of-type {
        margin-bottom: 0; }

@media only screen and (min-width: 680px) {
  .lottery-benefits .container ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .lottery-benefits .container ul li {
      flex: 0 0 45%;
      margin-bottom: 0;
      padding: 0.625rem 0; } }

@media only screen and (min-width: 1080px) {
  .lottery-benefits .container ul {
    flex-wrap: nowrap; }
    .lottery-benefits .container ul li {
      flex: 1;
      margin-right: 1%; }
      .lottery-benefits .container ul li:last-of-type {
        margin-right: 0; } }

/* Lottery Footer Cards */
.lottery-footer-cards {
  background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
  padding: 1.875rem 0; }
  .lottery-footer-cards .footer-cards--left {
    margin-bottom: 0.625rem; }
  .lottery-footer-cards .lottery-card {
    padding: 2.5rem 0.625rem 3.125rem;
    margin-bottom: 0.625rem; }
    .lottery-footer-cards .lottery-card:last-of-type {
      margin-bottom: 0; }

.lottery-app-container {
  display: flex;
  flex-direction: column; }
  .lottery-app-container .lottery-app {
    border: 2px solid #2C68A5;
    font-size: 1em;
    color: #2C68A5;
    border-radius: 0.625rem;
    display: inline-flex;
    align-items: center;
    padding: 0.625rem 1.5625rem;
    text-align: left;
    margin-bottom: 1.25rem; }
    .lottery-app-container .lottery-app span {
      line-height: 1.2;
      margin-left: 0.9375rem;
      font-family: "open-sans";
      margin-top: 0;
      font-weight: 600; }
    .lottery-app-container .lottery-app svg {
      max-width: 1.875rem;
      height: auto;
      min-height: 1.875rem;
      max-height: 2.1875rem;
      width: 100%;
      overflow: visible; }
      .lottery-app-container .lottery-app svg * {
        fill: #2C68A5; }
      .lottery-app-container .lottery-app svg circle + path {
        fill: white; }
    .lottery-app-container .lottery-app:last-of-type {
      margin-bottom: 0; }

.lottery-card--social {
  display: flex;
  justify-content: center; }
  .lottery-card--social a {
    color: #2C68A5;
    font-size: 2.125em;
    margin-right: 1.5625rem;
    padding: 0.625rem; }
    .lottery-card--social a svg {
      transition: all ease-in-out 0.2s; }
      .lottery-card--social a svg path {
        transition: all ease-in-out 0.2s; }
    .lottery-card--social a:last-of-type {
      margin-right: 0; }

@media only screen and (min-width: 680px) {
  .lottery-footer-cards > .container {
    display: flex;
    flex-wrap: wrap; }
    .lottery-footer-cards > .container .lottery-card {
      width: 100%;
      max-width: 100%; }
    .lottery-footer-cards > .container > * {
      flex: 1;
      margin-right: 1.5%;
      margin-bottom: 0; }
      .lottery-footer-cards > .container > *:last-child {
        margin-right: 0; }
        .lottery-footer-cards > .container > *:last-child .lottery-card {
          height: 100%; } }

@media only screen and (min-width: 960px) {
  .lottery-footer-cards .footer-cards--left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -1.25rem; }
    .lottery-footer-cards .footer-cards--left .lottery-card {
      flex: 0 0 48.25%;
      min-width: 0;
      width: 100%;
      max-width: 48.25%;
      margin: 0 0 1.25rem; }
      .lottery-footer-cards .footer-cards--left .lottery-card:first-child {
        flex: 0 0 100%;
        max-width: 100%; }
    .lottery-footer-cards .footer-cards--left .lottery-app-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 3.125rem; }
      .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app {
        flex: 0 0 32%;
        max-width: 32%;
        display: flex;
        flex-direction: column;
        padding: 1.5625rem 0.3125rem;
        margin: 0 2% 0.78125rem 0;
        text-align: center;
        transition: all ease-in-out 0.2s; }
        .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app:nth-of-type(3n + 3) {
          margin-right: 0; }
        .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app:nth-of-type(1n + 4) {
          margin-bottom: 0; }
        .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app svg {
          margin-bottom: 0.625rem;
          margin-right: 0; }
          .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app svg path, .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app svg polygon {
            transition: fill ease-in-out 0.2s; }
        .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app span {
          margin-left: 0; }
        .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app:last-child {
          margin-bottom: 0; }
        .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app:hover {
          background: #2C68A5;
          color: white;
          opacity: 1; }
          .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app:hover svg * {
            fill: white; }
          .lottery-footer-cards .footer-cards--left .lottery-app-container .lottery-app:hover svg circle + path {
            fill: #2C68A5; }
    .lottery-footer-cards .footer-cards--left .lottery-card--social {
      margin-top: 2.5rem; }
      .lottery-footer-cards .footer-cards--left .lottery-card--social a:hover {
        opacity: 1; }
        .lottery-footer-cards .footer-cards--left .lottery-card--social a:hover svg {
          transform: scale(1.1); }
          .lottery-footer-cards .footer-cards--left .lottery-card--social a:hover svg * {
            fill: #E25F2C; }
  .lottery-footer-cards .footer-cards--right {
    display: flex;
    align-items: stretch;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap; }
    .lottery-footer-cards .footer-cards--right .lottery-card {
      flex: 1; }
    .lottery-footer-cards .footer-cards--right .tab-nav {
      justify-content: flex-start;
      border-bottom: none;
      padding: 0 2.5rem; }
      .lottery-footer-cards .footer-cards--right .tab-nav li {
        flex: 0 0 49%;
        text-align: left;
        justify-content: flex-start;
        display: flex;
        padding: 0;
        border-bottom: none;
        pointer-events: none; }
        .lottery-footer-cards .footer-cards--right .tab-nav li svg {
          margin: 0 0.9375rem 0 0;
          max-width: 3.125rem;
          flex: 0 0 auto; }
    .lottery-footer-cards .footer-cards--right .lottery-card--content h3 br {
      display: none; }
    .lottery-footer-cards .footer-cards--right .tabs-container {
      display: flex;
      justify-content: space-between;
      padding: 0 2.5rem;
      width: 100%;
      max-width: 100%; }
      .lottery-footer-cards .footer-cards--right .tabs-container .tab-content {
        flex: 0 0 45%;
        opacity: 1;
        visibility: visible;
        overflow: visible;
        height: auto;
        display: block;
        z-index: 0;
        flex: 1; }
        .lottery-footer-cards .footer-cards--right .tabs-container .tab-content ul {
          margin: 1.5625rem 0 0; } }

/*	Tabs	*/
/*	Tabs	*/
.tab-nav {
  margin: 0;
  list-style: none;
  align-items: center;
  align-items: stretch;
  padding: 0 6%;
  display: inline-block;
  white-space: nowrap;
  align-self: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-around !important;
  user-select: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }
  .tab-nav li {
    list-style: none;
    color: white;
    font-size: 0.9375em;
    letter-spacing: 0.5px !important;
    line-height: 1.3;
    padding: 0.625rem 0.625rem 0.78125rem;
    border-bottom: 3px solid transparent;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    font-weight: 600;
    align-self: stretch;
    align-items: center;
    display: flex;
    max-width: 25%;
    font-size: 0.875em; }
    .tab-nav li svg {
      margin: 0 auto 0.625rem;
      display: block;
      flex: 0 0 100%;
      height: 2.8125rem;
      width: auto; }
    .tab-nav li.active {
      border-color: white; }
    .tab-nav li:last-of-type {
      margin-right: 0; }
  .tab-nav.tab-nav-orange {
    border-bottom: 1px solid #E25F2C; }
    .tab-nav.tab-nav-orange li {
      color: #E25F2C; }
      .tab-nav.tab-nav-orange li.active {
        border-color: #E25F2C; }
  .tab-nav.tab-nav-noborder {
    border-bottom: none; }
  .tab-nav.tab-nav-nooverflow {
    overflow: hidden;
    justify-content: space-around;
    align-items: flex-end; }
    .tab-nav.tab-nav-nooverflow li {
      max-width: 100%;
      width: auto;
      padding: 0.625rem 0.3125rem;
      font-size: 1.125em;
      font-weight: 700;
      flex: 0 0 auto;
      display: block;
      letter-spacing: 0;
      margin: 0; }
  .tab-nav.tab-nav-small li {
    font-weight: 400; }

.tab-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  user-select: none;
  max-width: 67.5rem;
  background-attachment: white;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 0.9375rem 0;
  max-width: 95vw; }
  .tab-toggle span {
    color: #2C68A5;
    cursor: pointer;
    opacity: 0.6;
    line-height: 1.3;
    font-weight: 600;
    font-size: 0.9375em;
    transition: all ease-in-out 0.2s; }
    .tab-toggle span:hover {
      opacity: 1; }
  .tab-toggle label {
    margin: 0 1.5625rem; }
    .tab-toggle label input[type="checkbox"] {
      display: none; }
      .tab-toggle label input[type="checkbox"] + span {
        display: block;
        width: 2.8125rem;
        height: 0.9375rem;
        background: #E25F2C;
        position: relative;
        border-radius: 3.125rem;
        opacity: 1; }
        .tab-toggle label input[type="checkbox"] + span:before {
          content: '';
          border-radius: 50%;
          width: 0.9375rem;
          height: 0.9375rem;
          background: white;
          position: absolute;
          top: 0;
          left: 100%;
          transform: translateX(-96%);
          transition: all ease-in-out 0.2s;
          box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); }
  .tab-toggle span.active {
    opacity: 1; }
    .tab-toggle span.active + label span:before {
      left: 0;
      transform: none; }
  .tab-toggle.no-border {
    box-shadow: none;
    border: none;
    margin: 0; }

.tab-content {
  opacity: 0;
  transition: opacity ease-in-out 0.3s;
  z-index: -99;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  display: block; }
  .tab-content.active {
    display: block;
    opacity: 1;
    z-index: 1;
    visibility: visible;
    height: auto;
    overflow: visible; }
    .tab-content.active.division-results {
      display: flex; }
  .tab-content.tab-content-slide {
    max-height: 0;
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: all 0 !important; }
    .tab-content.tab-content-slide.active {
      max-height: 5000px; }
  .tab-content.tab-content-overflow {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }

@media only screen and (min-width: 760px) {
  .tab-nav li {
    font-size: 1em; }
  .tab-toggle span {
    font-size: 1.1875em; }
    .tab-toggle span br {
      display: none; } }

/*	Timer	*/
.lottery-card .timer {
  flex-direction: column; }

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 16px !important; }
  .timer svg {
    width: 1.4375rem;
    flex: 0 0 1.4375rem;
    margin-right: 1.25rem; }
  .timer.cta-timer {
    margin-top: 1.25rem;
    justify-content: center; }
    .timer.cta-timer span {
      color: #E25F2C; }
    .timer.cta-timer svg {
      width: 1.875rem;
      height: auto;
      flex: 0 0 1.875rem;
      margin-right: 1.25rem; }
      .timer.cta-timer svg * {
        fill: white; }
  .timer .timer-countdown {
    color: #2C68A5;
    font-size: 1.125em;
    font-weight: 700; }
    .timer .timer-countdown.cta-countdown {
      font-size: 1em; }
      .timer .timer-countdown.cta-countdown .cta-timer-container > div {
        display: flex;
        flex-direction: column;
        float: left;
        width: 4.0625rem;
        margin-right: 0.9375rem;
        position: relative; }
        .timer .timer-countdown.cta-countdown .cta-timer-container > div span {
          color: white;
          font-size: 1.125em;
          position: absolute;
          top: 100%;
          left: 50%;
          margin-top: 0.625rem;
          line-height: 1;
          transform: translateX(-50%); }
          .timer .timer-countdown.cta-countdown .cta-timer-container > div span.cta-timer-count {
            background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
            font-size: 2.5em;
            border-radius: 0.625rem;
            position: static;
            padding: 0.46875rem 0.3125rem;
            margin-top: 0;
            transform: none; }
        .timer .timer-countdown.cta-countdown .cta-timer-container > div:last-of-type {
          margin-right: 0; }

/*	Notifications	*/
/*	Notification 	*/
.notifications-container {
  position: fixed;
  z-index: 999;
  left: 0.625rem;
  bottom: 0.625rem; }
  .notifications-container .notification {
    width: 9.375rem;
    background: linear-gradient(to top left, #F5993C 0%, #F76B1C 100%);
    padding: 0.625rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    font-size: 0.75em;
    font-weight: 600;
    opacity: 1;
    text-align: left;
    position: relative;
    margin-bottom: 0.3125rem;
    color: white;
    user-select: none;
    line-height: 1.2; }
    .notifications-container .notification:last-of-type {
      margin-bottom: 0; }

@media only screen and (min-width: 760px) {
  .notifications-container .notification {
    font-size: 1em;
    padding: 0.9375rem;
    width: 16.25rem; } }

/* Blog Posts */
.archive-categories {
  padding: 1.25rem 0 0;
  margin-bottom: 3.125rem; }
  .archive-categories .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-bottom: 0.09375rem solid #E3E3E3;
    padding-bottom: 0.625rem; }
  .archive-categories p {
    font-weight: 400;
    font-size: 1.125em;
    flex: 0 0 100%; }
  .archive-categories ul {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0; }
    .archive-categories ul li {
      list-style-type: none;
      flex: 0 0 31%;
      margin: 0.3125rem 3.33% 0.3125rem 0; }
      .archive-categories ul li .button {
        color: #2C68A5;
        font-weight: 400;
        padding: 0.3125rem 0.5rem;
        margin: 0;
        font-size: 0.75em;
        border: 0.125rem solid #2C68A5;
        width: 100%;
        text-transform: uppercase; }
        .archive-categories ul li .button.active {
          background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
          border: none;
          color: white;
          transform: none; }
        .archive-categories ul li .button:hover {
          transform: none;
          opacity: .6; }
      .archive-categories ul li:nth-of-type(3n+3) {
        margin-right: 0; }

@media only screen and (min-width: 500px) {
  .archive-categories p {
    font-weight: 500; }
  .archive-categories ul {
    flex: 0 0 90%; }
    .archive-categories ul li {
      flex: 0 0 23.5%;
      margin: 0.3125rem 2% 0.3125rem 0; }
      .archive-categories ul li:nth-of-type(3n+3) {
        margin: 0.3125rem 2% 0.3125rem 0; }
      .archive-categories ul li:nth-of-type(4n+4) {
        margin-right: 0; } }

@media only screen and (min-width: 760px) {
  .archive-categories {
    padding: 1.875rem 0 0; }
    .archive-categories .container {
      padding-bottom: 1.875rem; }
    .archive-categories p {
      flex: 0 0 10%;
      text-align: left; }
    .archive-categories ul {
      padding: 0;
      flex: 0 0 80%; } }

@media only screen and (min-width: 980px) {
  .archive-categories p {
    font-size: 1.25em !important; }
  .archive-categories ul li a:hover {
    opacity: 0.6; } }

.archive-posts {
  margin-bottom: 1.25rem; }
  .archive-posts > .container {
    display: flex;
    flex-wrap: wrap;
    width: 90%; }
  .archive-posts .blog-post {
    flex: 0 0 100%;
    margin: 0 auto 6vw;
    box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
    padding: 70% 0 0;
    max-width: 22.5rem; }
    .archive-posts .blog-post .blog-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      padding-bottom: 70%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all ease-in-out 0.4s; }
    .archive-posts .blog-post .blog-content {
      flex: 1;
      text-align: left;
      padding: 0.9375rem 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      top: 0;
      z-index: 1;
      transition: all ease-out 0.3s 0s; }
      .archive-posts .blog-post .blog-content h3 {
        margin-bottom: 0.625rem;
        font-weight: 400; }
      .archive-posts .blog-post .blog-content p {
        color: #4C4D4A;
        font-size: 0.8125em;
        line-height: 1.7; }
        .archive-posts .blog-post .blog-content p:last-of-type {
          margin-bottom: 0 !important; }
      .archive-posts .blog-post .blog-content .button.button-orange-solid {
        align-self: flex-start;
        background: transparent;
        color: #4C4D4A;
        padding: 0;
        font-weight: 400;
        margin: auto 0 0;
        transition: all ease-in-out 0.3s 0.2s !important; }
    .archive-posts .blog-post:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0;
      transition: all ease-in-out 0.3s;
      z-index: 1; }

.blog-info {
  display: flex;
  align-items: center;
  padding: 0.9375rem 0 0;
  border-top: 1px solid #7A7B78;
  transition: all ease-in-out 0.3s; }
  .blog-info p, .blog-info span {
    color: #7A7B78 !important;
    opacity: 0.7;
    margin: 0; }
  .blog-info span {
    margin: 0 0.3125rem; }

.blog-category {
  position: absolute;
  top: 1.25rem;
  left: 0;
  background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
  color: white !important;
  padding: 0.3125rem 0.9375rem;
  font-size: 0.8125em !important;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin: 0;
  z-index: 1;
  transition: all ease-in-out 0.3s; }

.archive-posts-nav {
  margin: 0 0 2.5rem; }
  .archive-posts-nav .container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .archive-posts-nav a {
    display: inline-block;
    padding: 0.3125rem 0.625rem;
    color: #2C68A5;
    font-weight: 500; }
    .archive-posts-nav a svg {
      max-height: 1.5625rem; }
    .archive-posts-nav a.active {
      color: #E25F2C; }

@media only screen and (min-width: 600px) {
  .archive-posts .blog-post {
    flex: 0 0 48%;
    margin: 0 4% 4vw 0;
    padding: 35% 0 0;
    max-width: 100%; }
    .archive-posts .blog-post:nth-of-type(2n+2) {
      margin-right: 0; } }

@media only screen and (min-width: 870px) {
  .archive-posts {
    margin-bottom: 2.5rem; }
    .archive-posts .blog-post {
      flex: 0 0 31.99%;
      margin: 0 2% 2vw 0;
      padding: 23% 0 0; }
      .archive-posts .blog-post:nth-of-type(2n+2) {
        margin: 0 2% 2vw 0; }
      .archive-posts .blog-post:nth-of-type(3n+3) {
        margin-right: 0; }
  .archive-posts-nav {
    margin: 0 0 3.75rem; } }

@media only screen and (min-width: 980px) {
  .archive-posts .blog-post .blog-content .button.button-orange-solid {
    color: white;
    background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
    padding: 0.75rem 2.5rem 0.875rem;
    align-self: center;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    margin: 1.5625rem 0 0; }
  .archive-posts .blog-post .blog-content h3 {
    font-size: 1.5em; }
  .archive-posts .blog-post .blog-content p {
    font-size: 1em; }
  .archive-posts .blog-post .blog-content .blog-info {
    margin-top: auto; }
    .archive-posts .blog-post .blog-content .blog-info p, .archive-posts .blog-post .blog-content .blog-info span {
      font-size: 0.9375em; }
  .archive-posts .blog-post:hover {
    opacity: 1; }
    .archive-posts .blog-post:hover:before {
      opacity: 1; }
    .archive-posts .blog-post:hover .blog-category {
      opacity: 0; }
    .archive-posts .blog-post:hover .blog-image {
      height: 100%;
      z-index: 0; }
    .archive-posts .blog-post:hover .blog-content {
      text-align: center;
      top: -6.25rem;
      transition: all ease-out 0.3s 0.35s; }
      .archive-posts .blog-post:hover .blog-content * {
        color: white; }
      .archive-posts .blog-post:hover .blog-content .button.button-orange-solid {
        transform: scale(1);
        opacity: 1;
        visibility: visible; }
      .archive-posts .blog-post:hover .blog-content .blog-info {
        opacity: 0;
        visibility: hidden; }
  .blog-category {
    font-size: 0.9375em; }
  .archive-posts-nav a {
    font-size: 1.5em; }
    .archive-posts-nav a svg {
      max-height: 100%; } }

.section-single-post .banner {
  min-height: 25vw;
  background-size: cover; }

.section-single-post .single-post-container {
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  padding: 3.125rem 0 0;
  border-radius: 10px;
  background-color: white;
  position: relative;
  z-index: 1;
  top: -3.75rem; }
  .section-single-post .single-post-container .archive-link {
    position: absolute;
    top: 0.9375rem;
    left: 52%;
    transform: translateX(-50%); }
    .section-single-post .single-post-container .archive-link svg {
      position: absolute;
      left: -1.25rem;
      top: 50%;
      transform: translateY(-50%); }
  .section-single-post .single-post-container .container {
    padding: 1.25rem 0;
    max-width: 90%; }
  .section-single-post .single-post-container .post-header {
    margin-bottom: 2.5rem; }
    .section-single-post .single-post-container .post-header .blog-info {
      justify-content: center;
      border: none;
      padding: 0; }
      .section-single-post .single-post-container .post-header .blog-info p, .section-single-post .single-post-container .post-header .blog-info span {
        font-size: 1em;
        font-weight: 400; }
    .section-single-post .single-post-container .post-header h1 {
      font-size: 1.875em;
      font-weight: 500;
      margin: 1.25rem 0 0.625rem; }
    .section-single-post .single-post-container .post-header h2 {
      font-size: 1.0625em;
      font-weight: 400;
      color: #E25F2C;
      margin: 0; }
  .section-single-post .single-post-container .post-image {
    padding-bottom: 50vw;
    position: relative; }
    .section-single-post .single-post-container .post-image .blog-category {
      top: 0;
      left: 1.5625rem; }
  .section-single-post .single-post-container .image-container {
    display: flex; }
    .section-single-post .single-post-container .image-container .post-image {
      flex: 1; }
      .section-single-post .single-post-container .image-container .post-image:first-of-type {
        margin-right: 1%; }
  .section-single-post .single-post-container .post-content {
    text-align: center; }
  .section-single-post .single-post-container .quote {
    font-weight: 400;
    margin: 1.875rem auto;
    font-size: 1.125em; }
  .section-single-post .single-post-container .share-buttons {
    border-top: 1px solid #7A7B78;
    padding: 1.25rem 0; }
    .section-single-post .single-post-container .share-buttons .container {
      max-width: 90%;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
    .section-single-post .single-post-container .share-buttons p {
      margin: 0 1.25rem 0 0;
      padding: 0.3125rem 0;
      text-transform: uppercase;
      display: inline-block; }
    .section-single-post .single-post-container .share-buttons ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0; }
      .section-single-post .single-post-container .share-buttons ul li {
        list-style-type: none;
        margin-right: 0.625rem; }
        .section-single-post .single-post-container .share-buttons ul li a {
          padding: 0.3125rem; }
          .section-single-post .single-post-container .share-buttons ul li a svg {
            width: 0.875rem;
            height: 0.875rem; }

@media only screen and (min-width: 413px) {
  .section-single-post .single-post-container .archive-link {
    position: absolute;
    transform: none;
    top: 0.9375rem;
    left: 2.1875rem;
    font-size: 1.25em; }
    .section-single-post .single-post-container .archive-link svg {
      position: absolute;
      left: -1.25rem;
      top: 50%;
      transform: translateY(-50%); }
  .section-single-post .single-post-container .post-content {
    text-align: left; } }

@media only screen and (min-width: 500px) {
  .section-single-post .single-post-container .post-header .blog-info p, .section-single-post .single-post-container .post-header .blog-info span {
    font-size: 1.125em; }
  .section-single-post .single-post-container .post-header h1 {
    font-size: 2.5em; }
  .section-single-post .single-post-container .post-header h2 {
    font-size: 1.375em; }
  .section-single-post .single-post-container .post-image {
    padding-bottom: 40vw; } }

@media only screen and (min-width: 800px) {
  .section-single-post .single-post-container {
    padding: 3.125rem 0 0;
    top: -3.75rem; }
    .section-single-post .single-post-container .container {
      padding: 1.25rem 0;
      max-width: 80%; }
    .section-single-post .single-post-container .post-header .blog-info p, .section-single-post .single-post-container .post-header .blog-info span {
      font-size: 1.125em; }
    .section-single-post .single-post-container .post-header h1 {
      font-size: 2.625em; }
    .section-single-post .single-post-container .post-header h2 {
      font-size: 1.5em; }
    .section-single-post .single-post-container .post-image {
      padding-bottom: 25vw; }
    .section-single-post .single-post-container .quote {
      max-width: 90%;
      margin: 2.5rem auto;
      font-size: 1.25em; }
    .section-single-post .single-post-container .post-content {
      font-size: 1em; } }

/*Result Listing*/
.lottery-results-container .result-listing {
  display: none; }

.lottery-results-container .lottery-play-search {
  margin-bottom: 0; }

.result-listing {
  padding: 1.875rem 0 !important;
  background-color: #f8f8f8; }
  .result-listing h2 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700; }
    .result-listing h2 svg {
      margin-right: 0.9375rem; }
  .result-listing p {
    color: #4C4D4A;
    font-weight: 600; }
  .result-listing.bg-blue-gradient h2, .result-listing.bg-blue-gradient p {
    color: white; }
  .result-listing .result-card-container .card-container-right {
    display: none; }
  .result-listing .result-card-container .lottery-card {
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: column; }
    .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-flag {
      margin: 0 auto 0.625rem; }
    .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info h3 {
      margin: 0;
      font-size: 1.625em;
      font-weight: 900; }
    .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status {
      display: flex;
      align-items: center;
      margin-top: 0.625rem; }
      .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status .date {
        margin-right: 0.625rem; }
      .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status span {
        margin-right: 0.3125rem; }
        .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status span svg {
          width: 1.5625rem;
          max-height: 1.5625rem;
          height: auto; }
          .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status span svg path {
            stroke-width: 1.25px; }
          .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status span svg #Shape {
            stroke-width: 0.5px; }
        .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status span.active svg * {
          stroke: #E25F2C; }
        .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status span.active svg #Shape {
          fill: #E25F2C; }
    .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .date {
      margin-top: 0;
      color: #2C68A5;
      font-weight: 700; }
    .result-listing .result-card-container .lottery-card.card-left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 31.25rem; }
      .result-listing .result-card-container .lottery-card.card-left .lottery-numbers-container {
        margin-bottom: 0.9375rem;
        width: 100%; }
      .result-listing .result-card-container .lottery-card.card-left .dropdown-link {
        margin-top: 1.25rem; }
      .result-listing .result-card-container .lottery-card.card-left .division-results {
        margin-top: 0.625rem; }
    .result-listing .result-card-container .lottery-card .lottery-numbers-container {
      margin: 0.9375rem auto 1.875rem;
      max-width: 15.625rem; }
      .result-listing .result-card-container .lottery-card .lottery-numbers-container .lottery-number {
        max-width: 100%; }
    .result-listing .result-card-container .lottery-card .division-results {
      justify-content: space-between;
      margin-bottom: 1.875rem !important; }
    .result-listing .result-card-container .lottery-card .dropdown-link {
      color: #2C68A5;
      margin-top: auto; }
    .result-listing .result-card-container .lottery-card:last-of-type {
      /*margin-bottom: 0;*/ }
  .result-listing .results-actions {
    padding: 0.625rem;
    margin: 1.25rem auto auto;
    background-color: white;
    width: 100%;
    max-width: 37.5rem;
    border-radius: 0.625rem;
    justify-content: flex-start;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    flex-wrap: wrap; }
    .result-listing .results-actions .disclaimer {
      flex: 0 0 100%;
      margin: 0.3125rem 0 !important; }

.lottery-game--lottery-results .results-actions {
  padding: 0.625rem;
  margin: 1.25rem auto auto;
  background-color: white;
  width: 95%;
  max-width: 49.6875rem;
  border-radius: 0.625rem;
  justify-content: flex-start;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap; }
  .lottery-game--lottery-results .results-actions .disclaimer {
    flex: 0 0 100%;
    margin: 0.3125rem 0 !important; }

@media only screen and (max-width: 760px) {
  .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status {
    justify-content: center; }
  .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .result-status .date {
    margin-bottom: 0; } }

@media only screen and (min-width: 760px) {
  .result-listing {
    padding: 3.75rem 0 5rem !important; }
    .result-listing .result-card-container .lottery-card {
      min-width: 37.5rem; }
      .result-listing .result-card-container .lottery-card .lottery-card-heading {
        display: flex;
        justify-content: flex-start; }
        .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-flag {
          margin: 0 1.25rem 0 0; }
        .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info {
          text-align: left; }
          .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info h3 {
            font-size: 2em; }
          .result-listing .result-card-container .lottery-card .lottery-card-heading .lottery-card-heading--info .date {
            margin: 0; }
      .result-listing .result-card-container .lottery-card .lottery-numbers-container {
        max-width: 100%; }
        .result-listing .result-card-container .lottery-card .lottery-numbers-container .lottery-numbers .lottery-number {
          height: 2.5rem;
          width: 2.5rem; }
      .result-listing .result-card-container .lottery-card .division-results {
        margin: 1.875rem 0 0 2.5rem; }
        .result-listing .result-card-container .lottery-card .division-results.five-col {
          margin: 0 auto;
          width: 100%;
          left: 0;
          max-width: 100%; }
        .result-listing .result-card-container .lottery-card .division-results div {
          flex: 1; }
      .result-listing .result-card-container .lottery-card .button:first-of-type {
        margin-right: 0.9375rem; }
  .lottery-results-container .result-listing {
    display: block; }
  .lottery-results-container .lottery-slider {
    display: none; }
  .result-listing {
    padding: 3.75rem 0 5rem; }
    .result-listing .result-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .result-listing .result-card-container .card-container-right {
        max-width: 37.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        .result-listing .result-card-container .card-container-right .lottery-card {
          flex: 0 0 48.5%;
          max-width: 48.5%;
          min-width: 0;
          margin: 0 0 0.9375rem; }
          .result-listing .result-card-container .card-container-right .lottery-card .lottery-card-heading .lottery-flag {
            height: 1.875rem;
            width: 1.875rem;
            margin-right: 0.625rem; }
          .result-listing .result-card-container .card-container-right .lottery-card .lottery-card-heading .lottery-card-heading--info h3 {
            font-size: 1.375em;
            line-height: 1; }
          .result-listing .result-card-container .card-container-right .lottery-card .lottery-card-heading .lottery-card-heading--info .date {
            font-size: 0.875em; }
          .result-listing .result-card-container .card-container-right .lottery-card .lottery-numbers-container .lottery-numbers {
            width: 100%;
            flex: 0 0 100%; }
            .result-listing .result-card-container .card-container-right .lottery-card .lottery-numbers-container .lottery-numbers .lottery-number {
              height: 1.875rem;
              width: 1.875rem; }
              .result-listing .result-card-container .card-container-right .lottery-card .lottery-numbers-container .lottery-numbers .lottery-number span {
                font-size: 0.875em; } }

@media only screen and (min-width: 1100px) {
  .result-listing .results-actions {
    margin-left: 0;
    max-width: 100%; }
  .result-listing .result-card-container {
    justify-content: space-between; }
    .result-listing .result-card-container .lottery-card.card-left {
      flex: 0 0 49%;
      max-width: 49%;
      min-width: 0;
      margin-bottom: 0;
      justify-content: flex-start;
      margin-left: 0; }
      .result-listing .result-card-container .lottery-card.card-left .lottery-card-heading .lottery-flag {
        height: 3.125rem;
        width: 3.125rem; }
      .result-listing .result-card-container .lottery-card.card-left .lottery-card-heading .lottery-card-heading--info h3 {
        line-height: 1;
        font-size: 1.75em; }
      .result-listing .result-card-container .lottery-card.card-left .lottery-card-heading .lottery-card-heading--info .date {
        font-size: 0.9375em; }
      .result-listing .result-card-container .lottery-card.card-left .dropdown-link {
        margin-top: auto; }
    .result-listing .result-card-container .card-container-right {
      flex: 0 0 49%;
      max-width: 100%; }
      .result-listing .result-card-container .card-container-right .lottery-card .lottery-numbers-container .lottery-numbers .lottery-number {
        height: 1.875rem;
        width: 1.875rem; }
      .result-listing .result-card-container .card-container-right .lottery-card:nth-of-type(3), .result-listing .result-card-container .card-container-right .lottery-card:last-of-type {
        margin-bottom: 0 !important; } }

.lottery-contain-four {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 0 0 37.5rem;
  padding: 4px 0; }
  .lottery-contain-four .lottery-card {
    flex: 0 0 48.5%;
    margin: 0 0 0.9375rem;
    min-width: 48.5%;
    max-width: 48.5%;
    font-size: 14px;
    padding: 1.25rem 0.9375rem !important; }
    .lottery-contain-four .lottery-card:nth-of-type(3) {
      margin-bottom: 0; }
  .lottery-contain-four.lottery-contains-four--column {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    display: inline-flex;
    flex: 0 0 auto !important; }
    .lottery-contain-four.lottery-contains-four--column .lottery-card {
      width: 100%;
      max-width: 260px; }

.lottery-play-listing {
  background-image: linear-gradient(to right, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
  padding: 0.9375rem 0 2.5rem; }
  .lottery-play-listing .lottery-play--nav {
    margin-bottom: 1.25rem; }
    .lottery-play-listing .lottery-play--nav .container {
      display: flex;
      border-bottom: 1px solid white;
      padding: 0 0.9375rem;
      max-width: 100%;
      width: 100%; }
    .lottery-play-listing .lottery-play--nav ul {
      display: flex;
      justify-content: space-between;
      padding: 0; }
  .lottery-play-listing .read-more {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.1875rem; }
    .lottery-play-listing .read-more span {
      display: flex;
      align-items: center;
      margin-left: 0.625rem;
      margin-top: 0.125rem; }
      .lottery-play-listing .read-more span svg {
        height: 0.75rem; }

.lottery-contain-four {
  flex: 0 0 100%;
  margin: 0.625rem 0; }
  .lottery-contain-four .lottery-card {
    margin-bottom: 0.625rem;
    padding: 1.5625rem 1.875rem; }
    .lottery-contain-four .lottery-card .lottery-icon {
      height: 2.5rem;
      width: 2.5rem; }
    .lottery-contain-four .lottery-card h3 {
      margin: 0.9375rem 0 0; }
    .lottery-contain-four .lottery-card:last-of-type {
      margin-bottom: 0; }

.tab-lottery-play {
  margin: 0 !important; }
  .tab-lottery-play.active {
    display: flex !important;
    flex-wrap: wrap; }
  .tab-lottery-play .lottery-card-info {
    background-color: transparent;
    box-shadow: none;
    flex: 0 0 100%;
    min-width: 100%; }
    .tab-lottery-play .lottery-card-info img {
      width: 8.125rem;
      height: auto; }
    .tab-lottery-play .lottery-card-info * {
      color: white; }
    .tab-lottery-play .lottery-card-info p {
      font-size: 0.9375em;
      max-width: 37.5rem;
      margin-left: auto;
      margin-right: auto; }
  .tab-lottery-play .flipper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .tab-lottery-play .lottery-card-flip {
    order: -1; }
    .tab-lottery-play .lottery-card-flip img {
      width: 3.75rem;
      height: auto;
      margin-bottom: 0.625rem; }

@media only screen and (min-width: 415px) {
  .lottery-play-listing .tabs-container .lottery-card {
    width: 25rem !important; }
  .lottery-play-listing .lottery-play--nav ul li {
    font-size: 0.9375em; } }

@media only screen and (min-width: 760px) {
  .tab-lottery-play .lottery-card-flip {
    margin: 0 0.5rem; }
    .tab-lottery-play .lottery-card-flip .lottery-card-front {
      width: 100%; }
  .tab-lottery-play {
    justify-content: center; }
    .tab-lottery-play .lottery-card {
      width: 37.5rem !important; } }

@media only screen and (min-width: 870px) {
  .tab-lottery-play .lottery-card-info {
    flex: 0 0 55%; }
  .lottery-contains-four.lottery-contains-four--column {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1020px) {
  .lottery-play-listing {
    padding-bottom: 3.125rem; }
  .tab-lottery-play {
    margin: 1.125rem 0 2.1875rem; }
    .tab-lottery-play .lottery-card-info, .tab-lottery-play .lottery-contain-four, .tab-lottery-play .lottery-card-flip {
      flex: 1;
      width: auto !important;
      min-width: 0;
      margin-right: 10px; }
    .tab-lottery-play .lottery-card-info {
      padding-top: 0;
      padding-right: 0; }
      .tab-lottery-play .lottery-card-info img {
        width: 6.25rem; }
      .tab-lottery-play .lottery-card-info p {
        text-align: left;
        line-height: 1.6; }
    .tab-lottery-play .lottery-contain-four {
      flex: 2;
      margin: 0 10px 0 0; }
      .tab-lottery-play .lottery-contain-four .lottery-card {
        padding: 1.5625rem 0.625rem;
        max-width: 210px; }
    .tab-lottery-play .lottery-card-flip {
      margin: 0;
      flex: 1.2;
      order: 0;
      min-width: 260px;
      margin-right: 7.5px; }
      .tab-lottery-play .lottery-card-flip:last-of-type {
        margin-right: 0; }
      .tab-lottery-play .lottery-card-flip .lottery-card-back p {
        font-size: 0.875em;
        line-height: 1.6; } }

@media only screen and (min-width: 1100px) {
  .tabs-container .lottery-card-flip {
    flex: 1; } }

@media only screen and (min-width: 1200px) {
  .lottery-contain-four .lottery-card {
    padding: 1.5625rem 2.5rem; } }

/* Results New */
.results-listing-container {
  width: 100%;
  border-radius: 0.8rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-width: 88%;
  margin: auto;
  margin-top: 20px; }
  @media only screen and (max-width: 1250px) {
    .results-listing-container {
      max-width: 95%; } }
  @media only screen and (max-width: 1100px) {
    .results-listing-container {
      max-width: 100%; } }
  @media only screen and (max-width: 900px) {
    .results-listing-container {
      box-shadow: none;
      border-radius: 0; } }
  .results-listing-container h3 {
    line-height: 1.3;
    text-align: left; }
  @media only screen and (max-width: 600px) {
    .results-listing-container .col {
      width: 100%; } }
  .results-listing-container .col:first-child {
    width: 28%;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    @media only screen and (max-width: 1100px) {
      .results-listing-container .col:first-child {
        width: 26%; } }
    @media only screen and (max-width: 900px) {
      .results-listing-container .col:first-child {
        justify-content: center;
        width: 100%; } }
    .results-listing-container .col:first-child h3 {
      margin: 0; }
    .results-listing-container .col:first-child img {
      margin-right: 1rem; }
      @media only screen and (max-width: 1250px) {
        .results-listing-container .col:first-child img {
          margin-right: 1rem; } }
  .results-listing-container .col.numbers-col {
    position: relative;
    width: 40%; }
    @media only screen and (max-width: 900px) {
      .results-listing-container .col.numbers-col {
        margin: 1.6rem 0;
        width: auto; } }
  @media only screen and (max-width: 900px) {
    .results-listing-container .inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0.5rem; } }
  .results-listing-container .results-list-item {
    padding: 1.2rem 3rem;
    padding-bottom: 1.5rem;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #eaeaea; }
    @media only screen and (max-width: 1100px) {
      .results-listing-container .results-list-item {
        padding: 3rem 1.5rem; } }
    @media only screen and (max-width: 900px) {
      .results-listing-container .results-list-item {
        width: 48.5%;
        margin-bottom: 20px;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        justify-content: center; } }
    @media only screen and (max-width: 600px) {
      .results-listing-container .results-list-item {
        width: 100%;
        padding: 2rem 1.5rem;
        max-width: 25rem;
        margin: auto;
        margin-bottom: 20px; } }
    .results-listing-container .results-list-item:last-child {
      border-bottom: none; }
    .results-listing-container .results-list-item .lotto-title h3 {
      color: #2c68a5; }
    @media only screen and (max-width: 900px) {
      .results-listing-container .results-list-item .lotto-title {
        text-align: left; }
        .results-listing-container .results-list-item .lotto-title p {
          margin: 0; } }
    .results-listing-container .results-list-item .lottery-numbers {
      display: flex;
      align-items: flex-start; }
      @media only screen and (max-width: 600px) {
        .results-listing-container .results-list-item .lottery-numbers {
          justify-content: center; } }
      .results-listing-container .results-list-item .lottery-numbers .lottery-number {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 100%;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin: 0 0.3rem; }
        @media only screen and (max-width: 900px) {
          .results-listing-container .results-list-item .lottery-numbers .lottery-number {
            width: 2rem;
            height: 2rem;
            font-size: 14px;
            margin: 0 0.2rem; } }
        .results-listing-container .results-list-item .lottery-numbers .lottery-number.orange {
          background-color: #f66c1c; }
        .results-listing-container .results-list-item .lottery-numbers .lottery-number.blue {
          background-color: #1b90e8; }
    .results-listing-container .results-list-item .multiplier {
      position: absolute;
      bottom: -22px;
      right: 9px; }
      @media only screen and (max-width: 900px) {
        .results-listing-container .results-list-item .multiplier {
          position: relative;
          bottom: inherit;
          right: inherit;
          text-align: center; } }
      .results-listing-container .results-list-item .multiplier p {
        margin-bottom: 0;
        font-size: 12px;
        color: #2c68a5; }
    .results-listing-container .results-list-item .result-content {
      background-color: white;
      text-align: left;
      font-weight: 500;
      line-height: 21px; }
      .results-listing-container .results-list-item .result-content p {
        color: #555; }
        .results-listing-container .results-list-item .result-content p:first-child {
          margin-top: 0; }
        .results-listing-container .results-list-item .result-content p:last-child {
          margin-bottom: 0; }
      .results-listing-container .results-list-item .result-content a {
        color: #f66c1c; }
    .results-listing-container .results-list-item .date {
      color: #2c68a5;
      font-size: 15px; }
      @media only screen and (max-width: 900px) {
        .results-listing-container .results-list-item .date.full {
          display: none; } }
      .results-listing-container .results-list-item .date.res {
        display: none; }
        @media only screen and (max-width: 900px) {
          .results-listing-container .results-list-item .date.res {
            display: block; } }

@media only screen and (max-width: 900px) {
  .result-content-container {
    width: 100%; }
    .result-content-container .results-list-item {
      width: 100%;
      margin-bottom: 10px; } }

.results-disclaimer {
  max-width: 85%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: left;
  padding: 0 20px; }
  @media only screen and (max-width: 600px) {
    .results-disclaimer {
      width: 100%;
      max-width: 26rem;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 0; } }
  .results-disclaimer p {
    opacity: 0.5;
    color: #ffffff;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 1.8; }
    .results-disclaimer p:first-child {
      margin-top: 0; }
  .results-disclaimer.blue p {
    opacity: 0.5;
    color: #4a4a4a; }

@media only screen and (max-width: 750px) {
  .lottery-results-container .result-listing.bg-blue-gradient {
    display: block; } }

/*Faqs	*/
.archive-faqs {
  padding: 1.25rem 0 3.75rem; }
  .archive-faqs h1 {
    flex: 0 0 100%;
    color: #E25F2C;
    font-size: 2em;
    margin: 0 0 1.875rem; }
  .archive-faqs .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .archive-faqs .container > * {
      flex: 0 0 100%;
      text-align: left;
      margin-top: 0; }
    .archive-faqs .container > p {
      font-weight: 600;
      font-size: 1.25em; }
      .archive-faqs .container > p:last-of-type {
        margin-bottom: 1.875rem; }
  .archive-faqs .archive-faq {
    display: flex;
    align-items: center;
    padding: 0.9375rem 1.25rem;
    min-width: auto;
    overflow: visible;
    flex-wrap: wrap;
    max-width: 100% !important;
    flex: 0 0 48%;
    max-width: 48%;
    margin: 0 4% 1.25rem 0;
    flex-wrap: wrap;
    justify-content: center;
    transition: all ease-in-out 0.2s; }
    .archive-faqs .archive-faq:nth-of-type(2n+2) {
      margin-right: 0; }
    .archive-faqs .archive-faq svg, .archive-faqs .archive-faq img {
      max-height: 2.5rem;
      height: auto;
      width: 2.5rem;
      overflow: visible;
      align-self: center;
      margin-bottom: 0.625rem; }
    .archive-faqs .archive-faq .faq-content {
      flex: 0 0 100%;
      cursor: pointer;
      text-align: center; }
      .archive-faqs .archive-faq .faq-content h3 {
        font-weight: 600;
        margin: 0 0 0.3125rem;
        color: #A2A2A2;
        font-size: 1.125em;
        line-height: 1;
        transition: all ease-in-out .2s; }
      .archive-faqs .archive-faq .faq-content p {
        color: #AAAAAA;
        font-size: 1em;
        margin: 0; }
      .archive-faqs .archive-faq .faq-content .pdf {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.3125rem;
        font-size: 0.875em;
        opacity: 0.7; }
        .archive-faqs .archive-faq .faq-content .pdf svg {
          width: 0.9375rem;
          margin-right: 0.3125rem;
          margin-bottom: 0; }
    .archive-faqs .archive-faq:hover {
      opacity: 1;
      transform: scale(1.02); }
      .archive-faqs .archive-faq:hover h3 {
        color: #E25F2C; }

@media only screen and (min-width: 780px) {
  .archive-faqs h1 {
    text-align: left;
    font-size: 2.625em;
    margin: 1.875rem 0 3.125rem; } }

@media only screen and (min-width: 900px) {
  .archive-faqs .archive-faq {
    flex: 0 0 calc(96% / 3);
    max-width: calc(96% / 3) !important;
    margin: 0 2% 1.5625rem 0 !important;
    flex-wrap: nowrap;
    align-items: center;
    padding: 1.5625rem 1.25rem; }
    .archive-faqs .archive-faq svg, .archive-faqs .archive-faq img {
      width: 2.5rem;
      max-height: 2.5rem;
      margin-bottom: 0; }
    .archive-faqs .archive-faq img {
      width: 3.75rem;
      max-height: 3.75rem; }
    .archive-faqs .archive-faq .faq-content {
      text-align: left;
      flex: 1;
      margin-left: 1.5625rem; }
      .archive-faqs .archive-faq .faq-content h3 {
        font-size: 1.5625em; }
      .archive-faqs .archive-faq .faq-content .pdf {
        justify-content: flex-start; }
    .archive-faqs .archive-faq:nth-of-type(3n + 3) {
      margin-right: 0 !important; } }

.faqs-listings {
  text-align: center;
  padding: 1.25rem 0 3.75rem; }
  .faqs-listings h1 {
    flex: 0 0 100%;
    color: #E25F2C;
    font-size: 2em;
    margin: 0 0 1.875rem; }
  .faqs-listings .container {
    display: flex;
    flex-wrap: wrap; }
  .faqs-listings h2 {
    margin: 0 0 1.25rem;
    font-size: 1.5625em;
    flex: 0 0 100%;
    text-align: center;
    color: #A2A2A2; }
  .faqs-listings .active-faq {
    display: none; }
  .faqs-listings ul {
    flex: 0 0 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-between;
    align-items: center; }
    .faqs-listings ul li {
      flex: 0 0 49.5%;
      list-style-type: none;
      margin-bottom: 0.625rem;
      width: auto;
      display: flex; }
      .faqs-listings ul li svg {
        max-height: 1.25rem;
        max-width: 1.5625rem;
        margin-right: 0.9375rem;
        filter: grayscale(100%);
        overflow: visible; }
      .faqs-listings ul li a {
        transition: all ease-in-out .2s;
        padding: 0.3125rem;
        font-size: 0.875em;
        display: flex;
        align-items: center;
        color: #868782 !important; }
        .faqs-listings ul li a:hover {
          color: #E25F2C;
          opacity: 1; }
          .faqs-listings ul li a:hover svg {
            filter: grayscale(0%); }
      .faqs-listings ul li .active {
        color: #E25F2C; }
        .faqs-listings ul li .active svg {
          filter: grayscale(0%); }

.faqs-question-listing {
  flex: 0 0 100%;
  padding: 3.75rem 0 0; }
  .faqs-question-listing h2 {
    color: #E25F2C;
    font-size: 1.625em;
    margin-bottom: 2.5rem; }
  .faqs-question-listing ul {
    display: block;
    padding-left: 1.25rem; }
    .faqs-question-listing ul li {
      position: relative;
      margin-bottom: 0 !important;
      padding: 0 0.15625rem; }
      .faqs-question-listing ul li:before {
        content: '';
        position: absolute;
        top: 1rem;
        left: -0.625rem;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #7A7B78; }

@media only screen and (min-width: 414px) {
  .faqs-listings {
    text-align: left; }
    .faqs-listings h2 {
      text-align: left; } }

@media only screen and (min-width: 600px) {
  .faqs-listings ul {
    justify-content: flex-start; }
    .faqs-listings ul li {
      flex: 0 0 32%; }
  .faqs-question-listing {
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    padding: 3.125rem 3.75rem;
    margin-top: 3.75rem; } }

@media only screen and (min-width: 780px) {
  .faqs-listings h1 {
    text-align: left;
    margin: 1.875rem 0 3.125rem;
    font-size: 2.625em; } }

@media only screen and (max-width: 850px) {
  .faqs-listings h2, .faqs-listings .faqs-categories-listing {
    order: 2; }
  .faqs-listings .faqs-question-listing {
    margin-top: 0;
    padding-top: 0; }
  .faqs-listings h2 {
    margin-top: 1.875rem !important; } }

@media only screen and (min-width: 850px) {
  .faqs-listings ul {
    display: block;
    flex: 1; }
    .faqs-listings ul li a {
      font-size: 1.125em; }
  .faqs-question-listing {
    flex: 0 0 65%;
    margin-top: 0; } }

@media only screen and (min-width: 980px) {
  .faqs-listings {
    padding: 3.75rem 0 6.25rem; }
    .faqs-listings ul {
      padding: 0 0 0 1.25rem; }
      .faqs-listings ul li a svg {
        margin-right: 1.875rem;
        max-height: 1.5625rem;
        max-width: 1.5625rem; }
      .faqs-listings ul li a:hover {
        color: #E25F2C !important;
        opacity: 1; }
        .faqs-listings ul li a:hover svg {
          filter: grayscale(0%); }
  .faqs-question-listing ul li:hover {
    color: #7A7B78; } }

.faqs-single .archive-navigation {
  flex: 0 0 100%;
  max-width: 22.5rem;
  margin: 0 0 2.5rem;
  display: flex;
  justify-content: flex-start; }
  .faqs-single .archive-navigation a {
    display: flex;
    align-items: center;
    margin-right: 1.25rem;
    transition: all ease-in-out .2s;
    font-size: 1em;
    flex: 1 0 auto; }
    .faqs-single .archive-navigation a svg {
      width: 1.25rem;
      height: auto;
      filter: grayscale(100%);
      transition: inherit;
      margin-right: 0.625rem; }
    .faqs-single .archive-navigation a span {
      display: block;
      white-space: nowrap; }
    .faqs-single .archive-navigation a:last-of-type {
      margin-right: 0; }

.faqs-answer-listing {
  text-align: left;
  padding: 0; }
  .faqs-answer-listing h2 {
    font-size: 1em;
    text-align: left; }
  .faqs-answer-listing h3 {
    font-weight: 600; }
  .faqs-answer-listing h3, .faqs-answer-listing p {
    font-size: 0.875em;
    margin-top: 0;
    color: #868782;
    opacity: 0.8;
    line-height: 1.2; }
  .faqs-answer-listing h3 {
    margin: 1.875rem 0 0.3125rem; }

@media only screen and (min-width: 414px) {
  .faqs-single .archive-navigation {
    justify-content: flex-start; } }

@media only screen and (min-width: 500px) {
  .faqs-answer-listing {
    padding: 2.5rem 3.125rem;
    margin-top: 0; } }

@media only screen and (min-width: 850px) {
  .faqs-single .archive-navigation {
    flex: 0 0 90%;
    padding-left: 5%; }
    .faqs-single .archive-navigation a {
      margin-right: 3.125rem;
      font-size: 1.25em; }
      .faqs-single .archive-navigation a svg {
        max-height: 1.25rem;
        filter: grayscale(100%); }
  .faqs-answer-listing {
    flex: 0 0 90%;
    max-width: 90%;
    margin: 0 auto;
    padding: 3.125rem 3.75rem; }
    .faqs-answer-listing h2 {
      font-size: 1.625em; }
    .faqs-answer-listing h3, .faqs-answer-listing p {
      font-size: 1.125em; } }

@media only screen and (min-width: 980px) {
  .faqs-single .archive-navigation a:hover {
    color: #E25F2C;
    opacity: 1; }
    .faqs-single .archive-navigation a:hover svg {
      filter: grayscale(0%); } }

.charity {
  padding: 3vw 0 2vw;
  text-align: left;
  font-size: 18px; }
  .charity .slider {
    width: 100%;
    margin: 4.6875rem 0; }
    .charity .slider .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4%;
      text-align: center;
      height: 100px;
      overflow: visible; }
      .charity .slider .slide img {
        height: auto;
        width: auto;
        max-width: 12.5rem;
        max-height: 100%;
        overflow: visible; }
      .charity .slider .slide p {
        font-size: 1.625em;
        white-space: nowrap;
        font-weight: 900;
        color: #2C68A5; }
      .charity .slider .slide:nth-of-type(even) p {
        color: #D8262D; }
  .charity .button-follow {
    margin: 4.6875rem auto 1.25rem; }
  .charity .images-row {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;
    justify-content: center;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
    padding: 1.25rem 0;
    flex-wrap: wrap; }
    .charity .images-row > * {
      margin: 0.9375rem 1.875rem; }
    .charity .images-row img {
      max-width: 50vw;
      width: auto;
      max-height: 9.375rem; }
  .charity img {
    max-width: 12.5rem;
    margin: 0 auto;
    display: block; }

@media only screen and (max-width: 680px) {
  .charity .slider .slide {
    font-size: 0.625em !important; } }

.sign-up--page {
  max-width: 36.25rem;
  margin: 0 auto;
  font-size: 18px;
  padding: 3.75rem 0; }
  .sign-up--page h1 {
    margin: 0 0 2rem;
    color: white;
    font-weight: 600 !important; }
    .sign-up--page h1.icon-title .icon {
      width: 2.5rem;
      height: 2.5rem;
      flex: 0 0 2.5rem !important; }
      .sign-up--page h1.icon-title .icon svg {
        max-width: 2rem;
        height: auto;
        width: 55%; }
    .sign-up--page h1 + .right {
      color: white;
      padding: 0 2.5%;
      margin: 0.75rem 0; }
  .sign-up--page .signup-page--header {
    color: white;
    width: 100%;
    text-align: center;
    border: 2px solid #FFFFFF;
    border-radius: 10px 10px 0 0;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    padding: 1.875rem;
    line-height: 1; }
    .sign-up--page .signup-page--header + form {
      border-radius: 0 0 10px 10px; }
  .sign-up--page form {
    background: white;
    border-radius: 10px;
    padding: 1.875rem 0; }
    .sign-up--page form .social-buttons {
      flex: 0 0 auto !important;
      width: auto;
      align-self: center;
      margin-left: 0.3125rem; }
    .sign-up--page form a.blue {
      font-size: 1em; }
    .sign-up--page form .side-borders {
      margin: 1.25rem 0 2.5rem; }
    .sign-up--page form .container {
      max-width: 23.75rem; }
    .sign-up--page form h3 {
      margin-top: 1.25rem;
      color: #E25F2C; }
      .sign-up--page form h3 + p {
        margin-bottom: 1.5rem; }
    .sign-up--page form p {
      line-height: 1.5; }
    .sign-up--page form a.blue {
      font-size: 16px; }
    .sign-up--page form label p {
      font-size: 0.8125rem;
      text-align: left;
      padding: 0 1.40625rem;
      margin: 0; }
    .sign-up--page form input[type="text"], .sign-up--page form input[type="number"], .sign-up--page form input[type="password"], .sign-up--page form textarea, .sign-up--page form select {
      font-size: 14px !important;
      height: 55px; }
      .sign-up--page form input[type="text"]::placeholder, .sign-up--page form input[type="number"]::placeholder, .sign-up--page form input[type="password"]::placeholder, .sign-up--page form textarea::placeholder, .sign-up--page form select::placeholder {
        opacity: 0.8; }
      .sign-up--page form input[type="text"]:-moz-placeholder, .sign-up--page form input[type="number"]:-moz-placeholder, .sign-up--page form input[type="password"]:-moz-placeholder, .sign-up--page form textarea:-moz-placeholder, .sign-up--page form select:-moz-placeholder {
        /* Firefox 18- */
        line-height: 2.2; }
      .sign-up--page form input[type="text"]::-moz-placeholder, .sign-up--page form input[type="number"]::-moz-placeholder, .sign-up--page form input[type="password"]::-moz-placeholder, .sign-up--page form textarea::-moz-placeholder, .sign-up--page form select::-moz-placeholder {
        /* Firefox 19+ */
        line-height: 2.2; }
    .sign-up--page form .placeholder {
      opacity: 0.8; }
    .sign-up--page form button {
      height: 55px;
      margin: 1.25rem 0;
      font-size: 1.25rem; }
    .sign-up--page form .placeholder {
      top: 1.03125rem;
      font-size: 14px !important; }
    .sign-up--page form .toggle-password {
      top: 1.125rem;
      transition: all linear 0.2s; }
    .sign-up--page form input:focus ~ .toggle-password, .sign-up--page form input:valid ~ .toggle-password {
      top: 1.125rem; }
    .sign-up--page form .select-dropdown .select-rendered {
      height: 3.1875rem;
      font-size: 14px !important; }
    .sign-up--page form .password-strength--validation p {
      text-align: center; }

.social-buttons {
  display: flex;
  align-items: center; }
  .social-buttons a {
    border-radius: 50%;
    width: 2.375rem;
    height: 2.375rem;
    flex: 0 0 2.375rem;
    margin-right: 0.625rem;
    position: relative; }
    .social-buttons a:last-child {
      margin-right: 0; }
    .social-buttons a svg {
      margin: auto !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .social-buttons a svg * {
        fill: white; }
    .social-buttons a.facebook {
      background-color: #425893; }
    .social-buttons a.google {
      background-color: #CD5642; }

@media only screen and (max-width: 560px) {
  .sign-up--page .right {
    text-align: center; }
  .sign-up--page form .container {
    max-width: 92.5%;
    width: 100%;
    font-size: 15px; } }

.landing-page {
  text-align: left; }
  .landing-page .container {
    display: flex;
    padding: 4.6875rem 0; }
    .landing-page .container h1 {
      margin-top: 0;
      color: black; }
    .landing-page .container p {
      color: black; }
    .landing-page .container .landing-right {
      flex: 0 0 22.5rem;
      width: 22.5rem;
      margin-left: 10%; }
    .landing-page .container .landing-side {
      margin-bottom: 3.125rem; }
    .landing-page .container .landing-lottery {
      display: flex;
      align-items: center;
      padding: 0.625rem 0.9375rem;
      background-color: #f3f1f2;
      margin-bottom: 0.625rem;
      transition: all ease-in-out 0.2s; }
      .landing-page .container .landing-lottery * {
        cursor: pointer; }
      .landing-page .container .landing-lottery:hover {
        opacity: 0.7; }
      .landing-page .container .landing-lottery p {
        font-weight: 600; }
      .landing-page .container .landing-lottery img {
        margin-right: 0.625rem;
        width: 3.4375rem; }

@media only screen and (max-width: 860px) {
  .landing-page .container {
    display: block;
    padding: 3.125rem 0; }
    .landing-page .container .landing-right {
      margin: 3.125rem 0 0;
      width: 100%;
      flex: 0 0 100%; } }

/* superenalotto banner */
.enalotto.banner-has-draws {
  background: linear-gradient(to right, #ed145d, #ed1437) !important; }
  .enalotto.banner-has-draws .banner .container {
    min-height: 400px;
    background-image: url(images/enalotto_banner.png);
    background-position: 56.5% bottom;
    background-size: auto 100%; }
  .enalotto.banner-has-draws .banner-draws {
    background: transparent; }
    .enalotto.banner-has-draws .banner-draws .banner-draw {
      max-width: 300px; }
      .enalotto.banner-has-draws .banner-draws .banner-draw > div {
        justify-content: space-between; }
        .enalotto.banner-has-draws .banner-draws .banner-draw > div > * {
          flex: auto !important; }
      .enalotto.banner-has-draws .banner-draws .banner-draw p {
        color: #2c68a5 !important; }
      .enalotto.banner-has-draws .banner-draws .banner-draw .orange {
        color: #e25f2c !important; }
      .enalotto.banner-has-draws .banner-draws .banner-draw.info {
        padding-top: 2rem;
        margin-top: 2rem;
        position: relative;
        overflow: visible; }
        .enalotto.banner-has-draws .banner-draws .banner-draw.info:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4rem;
          top: -2rem;
          left: 0;
          background-image: url(images/new.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
        .enalotto.banner-has-draws .banner-draws .banner-draw.info .center {
          display: block;
          margin: auto; }
          .enalotto.banner-has-draws .banner-draws .banner-draw.info .center h2,
          .enalotto.banner-has-draws .banner-draws .banner-draw.info .center p {
            text-align: center !important; }
          .enalotto.banner-has-draws .banner-draws .banner-draw.info .center h2 {
            font-size: 40px !important;
            color: #2c68a5 !important; }

/* syndicates */
.syndicates.banner-has-draws {
  background: linear-gradient(to right, #d93cb7, #1961a3) !important; }
  .syndicates.banner-has-draws .banner.section-image {
    min-height: 400px;
    background-image: url(images/syndicates_banner.png) !important;
    background-size: cover;
    background-position: center; }
    .syndicates.banner-has-draws .banner.section-image .container {
      background: none !important;
      justify-content: flex-end; }
  .syndicates.banner-has-draws .banner-draws {
    background: transparent; }
    .syndicates.banner-has-draws .banner-draws .banner-draw {
      max-width: 300px; }
      .syndicates.banner-has-draws .banner-draws .banner-draw > div {
        justify-content: space-between; }
        .syndicates.banner-has-draws .banner-draws .banner-draw > div > * {
          flex: auto !important; }
      .syndicates.banner-has-draws .banner-draws .banner-draw p {
        color: #2c68a5 !important; }
      .syndicates.banner-has-draws .banner-draws .banner-draw .orange {
        color: #e25f2c !important; }
      .syndicates.banner-has-draws .banner-draws .banner-draw.info {
        padding-top: 2rem;
        margin-top: 3rem;
        position: relative;
        overflow: visible; }
        .syndicates.banner-has-draws .banner-draws .banner-draw.info:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 4rem;
          top: -2rem;
          left: 0;
          background-image: url(images/new.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
        .syndicates.banner-has-draws .banner-draws .banner-draw.info .center {
          display: block;
          margin: auto; }
          .syndicates.banner-has-draws .banner-draws .banner-draw.info .center h2,
          .syndicates.banner-has-draws .banner-draws .banner-draw.info .center p {
            text-align: center !important; }
          .syndicates.banner-has-draws .banner-draws .banner-draw.info .center h2 {
            font-size: 40px !important;
            color: #2c68a5 !important; }

.syndicates .steps {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: center; }
  .syndicates .steps .col {
    width: 18%;
    padding: 2rem 1rem;
    position: relative; }
    .syndicates .steps .col p {
      margin-bottom: 0; }
    .syndicates .steps .col:last-child:after {
      display: none; }
    .syndicates .steps .col:after {
      content: '';
      display: block;
      position: absolute;
      right: -2rem;
      top: 0;
      bottom: 0;
      left: 0;
      background: url(images/step-divide.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right; }
    .syndicates .steps .col span {
      display: block;
      width: 2rem;
      height: 2rem;
      border: 1px solid #f66c1c;
      text-align: center;
      color: #f66c1c;
      line-height: 1.8;
      font-weight: 400;
      border-radius: 3rem;
      margin: auto; }
    .syndicates .steps .col img {
      height: 3rem;
      width: auto;
      margin-top: 1rem;
      display: inline-block;
      object-fit: contain; }
    .syndicates .steps .col p {
      color: #2c68a5;
      font-size: 1rem; }

.syndicates .plays-container .white-box--shadow {
  padding: 2rem !important;
  margin-bottom: 2rem !important;
  margin-top: 0 !important; }
  .syndicates .plays-container .white-box--shadow:last-child {
    margin-bottom: 0 !important; }
  .syndicates .plays-container .white-box--shadow h1 {
    font-size: 60px;
    margin: 0; }
  .syndicates .plays-container .white-box--shadow h4 {
    font-size: 1.4rem;
    margin: 0 0 1rem; }
  .syndicates .plays-container .white-box--shadow p {
    margin: 1rem 0 0; }
  .syndicates .plays-container .white-box--shadow p,
  .syndicates .plays-container .white-box--shadow h4,
  .syndicates .plays-container .white-box--shadow h5,
  .syndicates .plays-container .white-box--shadow .trigger {
    color: #2c68a5; }
  .syndicates .plays-container .white-box--shadow .orange-text {
    color: #e25f2c; }
  .syndicates .plays-container .white-box--shadow .three-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .syndicates .plays-container .white-box--shadow .three-col .col {
      width: 33% !important; }
  .syndicates .plays-container .white-box--shadow .two-col .col {
    width: 50%; }
  .syndicates .plays-container .white-box--shadow .two-col .title {
    width: 50%; }
    .syndicates .plays-container .white-box--shadow .two-col .title img {
      width: 5rem;
      height: 5rem; }
  .syndicates .plays-container .white-box--shadow .title {
    display: flex;
    align-items: center; }
    .syndicates .plays-container .white-box--shadow .title img {
      object-fit: contain;
      margin-right: 2rem; }
  .syndicates .plays-container .white-box--shadow .shares h5 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0.5rem; }
  .syndicates .plays-container .white-box--shadow .shares p {
    margin: 0;
    font-weight: 100; }
  .syndicates .plays-container .white-box--shadow .top,
  .syndicates .plays-container .white-box--shadow .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .syndicates .plays-container .white-box--shadow .top .col,
    .syndicates .plays-container .white-box--shadow .bottom .col {
      width: auto; }
  .syndicates .plays-container .white-box--shadow .top {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding-bottom: 1rem; }
  .syndicates .plays-container .white-box--shadow .bottom {
    padding-top: 1rem; }
    .syndicates .plays-container .white-box--shadow .bottom .trigger {
      padding-right: 2rem;
      display: inline;
      position: relative; }
      .syndicates .plays-container .white-box--shadow .bottom .trigger:before, .syndicates .plays-container .white-box--shadow .bottom .trigger:after {
        transition: 0.5s ease all; }
      .syndicates .plays-container .white-box--shadow .bottom .trigger:before {
        content: 'Show numbers'; }
      .syndicates .plays-container .white-box--shadow .bottom .trigger:after {
        content: '';
        display: block;
        position: absolute;
        width: 0.9rem;
        height: 0.9rem;
        right: 0;
        top: 0.4rem;
        background: url(images/arrow.svg);
        background-repeat: no-repeat; }
    .syndicates .plays-container .white-box--shadow .bottom .button {
      max-width: 16rem;
      text-transform: capitalize; }
  .syndicates .plays-container .white-box--shadow .stepper .add,
  .syndicates .plays-container .white-box--shadow .stepper .minus {
    width: 2rem !important;
    height: 2rem !important;
    background: #2c68a5;
    border-radius: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 500;
    position: relative; }
  .syndicates .plays-container .white-box--shadow .stepper .minus span {
    position: absolute;
    top: 10%; }
  .syndicates .plays-container .white-box--shadow .stepper .num {
    background: #2c68a5;
    width: 4rem !important;
    height: 4rem !important;
    border-radius: 0.5rem;
    margin: 0 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .syndicates .plays-container .white-box--shadow .stepper .num span {
      display: block;
      width: 100%;
      color: white; }
      .syndicates .plays-container .white-box--shadow .stepper .num span:first-child {
        font-size: 2rem; }
      .syndicates .plays-container .white-box--shadow .stepper .num span:last-child {
        font-size: 0.7rem;
        text-transform: uppercase; }
    .syndicates .plays-container .white-box--shadow .stepper .num h5 {
      margin: 0;
      color: white; }

.syndicates .active .trigger:before {
  content: 'Hide numbers' !important; }

.syndicates .active .trigger:after {
  transform: rotate(-180deg); }

.syndicates .active .expand {
  max-height: 1800px;
  transition: all ease 0.5s; }

.syndicates .expand {
  max-height: 0;
  overflow: hidden; }
  .syndicates .expand .tables:first-of-type {
    margin-top: 2rem;
    border-top: 1px solid rgba(151, 151, 151, 0.2); }

.syndicates .tables {
  width: 100%;
  padding-top: 1rem; }
  .syndicates .tables .title {
    align-items: center;
    margin: 0; }
    .syndicates .tables .title h5 {
      text-align: left;
      font-weight: 400;
      color: #f66c1c;
      font-size: 1rem !important;
      margin: 2rem 0 !important; }
      .syndicates .tables .title h5 span {
        color: #2c68a5; }
    .syndicates .tables .title img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem; }
  .syndicates .tables .four-col {
    display: flex;
    justify-content: space-between; }
    .syndicates .tables .four-col .col {
      width: calc(25%); }
      .syndicates .tables .four-col .col ul,
      .syndicates .tables .four-col .col li {
        margin: 0;
        list-style: none; }
      .syndicates .tables .four-col .col ul {
        padding: 0 1rem;
        border-right: 1px solid #fb8e0c !important; }
      .syndicates .tables .four-col .col:last-child ul {
        border-right: 0 !important;
        padding-right: 0; }
      .syndicates .tables .four-col .col:first-child ul {
        padding-left: 0; }
      .syndicates .tables .four-col .col li {
        padding: 0.25rem 0.5rem;
        display: flex;
        justify-content: space-around;
        color: #1b90e8;
        font-size: 0.9rem; }
        .syndicates .tables .four-col .col li:nth-child(odd) {
          background: rgba(27, 144, 232, 0.1); }
        .syndicates .tables .four-col .col li:nth-child(even) {
          background: rgba(27, 144, 232, 0.2); }
  .syndicates .tables .pagination {
    width: 6rem;
    margin-left: calc(100% - 6rem);
    margin-top: 1rem;
    position: relative;
    color: #1b90e8; }
    .syndicates .tables .pagination span {
      color: #fb8e0c; }
    .syndicates .tables .pagination:before, .syndicates .tables .pagination:after {
      content: '';
      display: block;
      position: absolute;
      width: 0.9rem;
      height: 0.9rem;
      top: 0;
      background: url(images/arrow-o.svg);
      background-repeat: no-repeat; }
    .syndicates .tables .pagination:before {
      left: 0; }
    .syndicates .tables .pagination:after {
      right: 0;
      transform: rotate(-180deg); }

.syndicates .dark {
  background: #4c4d4a;
  padding: 3rem 0; }
  .syndicates .dark p {
    max-width: 35rem;
    margin: auto;
    color: white; }
    .syndicates .dark p span {
      color: #f66c1c;
      margin-top: 1rem;
      display: block; }

@media screen and (max-width: 1080px) {
  .syndicates.banner-has-draws .banner {
    padding: 0; }
    .syndicates.banner-has-draws .banner .container {
      display: flex; }
      .syndicates.banner-has-draws .banner .container .banner-draws {
        width: auto; }
  .syndicates .plays-container .white-box--shadow .col {
    margin: 0; }
  .syndicates .plays-container .white-box--shadow h1 {
    font-size: 50px; } }

@media screen and (max-width: 950px) {
  .syndicates.banner-has-draws .banner {
    padding: 60% 0 0; }
    .syndicates.banner-has-draws .banner.section-image {
      background-image: url(images/syndicates_bannermob.png) !important;
      background-position: top; }
    .syndicates.banner-has-draws .banner .container .banner-draws {
      width: 100%; }
  .syndicates .steps {
    display: none; }
  .syndicates .plays-container .white-box--shadow .two-col .col {
    width: 100%; }
  .syndicates .plays-container .white-box--shadow .two-col .stepper {
    margin: 1rem auto; }
    .syndicates .plays-container .white-box--shadow .two-col .stepper .add,
    .syndicates .plays-container .white-box--shadow .two-col .stepper .minus {
      width: 3rem !important;
      height: 3rem !important; }
      .syndicates .plays-container .white-box--shadow .two-col .stepper .add span,
      .syndicates .plays-container .white-box--shadow .two-col .stepper .minus span {
        font-size: 2rem; }
    .syndicates .plays-container .white-box--shadow .two-col .stepper .num {
      margin: 0 1rem;
      width: 5rem !important;
      height: 5rem !important; }
      .syndicates .plays-container .white-box--shadow .two-col .stepper .num span:first-child {
        font-size: 2.5rem; }
      .syndicates .plays-container .white-box--shadow .two-col .stepper .num span:last-child {
        font-size: 0.9rem; }
  .syndicates .plays-container .white-box--shadow .top,
  .syndicates .plays-container .white-box--shadow .bottom {
    margin-top: 2rem; }
  .syndicates .expand .tables .four-col .col {
    width: 50%; }
    .syndicates .expand .tables .four-col .col:nth-child(n + 3) {
      display: none; }
    .syndicates .expand .tables .four-col .col:nth-child(2) ul {
      padding-right: 0;
      border-right: 0 !important; } }

@media screen and (max-width: 550px) {
  .syndicates .section.thick {
    padding: 2rem 0; }
  .syndicates .dark p {
    width: 90%;
    margin: auto; }
  .syndicates .plays-container .white-box--shadow {
    padding: 1.5rem; }
    .syndicates .plays-container .white-box--shadow .two-col .title,
    .syndicates .plays-container .white-box--shadow .two-col .top,
    .syndicates .plays-container .white-box--shadow .two-col .bottom {
      flex-direction: column;
      text-align: center; }
    .syndicates .plays-container .white-box--shadow .two-col .top,
    .syndicates .plays-container .white-box--shadow .two-col .bottom {
      margin: 0;
      padding: 1rem 0 0;
      border: 0; }
      .syndicates .plays-container .white-box--shadow .two-col .top div:first-child h5,
      .syndicates .plays-container .white-box--shadow .two-col .bottom div:first-child h5 {
        display: none; }
    .syndicates .plays-container .white-box--shadow .two-col .title h1 {
      font-size: 35px;
      line-height: 0.5; }
    .syndicates .plays-container .white-box--shadow .two-col .title img {
      width: 4rem;
      height: 4rem;
      margin: 0 auto 1rem; }
    .syndicates .plays-container .white-box--shadow .two-col .bottom {
      flex-direction: column-reverse; }
    .syndicates .plays-container .white-box--shadow .two-col .button {
      margin-bottom: 1rem; }
  .syndicates .expand .tables .four-col .col {
    width: 100%; }
    .syndicates .expand .tables .four-col .col:nth-child(n + 2) {
      display: none; }
    .syndicates .expand .tables .four-col .col:nth-child(1) ul {
      padding-right: 0;
      border-right: 0 !important; } }

.lottery-flag {
  width: 3.75rem;
  height: 3.75rem; }
  .lottery-flag.flag-round {
    border-radius: 50%; }

.cta-highlight {
  color: #F4540C; }

.read-more {
  text-decoration: underline;
  font-size: 1em;
  line-height: 1.4; }
  .read-more.red {
    color: #D8262D; }

.select-options li img {
  width: 30px; }

@media only screen and (min-width: 980px) {
  a {
    transition: opacity ease-in-out 0.2s; }
    a:hover {
      opacity: 0.6; }
  .button:hover {
    opacity: 1; }
  .button.button-white:hover {
    background: white;
    color: #E25F2C; }
  .button.button-orange:hover {
    background: #E25F2C;
    color: white; }
  .button.button-orange-solid:hover, .button.button-green-solid:hover, .button.button-blue-solid:hover {
    background-position: 30% center; }
  .button.button-blue:hover {
    background: #2C68A5;
    color: white; }
  .button.button-icon-inline:hover {
    background: #E25F2C;
    color: white;
    border-color: #E25F2C; }
    .button.button-icon-inline:hover .button-icon {
      border-color: white !important; }
    .button.button-icon-inline:hover svg * {
      fill: white;
      stroke: white; }
    .button.button-icon-inline:hover svg circle {
      fill: transparent; }
  .button.button-follow:hover {
    background-color: #2C68A5;
    color: white; }
    .button.button-follow:hover svg * {
      fill: white; } }

@media only screen and (min-width: 980px) {
  body,
  html {
    /*font-size: 17px;*/ }
  .read-more {
    transition: all ease-in-out 0.2s; }
    .read-more:hover {
      opacity: 0.7; } }

@media only screen and (min-width: 1500px) {
  body,
  html {
    /*font-size: 18px;*/ } }

@media print {
  body:before {
    display: none; }
  header,
  footer,
  .banner,
  .tab-nav,
  .lottery-cta,
  .lottery-regulations,
  .lottery-footer-cards,
  .lottery-blurb,
  .buttons-container,
  .footer-bottom,
  .footer-logo.secondary-logo {
    display: none !important; }
  .container,
  .site-container {
    width: 100% !important;
    max-width: 100% !important; }
  .lottery-slider {
    box-shadow: none; }
  footer:before {
    display: none; }
  * {
    -webkit-print-color-adjust: exact !important;
    -webkit-filter: opacity(1); }
  main {
    top: 0 !important; }
  .lottery-slider {
    background: white;
    text-align: left; }
  .history-print-title {
    display: block !important;
    background-image: linear-gradient(to right, #2da0f1 0%, #289bee 44%, #178be4 68%, #0076d8 100%);
    padding: 20px 0; }
    .history-print-title p {
      color: white;
      font-size: 28px;
      margin: 0;
      font-weight: 600; }
  .history-row.history-row-titles {
    font-size: 24px !important;
    border-color: #7a7b78; }
  .history-row {
    justify-content: flex-start !important;
    font-size: 18px !important;
    padding: 0 30px !important; }
    .history-row .date {
      width: 100px !important;
      flex: 0 0 100px !important;
      margin-right: 20px !important; }
    .history-row .lottery {
      width: 200px !important;
      flex: 0 0 200px !important;
      margin-right: auto !important; }
    .history-row .cost,
    .history-row .balance {
      width: 100px !important;
      flex: 0 0 100px !important;
      font-size: 18px; }
  .print-container {
    width: 85% !important;
    margin: 0 auto;
    display: block; }
  .account-history {
    margin: 0 !important; }
    .account-history .tabs-container {
      margin: 50px auto;
      border-radius: 10px;
      overflow: visible;
      box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
      width: 85% !important; }
      .account-history .tabs-container .history-row {
        padding: 0; }
  .print-logo {
    justify-content: flex-start;
    text-align: left; }
    .print-logo img {
      width: 180px; }
  footer {
    page-break-inside: avoid; } }

#lottery-signup-banner {
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#f5993c), to(#f76b1c));
  background-image: -webkit-linear-gradient(left, #f5993c, #f76b1c);
  background-image: linear-gradient(to right, #f5993c, #f76b1c);
  padding: 1.25rem 0; }

#lottery-signup-banner svg {
  margin-right: 1.25rem; }

#lottery-signup-banner .lottery-signup-banner-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.lottery-signup-banner-mobile {
  font-size: 12px;
  min-height: 0;
  padding: 0.75rem 2rem; }

#lottery-signup-banner .lottery-signup-banner-mobile {
  display: none; }

#lottery-signup-banner h3 {
  color: #fff;
  font-size: 1.75em;
  font-weight: 700;
  margin: 0.4375rem 2.5rem 0 0; }

@media only screen and (max-width: 760px) {
  #lottery-signup-banner .lottery-signup-banner-desktop {
    display: none; }
  #lottery-signup-banner .lottery-signup-banner-mobile {
    display: block; }
  #lottery-signup-banner button {
    padding: 0.75rem 0.9375rem 0.875rem; } }

#lottery-signup-banner.signup-banner-reverse {
  background: white; }
  #lottery-signup-banner.signup-banner-reverse h3 {
    color: #E25F2C; }
